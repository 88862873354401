import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const TableList = styled.div`
  font-size: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 1fr;
  gap: 0.63em;
  filter: none;
  transition: 0.2s;
  margin-bottom: 2.5em;

  .loader {
    grid-column: 3 / 5;
    height: 2em;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: ${theme.point820}) {
    grid-template-columns: repeat(4, 1fr);
    gap: 0.31em;

    .loader {
      grid-column: 2 / 4;
    }
  }

  @media screen and (max-width: ${theme.point720}) {
    grid-template-columns: repeat(3, 1fr);
    gap: 0.31em;
    filter: none;
    margin-bottom: 0;

    .loader {
      grid-column: 2;
    }
  }
`
TableList.displayName = 'TableList'

export const Table = styled.div`
  min-height: 10.7em;
  background: ${colorTheme.restaurant.tableMobile.background};
  border-radius: 0.75em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  padding: 0;

  &.busy {
    background: ${colorTheme.restaurant.tableMobile.busy.background};
  }

  &.selected {
    background: ${colorTheme.restaurant.tableMobile.selected.background};
  }

  &.swap {
    min-height: 9.4em;
  }

  @media screen and (max-width: ${theme.point820}) {
    &, &.swap {
      min-height: 7.4em;
    }
  }
`
Table.displayName = 'Table'

export const TableIcon = styled.div`
  width: 6.2em;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 4.18em;
    height: 4.12em;
  }

  .table-open {
    fill: ${colorTheme.restaurant.icons.tableOpen.background};
    path {
      stroke: ${colorTheme.restaurant.icons.tableOpen.stroke};
    }
  }

  .table-open-green {
    path {
      fill: ${colorTheme.restaurant.icons.tableOpen.green.background};
      stroke: ${colorTheme.restaurant.icons.tableOpen.green.stroke};
    }
  }

  .table-open-white {
    path {
      fill: ${colorTheme.restaurant.icons.tableOpen.white.background};
      stroke: ${colorTheme.restaurant.icons.tableOpen.white.stroke};
    }
  }

  .table-closed {
    path {
      fill: ${colorTheme.restaurant.icons.tableClosed.background};
      stroke: ${colorTheme.restaurant.icons.tableClosed.stroke};
    }
  }

  .table-closed-white {
    path {
      stroke: ${colorTheme.restaurant.icons.tableClosed.white.stroke};
    }
  }

  .number {
    position: absolute;
    top: auto;
    left: auto;
    color: ${colorTheme.restaurant.tableMobile.number};
    font-size: 1.88em;
    font-family: ${theme.fontRobotoRegular};
    text-align: center;
  }

  &.swap {
    width: 5.1em;
  }

  &.swap .number {
    color: ${colorTheme.restaurant.tableMobile.numberSwap};
  }

  &.busy {
    .number {
      color: ${colorTheme.restaurant.tableMobile.busy.number};
    }
  }

  &.selected {
    svg, path {
      stroke: ${colorTheme.restaurant.tableMobile.selected.icon};
    }

    .number {
      color: ${colorTheme.restaurant.tableMobile.selected.number};
    }
  }

  @media screen and (max-width: ${theme.point820}) {
    width: 4.19em;

    .number {
      font-size: 1.25em;
    }
  }
`
TableIcon.displayName = 'TableIcon'

export const TablePrice = styled.div`
  color: ${colorTheme.restaurant.tableMobile.price};
  font-size: 1.5em;
  font-family: ${theme.fontRobotoRegular};
  margin-top: 0.63em;

  &.selected {
    color: ${colorTheme.restaurant.tableMobile.selected.price};
  }

  @media screen and (max-width: ${theme.point820}) {
    font-size: 1.13em;
    margin-top: 0.4em;
  }
`
TablePrice.displayName = 'TablePrice'

export const RestWrapper = styled.div`
  padding: 20px;
  position: absolute;
  transform: translate(${props => props.coordX.value}em, ${props => props.coordY.value}em);
	background-color: ${props => props.bgdColor ? colorTheme.restaurant.background : ''};
	border: ${props => props.bgdColor ? `2px dashed ${colorTheme.restaurant.border}` : ''};
	border-radius: 0.7em;
  z-index: ${props => props.zIndex || 0};

	display: flex;
	flex-direction: column;
`
RestWrapper.displayName = 'RestWrapper'

export const RestWall = styled.div`
  border: 1px ${colorTheme.restaurant.wall.border} solid;
  text-align: center;
  height: ${props => props.height.value}em;
  width: ${props => props.width.value}em;
  background-color: ${props => props.color};
`
RestWall.displayName = 'RestWall'
