import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomModal from '../../Elements/modal/CustomModal'
import Button from '../../Elements/buttons/Button'
import { StyledA, TextP } from '../../../style/text/index.styled'
import { selectCompanyData } from '../../../redux/selector/system'
import whatsapp_icon from '../../../img/svg/whatsapp_icon.svg'
import { ContactItem, QrCodeWrapper, WrapperContacts } from './index.styled'
import { QRCodeSVG } from 'qrcode.react'
import { useTranslation } from 'react-i18next'
import MainIcon from '../../../img/svg/main'
import { ContactExtra, ContactWhatsApp, Text } from '../contactsModal/index.styled'
import { setSupportShowExtraContactsModal } from '../../../redux/action/support'

const EVENT_Z_INDEX = 17

const TseDisabledModal = React.memo(({ isOpen, close }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const companyIndex = useSelector(store => store.login.companyIndex)
	const name = useSelector(selectCompanyData('name'))
	const firstName = useSelector(selectCompanyData('firstName'))
	const phoneNumberOfDealer = useSelector(store => store.support.dealerContacts?.supportNumber)
	const whatsAppNumberOfDealer = useSelector(store => store.support.dealerContacts?.whatsAppNumber)
	const isDealer = useSelector(store => store.support.dealerContacts)

	const whatsappHref = firstName ? `https://wa.me/4951154681414?text=Hallo!%20Ich%20bin%20${firstName},%20Firma%20${name},%20Kundennummer%20${companyIndex}.%20Ich%20möchte%20TSE%20aktivieren.` :
		`https://wa.me/4951154681414?text=Hallo!%20Ich%20bin%20Ihre%20Kunden,%20Firma%20${name},%20Kundennummer%20${companyIndex}.%20Ich%20möchte%20TSE%20aktivieren.`
	const preparedWhatsAppNumber = whatsAppNumberOfDealer?.split('').filter(item => /\d/.test(item)).join('')

	const whatsappHrefOfDealer = firstName ? `https://wa.me/${preparedWhatsAppNumber}?text=Hallo!%20Ich%20bin%20${firstName},%20Firma%20${name},%20Kundennummer%20${companyIndex}.%20Ich%20möchte%20TSE%20aktivieren.` :
		`https://wa.me/${preparedWhatsAppNumber}?text=Hallo!%20Ich%20bin%20Ihre%20Kunden,%20Firma%20${name},%20Kundennummer%20${companyIndex}.%20Ich%20möchte%20TSE%20aktivieren.`

	const phoneHrefOfDealer = `tel:${phoneNumberOfDealer}`

	const showExtraContacts = useCallback(() => {
		dispatch(setSupportShowExtraContactsModal(true))
	}, [])

	return (
		<CustomModal isOpen={isOpen}
								 close={close}
								 zIndex={EVENT_Z_INDEX}
								 size="small"
								 title={t('Modal.tse_modal.title_danger')}
								 disableBackButton={true}
								 id="deactivation-warning-modal"
								 button={
									 <>
										 <ContactExtra className="back" onClick={showExtraContacts}>{t('app.modals.extraContacts')}</ContactExtra>
										 <Button icon="checkmark" onClick={close} tabIndex="1" text="Ok" color="green" />
									 </>
								 }>
			<TextP className="red">{t('Modal.tse_modal.title')}</TextP>
			<TextP className="small">{isDealer ? t('Modal.tse_modal.textWithDealer') : t('Modal.tse_modal.text')}</TextP>
			<TextP>{name} - {companyIndex}</TextP>
			<WrapperContacts>
				{isDealer ?
					<>
						{!!phoneNumberOfDealer && <ContactItem>
							<MainIcon icon="telephone_icon_blue" />
							<StyledA href={phoneHrefOfDealer}>{phoneNumberOfDealer}</StyledA>
						</ContactItem>}
						{!!whatsAppNumberOfDealer &&
							<>
								<ContactWhatsApp>
									<MainIcon icon="mobile" />
									<Text>WhatsApp</Text>
								</ContactWhatsApp>
								<QrCodeWrapper>
									<QRCodeSVG value={whatsappHrefOfDealer}
														 level="M"
														 imageSettings={{
															 src: whatsapp_icon,
															 width: 32,
															 height: 32,
															 x: null,
															 y: null,
															 excavate: true,
														 }} />
								</QrCodeWrapper>
							</>}
					</>
					: <>
						<ContactWhatsApp>
							<MainIcon icon="mobile" />
							<Text>WhatsApp</Text>
						</ContactWhatsApp>
						<QrCodeWrapper>
							<QRCodeSVG value={whatsappHref}
												 level="M"
												 imageSettings={{
													 src: whatsapp_icon,
													 width: 32,
													 height: 32,
													 x: null,
													 y: null,
													 excavate: true,
												 }} />
						</QrCodeWrapper>
					</>
				}
			</WrapperContacts>
		</CustomModal>
	)
})

TseDisabledModal.displayName = 'TseDisabledModal'
export default TseDisabledModal
