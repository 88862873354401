import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getNeededDateFormat } from '../../../helper'
import MainIcon from '../../../img/svg/main'
import PlatformIcon from '../../../img/svg/platforms'
import { setAppModal, setAppModalData } from '../../../redux/action/system'
import { selectCompanyIndex } from '../../../redux/selector/system'
import colors from '../../../style/colors'
import Accordion from '../../Elements/accordion'
import Button from '../../Elements/buttons/Button'
import Currency from '../../Elements/currency'
import CustomModal from '../../Elements/modal/CustomModal'
import Order from '../../Elements/modal/order/Order'
import { CarIconWrapper, ModalContent, OrderInfo, OrderInfoBlock, OrderInfoItem, OrderInfoText, OrderTimeBlock, OrderWarning, OrderWarningText, OrderWrap } from './index.styled'
import { confirmExpressOrderThunk } from '../../../redux/thunk/order/confirmExpressOrder'
import { OrderUnpaidContent, OrderUnpaidText, OrderUnpaidWrapper } from '../processingTimeOrder/index.styled'
import { useTheme } from 'styled-components'

const EVENT_Z_INDEX = 984

const ConfirmExpressOrder = React.memo(() => {
	const { t } = useTranslation()
	const { colorTheme } = useTheme()
	const confirmExpressOrderModalShow = useSelector(store => store.system.modal?.confirmExpressOrderModalShow)
	const order = useSelector(store => store.system.modalData?.confirmExpressOrderModalData[0])
	const isProcessingConfirmOrder = useSelector(store => store.order.isProcessingConfirmOrder)
	const dispatch = useDispatch()
	const companyIndex = useSelector(selectCompanyIndex)
	const date = order?.orderType === 'PICKUP' ? order?.expectedPickupTime : order?.expectedDeliveryTime
	const payed = order?.payments?.filter(item => item?.method === order?.paymentMethod)[0]?.payed

	useEffect(() => {
		if (confirmExpressOrderModalShow) {
			// eslint-disable-next-line
			console.log(`Opening popup for confirming the express order orderId=${order.orderId} companyIndex=${companyIndex} path=${window.location.pathname}`)
		}
	}, [confirmExpressOrderModalShow]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleConfirm = useCallback(() => {
		dispatch(confirmExpressOrderThunk(order?.orderId))
	}, [order?.orderId, dispatch])

	const handleClose = useCallback(() => {
		dispatch(setAppModal({ confirmExpressOrderModalShow: false }))
	}, [dispatch])

	const handleDeny = useCallback(() => {
		dispatch(setAppModal({ denyOrderModalShow: true }))
		dispatch(setAppModalData({ denyOrderModalData: [order] }))
		handleClose()
	}, [handleClose, order, dispatch])

	return (
		<>
			<CustomModal isOpen={confirmExpressOrderModalShow}
				close={handleClose}
				id="express-order-modal"
				enableOutsideClick={false}
				size="auto"
				disableBackButton={true}
				zIndex={EVENT_Z_INDEX}
				footerClassname="big-top"
				title={t(`Modal.express_order_modal.title.${order?.platformMetaData?.platformType}`)}
				button={
					<>
						<Button name="cancel" icon="clear" text={t('Modal.deny_order')}
							keyButton="F3" zIndex={EVENT_Z_INDEX}
							onClick={handleDeny}
							className="back"
							disabled={isProcessingConfirmOrder} />
						<Button name="accept" icon="checkmark" color="green"
							keyButton="Enter" zIndex={EVENT_Z_INDEX}
							onClick={handleConfirm}
							text={t('buttons.controls.takeOrder')}
							disabled={isProcessingConfirmOrder} />
					</>
				}>
				<ModalContent>
					<OrderInfo>
						<OrderInfoBlock data-testid="time" className="with-image">
							<OrderTimeBlock>
								<OrderInfoItem>
									<MainIcon icon="clock_on_3" fill={colors.red_bright} />
									<OrderInfoText className="warn">{getNeededDateFormat(date, 'H:mm')}</OrderInfoText>
								</OrderInfoItem>
								<OrderInfoItem>
									<MainIcon icon="date" fill={colors.gray} />
									<OrderInfoText>{getNeededDateFormat(date, 'dd.MM.y')}</OrderInfoText>
								</OrderInfoItem>
							</OrderTimeBlock>
							<CarIconWrapper>
								<MainIcon icon="car_express" className="car_express" />
								<MainIcon icon="express" className="express" />
							</CarIconWrapper>
						</OrderInfoBlock>
						<OrderWarning>
							<MainIcon icon="info" />
							<OrderWarningText>
								{t('Modal.express_order_modal.warning')}
							</OrderWarningText>
						</OrderWarning>
						<OrderInfoBlock data-testid="payment">
							{!payed ? <OrderUnpaidWrapper>
								<OrderUnpaidContent>
									<MainIcon icon={order?.paymentMethod} fill={colors.gray} testId={order?.paymentMethod} />
									<OrderUnpaidText><Currency>{order?.totalPrice}</Currency></OrderUnpaidText>
								</OrderUnpaidContent>
								<OrderUnpaidText className="small">{t('Modal.unpaid_order')}</OrderUnpaidText>
							</OrderUnpaidWrapper> :
							<OrderInfoItem>
								<MainIcon icon={order?.paymentMethod} fill={colors.gray} testId={order?.paymentMethod} />
								<OrderInfoText><Currency>{order?.totalPrice}</Currency></OrderInfoText>
							</OrderInfoItem>}
						</OrderInfoBlock>
						<OrderInfoBlock data-testid="platform">
							<PlatformIcon icon={`${order?.platformMetaData?.platformType}-${colorTheme}`} />
						</OrderInfoBlock>
					</OrderInfo>
					<Accordion titleIcon="report" titleWhenOpen={t('Modal.express_order_modal.viewOrder')} titleWhenClose={t('Modal.express_order_modal.viewOrder')} size="small" outline={true}>
						<OrderWrap>
							<Order order={order} />
						</OrderWrap>
					</Accordion>
				</ModalContent>
			</CustomModal>
		</>
	)
})

ConfirmExpressOrder.displayName = 'ConfirmExpressOrder'
export default ConfirmExpressOrder
