import { APPEND_REPORTS, SET_DATE_FROM, SET_DATE_TO, SET_MONTH_FROM, SET_MONTH_TO, SET_REPORTS, SET_REPORT_LOADING, SET_REPORT_TOTAL_LENGTH, SET_STRIPE_REPORT_DATA, SET_STRIPE_REPORT_DATA_LOADING, SET_STRIPE_REPORT_PRINT_MESSAGE_DATA, SET_STRIPE_REPORT_TO_PRINT } from '../../actionTypes/report'

export const setReports = reports => ({ type: SET_REPORTS, reports })

export const setReportTotalLength = length => ({ type: SET_REPORT_TOTAL_LENGTH, length })

export const appendReports = reports => ({ type: APPEND_REPORTS, reports })

export const setDateTo = dateTo => ({ type: SET_DATE_TO, dateTo })

export const setDateFrom = dateFrom => ({ type: SET_DATE_FROM, dateFrom })

export const setMonthTo = monthTo => ({ type: SET_MONTH_TO, monthTo })

export const setMonthFrom = monthFrom => ({ type: SET_MONTH_FROM, monthFrom })

export const setReportLoading = value => ({ type: SET_REPORT_LOADING, value })

export const setStripeReportData = value => ({ type: SET_STRIPE_REPORT_DATA, value })

export const setStripeReportDataLoading = value => ({ type: SET_STRIPE_REPORT_DATA_LOADING, value })

export const setStripeReportToPrint = value => ({ type: SET_STRIPE_REPORT_TO_PRINT, value })

export const setStripeReportPrintMessageData = value => ({ type: SET_STRIPE_REPORT_PRINT_MESSAGE_DATA, value })
