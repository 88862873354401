import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../buttons/Button'
import CustomModal from '../../../modal/CustomModal'
import { selectCurrencySymbol, selectFormattedCurrency, selectIsNeededZIndex } from '../../../../../redux/selector/system'
import keyboardImg from '../../../../../img/svg/input/keyboard.svg'
import Keyboard from '../../../keyboard'
import ReactDOM from 'react-dom'
import KeyBindings from '../../../keybindings'
import { addCashDeskItem, setCashDeskPreorderDate } from '../../../../../redux/action/cashDesk'
import { addDeliveryCartItem, setDeliveryCartPreorderDate } from '../../../../../redux/action/deliveryCart'
import { updateClientMonitorCartThunk } from '../../../../../redux/thunk/clientMonitorCart'
import { convert2MoneyExchange } from './helper'
import { GetMoneyInputText, GetMoneyInputWrap, MoneyExchangeButtonsWrapper, MoneyExchangeItem, MoneyExchangeList, MoneyExchangeModalWrapper } from './index.styled'
import { getCartPaymentTerminalsThunk, getCartPaymentThunk } from '../../../../../redux/thunk/cartPayment'
import Loader from '../../../loaders'

const EVENT_Z_INDEX = 87
const EVENT_Z_INDEX_FOR_ESC = 88
const PER_LOAD = 10

const monies = ['10', '20', '30', '50', '75', '100', '125', '150']

const MoneyExchangeModal = React.memo(({ isOpen, close, module }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const currencySymbol = useSelector(selectCurrencySymbol)
	const [moneySum, setMoneySum] = useState(0)
	const [moneyKeyboard, setMoneyKeyboard] = useState('')
	const [showKeyboard, setShowKeyboard] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const formattedSum = useSelector(selectFormattedCurrency(moneySum))
	const formattedKeyboard = useSelector(selectFormattedCurrency(typeof moneyKeyboard === 'number' ? moneyKeyboard : Number(moneyKeyboard)))
	const domElement = document.getElementById('content')
	const waiterAccessToken = useSelector(state => state.modalLogin.accessToken)
	const hasModalLogin = !!waiterAccessToken

	const onClose = useCallback(() => {
		close()
		setMoneySum(0)
		setMoneyKeyboard('')
	}, [close])

	const addMoneyExchangeToCart = useCallback(async () => {
		setIsLoading(true)
		const totalSum = moneySum || +moneyKeyboard
		const moneyExchangeItem = convert2MoneyExchange(totalSum, t, currencySymbol)

		if (module === 'CASH_DESK') {
			dispatch(setCashDeskPreorderDate(null))
			dispatch(updateClientMonitorCartThunk('CASH_DESK',
				() => dispatch(addCashDeskItem(moneyExchangeItem))))
		} else if (module === 'DELIVERY') {
			dispatch(setDeliveryCartPreorderDate(null))
			dispatch(updateClientMonitorCartThunk('DELIVERY',
				() => dispatch(addDeliveryCartItem(moneyExchangeItem))))
		}

		await dispatch(getCartPaymentThunk(hasModalLogin))
		await dispatch(getCartPaymentTerminalsThunk(0, PER_LOAD, hasModalLogin))
		setIsLoading(false)
		onClose()
	}, [onClose, moneySum, moneyKeyboard, currencySymbol, t, dispatch, hasModalLogin, module])

	const handleClick = useCallback((cost) => {
		setShowKeyboard(false)
		setMoneyKeyboard('')
		setMoneySum(cost)
	}, [])

	const toggleKeyboard = useCallback(() => {
		setShowKeyboard(!showKeyboard)
	}, [showKeyboard])

	const handleKeyboardOfScreenType = useCallback((value) => {
		setMoneySum(0)
		const regex = /^[\d.,]*$/
		if (!regex.test(value))  {
			return
		}
		if (value.includes(',')) {
			if (!String(moneyKeyboard).includes('.') && String(moneyKeyboard).length) {
				return setMoneyKeyboard(value.replace(',', '.'))
			} else {
				return
			}
		}
		setMoneyKeyboard(value)
	}, [moneyKeyboard])

	const closeKeyboard = useCallback(() => {
		setShowKeyboard(false)
	}, [])

	const handleEnter = useCallback(() => {
		addMoneyExchangeToCart()
	}, [addMoneyExchangeToCart])

	const handleDelete = useCallback(() => {
		setMoneySum(0)
		setMoneyKeyboard(moneyKeyboard => moneyKeyboard.replace(/.$/, '') )
	},[])
	const handleCommaClick = (value) => {
		setMoneySum(0)
		setMoneyKeyboard(moneyKeyboard + value)
	}

	const handleKeyboardType = useCallback((value) => {
		setMoneySum(0)
		setMoneyKeyboard(moneyKeyboard + value)
	}, [moneyKeyboard])

	return (
		<>
			<CustomModal isOpen={isOpen}
									 close={onClose}
									 size="auto"
									 title={t('Cart.MoneyExchange.title')}
									 zIndex={EVENT_Z_INDEX}
									 disableBackButton={true}
									 icon="money-exchange">
				<MoneyExchangeModalWrapper>
					<MoneyExchangeList>
						{monies.map(money => (
							<MoneyExchangeItem key={money}
																 data-testid={`moneyExchangeItem-${money}`}
																 onClick={() => handleClick(+money)}
																 className={moneyKeyboard ? 'noHover' : ''}
																 name={money}>
								{money + ' ' + currencySymbol}
							</MoneyExchangeItem>
						))}
					</MoneyExchangeList>
					<GetMoneyInputWrap className={((moneySum || moneyKeyboard) ? 'active ' : '')}>
						<GetMoneyInputText className="input">
							{moneySum > 0 ? formattedSum : formattedKeyboard}
						</GetMoneyInputText>
						<img src={keyboardImg} alt="keyboard" onClick={toggleKeyboard} className="keyboard" />
					</GetMoneyInputWrap>
				</MoneyExchangeModalWrapper>
				<MoneyExchangeButtonsWrapper>
					<Button onClick={onClose}
									text={t('buttons.controls.cancel_1')}
									icon="clear"
									color="gray"
									name="cansel"
									keyButton="ESC"
									zIndex={EVENT_Z_INDEX_FOR_ESC} />
					<Button onClick={addMoneyExchangeToCart}
									icon="get-cash"
									color="green"
									name="getCash"
									keyButton="F2"
									zIndex={EVENT_Z_INDEX}
									disabled={!moneySum && !moneyKeyboard && isLoading}
									text={isLoading ? <Loader color="white" /> : t('Cart.MoneyExchange.btnModal')} />
				</MoneyExchangeButtonsWrapper>
				{showKeyboard && ReactDOM.createPortal(
					<Keyboard onlyNumbers={true}
										handleType={handleKeyboardOfScreenType}
										currentValue={moneyKeyboard}
										close={closeKeyboard}
										enter={handleEnter} />, domElement)}
			</CustomModal>

			{isNeededZIndex &&
				<KeyBindings enter={handleEnter}
										 delet={handleDelete}
										 comma={() => handleCommaClick('.')}
										 zero={() => handleKeyboardType('0')}
										 one={() => handleKeyboardType('1')}
										 two={() => handleKeyboardType('2')}
										 three={() => handleKeyboardType('3')}
										 four={() => handleKeyboardType('4')}
										 five={() => handleKeyboardType('5')}
										 six={() => handleKeyboardType('6')}
										 seven={() => handleKeyboardType('7')}
										 eight={() => handleKeyboardType('8')}
										 nine={() => handleKeyboardType('9')} />}
		</>
	)
})

MoneyExchangeModal.displayName = 'MoneyExchangeModal'
export default MoneyExchangeModal
