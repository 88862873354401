import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const DropDown = styled.div`
  font-size: 1rem;
  padding: 0.12em;
  border-radius: 0.75em;
  border: 1px solid ${colorTheme.dropDownSelect.border};
  cursor: pointer;
  position: relative;
  z-index: 2;
  background-color: ${colorTheme.dropDownSelect.background};

  &:hover {
    border-radius: 0.75em 0.75em 0 0;

    > .items {
      display: block;
    }
  }
`
DropDown.displayName = 'DropDown'

export const DropDownItems = styled.div`
  font-size: 1em;
  display: none;
  position: absolute;
  top: 2.5em;
  left: -1px;
  width: calc(100% + 2px);
  padding: 0.12em;
  border-radius: 0 0 0.75em 0.75em;
  border: 1px solid ${colorTheme.dropDownSelect.border};
  border-top: none;
  background-color: ${colorTheme.dropDownSelect.background};
`

export const DropDownItem = styled.div`
  display: flex;
  gap: 0.22em;
  font-size: 1.13em;
  font-family: ${theme.fontRobotoBold};
  text-transform: uppercase;
  color: ${colorTheme.dropDownSelect.item.text};
  align-items: center;
  justify-content: center;
  padding: 0.2em 0.28em;
  min-height: 2.06em;
  min-width: 2.06em;
  border-radius: 0.6em;
  border: 1px solid transparent;

  > svg {
    width: 1.22em;
    height: 1.22em;
    flex-shrink: 0;
  }

  &:hover {
    border-color: ${colorTheme.dropDownSelect.item.hover.border};
    color: ${colorTheme.dropDownSelect.item.hover.text};
  }

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.29em;
		min-height: 2.38em;
  	min-width: 3.66em;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.13em;
	}
`
DropDownItem.displayName = 'DropDownItem'

export const DropDownSelected = styled(DropDownItem)`
  color: ${colorTheme.dropDownSelect.item.selected.text};

  &:hover {
    border-color: transparent;
  }
`
DropDownSelected.displayName = 'DropDownSelected'
