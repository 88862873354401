import { retrieveErrorFromApi } from '../../../helper'
import { appendCoupons, appendGiftCoupons, setCouponDisabledForKasse, setCouponErrorIsExist, setCoupons, setCouponsLength, setGiftCoupons, setGiftCouponsLength, setGiftCouponIsCanceled, setGiftCouponIsInvalid, setGiftCouponIsNotExist, setGiftCouponIsUsed } from '../../action/coupon'
import { setAppErrors, setSystemFormButtonSave } from '../../action/system'
import deleteCoupon from '../../axios/coupon/delete'
import fetchCoupons from '../../axios/coupon/getAll'
import storeCoupon from '../../axios/coupon/post'
import updateCoupon from '../../axios/coupon/put'
import getCoupon from '../../axios/coupon/get'
import { resetSystemFormButtonSaveThunk, succeedSystemFormButtonSaveThunk } from '../app'
import fetchGiftCoupons from '../../axios/coupon/giftCoupon/get'
import getIsValidGiftCoupon from '../../axios/coupon/giftCoupon/getIsValid'
import getIsValidMarketingCoupon from '../../axios/coupon/getIsValid'

export const getCouponsThunk = (page, size) => dispatch => {
  return fetchCoupons(page, size)
    .then(res => {
      dispatch(setCouponsLength(parseInt(res.headers['x-coupons-total'])))
      if (page > 0 && size > 0) {
        dispatch(appendCoupons(res.data))
        return
      }
      dispatch(setCoupons(res.data))

    }).catch(error => {
      dispatch(setAppErrors(retrieveErrorFromApi(error)))
    })
}

export const storeCouponThunk = (data) => dispatch => {
	dispatch(setSystemFormButtonSave({ processing: true }))

  return storeCoupon(data)
    .then(() => {
			dispatch(succeedSystemFormButtonSaveThunk())
      return dispatch(getCouponsThunk(0, 10))
    }).catch(error => {
			dispatch(resetSystemFormButtonSaveThunk())
			const responseData = error?.response?.data
			if (responseData?.apierror?.errorStatusCode === 'coupon_code_is_duplicate') {
				return dispatch(setCouponErrorIsExist(true))
			} else {
				return dispatch(setAppErrors(retrieveErrorFromApi(error)))
			}
    })
}

export const deleteCouponThunk = (id) => dispatch => {
  return deleteCoupon(id)
    .then(() => {
      return dispatch(getCouponsThunk(0, 10))
    }).catch(error => {
      dispatch(setAppErrors(retrieveErrorFromApi(error)))
    })
}

export const updateCouponThunk = (id, data) => dispatch => {
	dispatch(setSystemFormButtonSave({ processing: true }))

  return updateCoupon(id, data)
    .then(() => {
			dispatch(succeedSystemFormButtonSaveThunk())
      return dispatch(getCouponsThunk(0, 10))
    }).catch(error => {
			dispatch(resetSystemFormButtonSaveThunk())
      const responseData = error?.response?.data
			if (responseData?.apierror?.errorStatusCode === 'coupon_code_is_duplicate') {
				return dispatch(setCouponErrorIsExist(true))
			} else {
				return dispatch(setAppErrors(retrieveErrorFromApi(error)))
			}
    })
}

export const updateCouponPropertiesThunk = (id, data) => (dispatch) => {
	return updateCoupon(id, data)
		.then(() => {
			return dispatch(getCouponsThunk(0, 10))
		})
		.catch((error) => {
			return dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getIsValidCouponThunk = (codeId, isRestaurant, callbackSuccess, callbackGiftSuccess) => async dispatch => {
	let marketingCoupon = null
	if (!isRestaurant)
		marketingCoupon = await dispatch(getIsValidMarketingCouponThunk(codeId))
	if (isRestaurant || marketingCoupon?.couponIsInvalid) {
		const giftCoupon = await dispatch(getIsValidGiftCouponThunk(codeId))
		if (giftCoupon) callbackGiftSuccess(giftCoupon)
	}
	else if (marketingCoupon && !marketingCoupon?.couponIsInvalid) callbackSuccess(marketingCoupon)
}

export const getIsValidMarketingCouponThunk = (codeId) => async dispatch => {
	try {
		const res = await getIsValidMarketingCoupon(codeId)
		return res.data
	} catch (error) {
		const errorStatusCode = error.response.data?.apierror.errorStatusCode
		const invalidCoupon = errorStatusCode === 'coupon_is_invalid'
		const disabledForKasseCoupon = errorStatusCode === 'coupon_disabled_for_kasse'

		if (invalidCoupon) {
			return { couponIsInvalid: true }
		}
		else if (disabledForKasseCoupon) {
			dispatch(setCouponDisabledForKasse(true))
		}
		else {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		}
	}
}

export const getIsValidGiftCouponThunk = (codeId) => async dispatch => {
	try {
		const res = await getIsValidGiftCoupon(codeId)
		return res.data
	} catch (error) {
		const errorStatusCode = error.response.data?.apierror.errorStatusCode
		const invalidCoupon = errorStatusCode === 'gift_coupon_is_deactivated'
		const usedCoupon = errorStatusCode === 'gift_coupon_is_fully_used'
		const canceledCoupon = errorStatusCode === 'gift_coupon_is_canceled'
		const notExistCoupon = errorStatusCode === 'gift_coupon_is_not_found'

		if (invalidCoupon) {
			dispatch(setGiftCouponIsInvalid(error.response.data?.apierror.expirationTime))
		}
		else if (usedCoupon) {
			dispatch(setGiftCouponIsUsed(true))
		}
		else if (canceledCoupon) {
			dispatch(setGiftCouponIsCanceled(true))
		}
		else if (notExistCoupon) {
			dispatch(setGiftCouponIsNotExist(true))
		}
		else {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		}
	}
}

export const getCouponThunk = (couponId) => async (dispatch) => {
	try {
		const res = await getCoupon(couponId)
		return res.data
	} catch (error) {
		dispatch(setAppErrors(retrieveErrorFromApi(error)))
	}
}

export const getGiftCouponsThunk = (page, size, status = null, code = null) => dispatch => {
  return fetchGiftCoupons(page, size, status, code)
    .then(res => {
      dispatch(setGiftCouponsLength(parseInt(res.headers['x-gift-coupons-total'])))
      if (page > 0 && size > 0) {
        dispatch(appendGiftCoupons(res.data))
        return
      }
      dispatch(setGiftCoupons(res.data))

    }).catch(error => {
      dispatch(setAppErrors(retrieveErrorFromApi(error)))
    })
}
