import React from 'react'
import { statusOptionsStatic } from '../../../../../redux/reducer/order/constants'
import { TableOrderMobileTitle, TextBig } from '../../../../Elements/table/OrdersTable/index.styled'
import PlatformType from '../../../../Elements/table/TableTdElements/PlatformType'
import OrderDate from '../../../../Elements/table/TableTdElements/OrderDate'
import OrderPayment from '../../../../Elements/table/TableTdElements/OrderPayment'
import MainIcon from '../../../../../img/svg/main'
import { getNeededDateFormat } from '../../../../../helper'
import { useTranslation } from 'react-i18next'
import CustomerData from '../../../../Elements/table/TableTdElements/CustomerData'
import { TableTd, TableTdGrid } from '../../../../Elements/table/style/table/td/index.styled'
import { TableTdStatus } from '../../../../Elements/table/old/index.styled'

const TodayPreOrdersTableTdList = React.memo(({ tableHeadersStatic, item }) => {
	const { t } = useTranslation()
	return (
		<>
			<TableTd data-title={tableHeadersStatic[0].title}>
				<TableOrderMobileTitle>{tableHeadersStatic[0].title}</TableOrderMobileTitle>
				<OrderDate item={item} />
			</TableTd>
			<TableTd data-title={tableHeadersStatic[1].title} className="col-mob-3-5">
				<TableOrderMobileTitle>{tableHeadersStatic[1].title} </TableOrderMobileTitle>
				<PlatformType item={item} />
			</TableTd>
			<TableTd data-title={tableHeadersStatic[2].title} className="col-tablet-4-7 col-mob-1-5 row-mob-2">
				<TableOrderMobileTitle>{tableHeadersStatic[2].title} </TableOrderMobileTitle>
				<CustomerData item={item} />
			</TableTd>
			<TableTd data-title={tableHeadersStatic[3].title} className="col-tablet-1-3">
				<TableOrderMobileTitle>{tableHeadersStatic[3].title} </TableOrderMobileTitle>
				<OrderPayment item={item} />
			</TableTd>
			<TableTdStatus data-title={tableHeadersStatic[4].title}
										 className={'const-color col-tablet-4-7 col-mob-3-5 row-mob-3 ' + item?.status.status}>
				<TableOrderMobileTitle>{tableHeadersStatic[4].title} </TableOrderMobileTitle>
				<TableTdGrid>
					<MainIcon icon={item?.status?.status} />
					<TextBig>{t(statusOptionsStatic.filter(status => status.value === item.status?.status).map(filtered => filtered.label))}</TextBig>
				</TableTdGrid>
				<TableTdGrid>
					<MainIcon icon="preorder" />
					<div>{getNeededDateFormat(item.preOrder, 'dd.MM.yyyy')} {getNeededDateFormat(item.preOrder, 'HH:mm')}</div>
				</TableTdGrid>
			</TableTdStatus>
		</>
	)
})

TodayPreOrdersTableTdList.displayName = 'TodayPreOrdersTableTdList'
export default TodayPreOrdersTableTdList
