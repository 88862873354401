import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setRestaurantTable, setRestaurantTableModal } from '../../../../redux/action/restaurant'
import Currency from '../../../Elements/currency'
import colors from '../../../../style/colors'
import { RestWall, RestWrapper, Table, TableIcon, TableList, TablePrice } from './index.styled'
import { fetchRestaurantTablesThunk } from '../../../../redux/thunk/restaurant/table'
import { getTablePrice, tableIsBusy } from '../../../../helper'
import Loader from '../../../Elements/loaders'
import { theme } from '../../../../style/theme'
import { TablesMainWrapper, TablesWrapper } from '../../index.styled'
import { useWindowBreakPoint } from '../../../../hooks/useWindowBreakPoint'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { fillTables } from '../../../../helper/restaurant'
import PinCodeModalLogin from '../../../Elements/PinCodeModalLogin'
import RestaurantIcon from '../../../../img/svg/restaurant'
import RestaurantTable from './table'
import { useLocalStorage } from '../../../../hooks/useLocalStorage'

const Tables = () => {
	const { t } = useTranslation()
	const areas = useSelector(state => state.restaurant.areas)
	const areaId = useSelector(state => state.restaurant.areaId)
	const tables = useSelector(state => state.restaurant.tables)
	const selectedTable = useSelector(state => state.restaurant.table)
	const tablesLoading = useSelector(state => state.restaurant.tablesLoading)

	const pinCodeModalLoginIsNeeded = useSelector(state => state.modalLogin.pinCodeModalLoginIsNeeded)
	const modalLoginUsername = useSelector(state => state.modalLogin.username)

	const showSplitModal = useSelector(state => state.restaurant.showSplitModal)
	const [modalLoginIsOpen, setModalLoginIsOpen] = useState(false)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const isMobile = useWindowBreakPoint(theme.point720)
	const [, setTableId] = useLocalStorage('tableId', null)

	const areaColor = areas?.find(area => area.id === areaId)?.meta?.color
	const hasAccessToChangeTable = pinCodeModalLoginIsNeeded ? modalLoginUsername : true

	useEffect(() => {
		if (areaId && !showSplitModal) {
			dispatch(fetchRestaurantTablesThunk(areaId))
		}
	}, [areaId, showSplitModal]) // eslint-disable-line react-hooks/exhaustive-deps

	const navigateToCart = () => {
		navigate('/restaurant/cart')
	}

	const selectTable = (table) => {
		dispatch(setRestaurantTable(table))
		if (tableIsBusy(table)) {
			dispatch(setRestaurantTableModal(true))
		} else {
			if (hasAccessToChangeTable) {
				navigateToCart()
			} else {
				setModalLoginIsOpen(true)
			}
		}
		setTableId(table?.id)
	}

	const handleModalLoginClose = () => {
		setModalLoginIsOpen(false)
	}

	let restTable = []
	if (!isMobile && tables && tables.length) {
		restTable = fillTables(tables)
	}

	const area = areas?.filter(a => a.id === areaId)
	let walls = []
	let floor = {}
	if (!isMobile && area && area.length) {
		walls = area[0]?.walls || []
		floor = area[0]?.floor || {}
	}

	const getProcessedByWaiter = (table) => {
		if (table.orders?.length) {
			return table.orders[table.orders.length - 1]?.processedBy
		} else if (table.splits?.length) {
			return table.splits[table.splits.length - 1]?.processedBy
		} else {
			return null
		}
	}

	return (
		<>
			<TablesWrapper color={areaColor}>
				<TablesMainWrapper color={floor?.meta?.color}
													 bgdImg={floor?.meta?.color !== '' ? '' : `/img/floors/${floor?.meta?.backgroundImage}.webp`}
													 data-testid="floor">
					<TableList id="restaurant-table-list">
						{!tablesLoading ?
							isMobile ?
								(tables?.map((table, index) =>
									<Table key={index}
												 id={`table_${index + 1}`}
												 selected={selectedTable?.id === table.id}
												 onClick={() => selectTable(table)}
												 className={tableIsBusy(table) ? 'busy' : ''}>
										<TableIcon className={tableIsBusy(table) ? 'busy' : ''}>
											<div className="number">{table.number}</div>
											<RestaurantIcon icon={tableIsBusy(table) ? 'table-closed' : 'table-open'} />
										</TableIcon>
										{tableIsBusy(table) &&
											<TablePrice>
												<Currency>{getTablePrice(table)}</Currency>
											</TablePrice>}
									</Table>
								))
								:
								(restTable?.map((table, i) => {
									const processedBy = getProcessedByWaiter(table)
									return (
										<RestaurantTable key={i}
																		 table={table}
																		 zIndex={restTable.length - i}
																		 index={i}
																		 onClick={() => selectTable(table)}
																		 processedBy={processedBy} />
									)
								}))
							: <div className="loader"><Loader color={isMobile ? colors.white : colors.blue} /></div>}
						{!isMobile ? (walls.map((wall, i) =>
							<RestWrapper key={i} {...wall.meta} >
								<RestWall id={`wall_${i + 1}`} data-testid={`wall_${i + 1}`} {...wall.meta} />
							</RestWrapper>
						)) : null
						}
					</TableList>
				</TablesMainWrapper>
			</TablesWrapper>

			<PinCodeModalLogin isOpen={modalLoginIsOpen}
												 close={handleModalLoginClose}
												 module="RESTAURANT"
												 title={t('restaurant.tables.modal.register_waiter')}
												 group="WAITER"
												 onLogin={navigateToCart}
												 returnZIndex={0} />
		</>
	)
}

Tables.displayName = 'Tables'
export default Tables
