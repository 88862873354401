import styled from 'styled-components'
import { theme } from '../../style/theme'
import { colorTheme } from '../../style/theme/colorTheme'

export const AreasMobileWrapper = styled.div`
  padding: 0.63rem;
  display: grid;
  gap: 0.63rem;
  grid-auto-rows: min-content;
  background: ${colorTheme.restaurant.areas.background};
  min-height: calc(100% - 4.5em);
`
AreasMobileWrapper.displayName = 'AreasMobileWrapper'

export const TablesWrapper = styled.div`
  font-size: 1rem;
  background: ${props => props.color ? props.color : 'transparent'};
  padding: 2.25em 0 2.25em 2.25em;
  height: 100%;

  @media screen and (max-width: ${theme.point820}) {
    padding: 1em;
  }

  @media screen and (max-width: ${theme.point720}) {
    padding: 0.63em 0.44em;
    border-radius: 0 0 0.75em 0.75em;
    background-color: transparent;
  }
`
TablesWrapper.displayName = 'TablesWrapper'

export const TablesMainWrapper = styled.div`
  background-color: ${props => props.color ? props.color : colorTheme.restaurant.areas.tables.background};
 	background-image: ${props => props.bgdImg ? `url(${props.bgdImg})` : ''};
	background-size: auto;

  border-radius: 0.88em 0 0 0.88em;
  height: 100%;
  overflow: auto;
	position: relative;

  @media screen and (max-width: ${theme.point820}) {
    border-radius: 0.75em 0.75em 0 0;
    padding: 0.44em;
  }

  @media screen and (max-width: ${theme.point720}) {
    background-color: transparent;
    margin: 0;
    padding: 0;
    border-radius: 0;
  }
`
TablesMainWrapper.displayName = 'TablesMainWrapper'
