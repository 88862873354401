import styled from 'styled-components'
import colors from '../../../style/colors'
import { TextP } from '../../../style/text/index.styled'

export const WrapperContacts = styled.div`
	display: flex;
	flex-direction: column;
	grid-gap: 0.5em;
	align-items: center;
	margin-top: 0.8em;
`
WrapperContacts.displayName = 'WrapperContacts'

export const ContactIcon = styled.img`
	margin-right: 0.3em;
`
ContactIcon.displayName = 'ContactIcon'

export const ContactItem = styled.div`
	display: flex;
	grid-gap: 0.3em;

	svg {
		width: 2em;
		height: 2em;
	}

	&.smallText {
		svg {
			width: 1.17em;
			height: 1.17em;
		}

		a {
			font-size: 1em;
			color: #111111;
		}
	}
`
ContactItem.displayName = 'ContactItem'

export const ContactWhatsApp = styled(ContactItem)`
	grid-gap: 0;
	align-items: center;
	margin-top: 0.8em;

	svg {
		width: 2.2em;
		height: 2.2em;
	}
`
ContactWhatsApp.displayName = 'ContactWhatsApp'

export const QrCodeWrapper = styled.div`
	width: 10.5em;
	height: 10.5em;
	display: flex;

	& > svg {
		display: block;
		width: 100%;
		height: 100%;
	}
`

export const ContactExtra = styled.div`
	font-size: 1.125em;
	color: ${colors.blue};
	align-self: center;
	cursor: pointer;
	text-decoration: underline;
`
ContactExtra.displayName = 'ContactExtra'

export const Text = styled(TextP)`
	margin-bottom: 0;
`
Text.displayName = 'Text'
