import { createSelector } from 'reselect'
import { selectCurrencySymbol } from '../system'
import { convert2ResultItems, convertToClientMonitorPurchaseItems } from './helper'
import { calcCartItemTotalPrice, getDiscountInCurrency, getPercentDiscountInCurrency, roundPrice } from '../../../helper'
import { getGiftCouponsPayment } from '../../../helper/getGiftCouponsPayment'

export const selectDeliveryCartItems = store => store.deliveryCart.items

export const selectCashDeskItems = store => store.cashDesk.items

export const selectCartInitialDiscount = state => state.cartDiscount.initialDiscount?.discount

export const selectCartDiscount = state => state.cartDiscount?.discount

export const selectCartDiscountType = state => state.cartDiscount?.discountType

export const selectTableCartOrders = store => store.tableCart.orders
export const selectTableSplits = store => store.restaurant.table?.splits
export const selectTableCartNewOrder = store => store.tableCart?.newOrder

export const selectCartExtraDiscount = store => store.cartDiscount?.extraDiscount
export const selectCartExtraDiscountType = store => store.cartDiscount?.extraDiscountType

export const selectCurrentDeliveryCost = store => store.deliveryCart?.currentDeliveryCost

const selectCashDeskGiftCoupons = store => store.cashDesk.giftCoupons
const selectDeliveryCartGiftCoupons = store => store.deliveryCart.giftCoupons

export const selectCouponInCart = (cartType) => createSelector(
	selectDeliveryCartItems,
	selectCashDeskItems,
	(deliveryCartItems, cashDeskItems) => {
		const items = cartType === 'DELIVERY' ? deliveryCartItems : cashDeskItems
		return items?.find(item => item.type === 'MARKETING_COUPON') || null
	}
)

export const selectResultItemsInCart = (cartType) => createSelector(
	selectDeliveryCartItems,
	selectCashDeskItems,
	// TODO selectRestaurantItems
	selectCurrencySymbol,
	(deliveryCartItems, cashDeskItems, currencySymbol) => {
		const items = cartType === 'DELIVERY' ? deliveryCartItems : cashDeskItems
		return convert2ResultItems(items, currencySymbol)
	}
)

export const selectGiftCouponInCart = (cartType) => createSelector(
	selectDeliveryCartItems,
	selectCashDeskItems,
	(deliveryCartItems, cashDeskItems) => {
		const items = cartType === 'DELIVERY' ? deliveryCartItems : cashDeskItems
		return items?.find(item => item.type === 'GIFT_COUPON') || null
	}
)

export const selectHasAnyItemsInCart = (cartType) => createSelector(
	selectDeliveryCartItems,
	selectCashDeskItems,
	selectTableCartOrders,
	selectTableSplits,
	selectTableCartNewOrder,
	(deliveryCartItems, cashDeskItems, orders, splits, newOrder) => {
		if (cartType === 'DELIVERY') {
			return !!deliveryCartItems.length
		} else if (cartType === 'CASH_DESK') {
			return !!cashDeskItems.length

		} else {
			return !!orders?.length || !!splits?.length || newOrder
		}
	}
)

export const selectCartItemsTotal = (cartType) => createSelector(
	selectResultItemsInCart(cartType),
	(resultItems) => {
		return resultItems?.reduce((tp, i) => tp + calcCartItemTotalPrice(i), 0)
	}
)

export const selectCartItemsTotalWithDiscount = (cartType) => createSelector(
	selectResultItemsInCart(cartType),
	(resultItems) => {
		return resultItems?.reduce((tp, i) => tp + calcCartItemTotalPrice(i, true), 0)
	}
)

export const selectCartDiscountInCurrency = (cartType) => createSelector(
	selectGiftCouponInCart(cartType),
	selectCartDiscount,
	selectCartItemsTotalWithDiscount(cartType),
	selectMoneyExchangeInCart(cartType),
	(hasGiftCoupon, discount, itemsTotalWithDiscount, hasMoneyExchange) => {
		const discountValue = (hasGiftCoupon || hasMoneyExchange) ? 0 : discount
		return getPercentDiscountInCurrency(discountValue, itemsTotalWithDiscount)
	}
)

export const selectExtraDiscountInCurrency = (cartType) => createSelector(
	selectCartExtraDiscount,
	selectCartExtraDiscountType,
	selectCartItemsTotal(cartType),
	selectCartDiscountInCurrency(cartType),
	selectGiftCouponInCart(cartType),
	selectMoneyExchangeInCart(cartType),
	(extraDiscount, extraDiscountType, itemsTotal, discountInCurrency, hasGiftCoupon, hasMoneyExchange) => {
		const price = itemsTotal - discountInCurrency
		const discountValue = (hasGiftCoupon || hasMoneyExchange)  ? 0 : extraDiscount
		return getDiscountInCurrency(discountValue, extraDiscountType, price)
	}
)

export const selectCouponDiscountInCurrency = (cartType) => createSelector(
	selectCouponInCart(cartType),
	selectCartItemsTotal(cartType),
	selectCartDiscountInCurrency(cartType),
	selectExtraDiscountInCurrency(cartType),
	(coupon, itemsTotal, discountInCurrency, extraDiscountInCurrency) => {
		const price = itemsTotal - discountInCurrency - extraDiscountInCurrency
		return getDiscountInCurrency(coupon?.initialProduct?.discountData?.discount,
			coupon?.initialProduct?.discountData?.discountType, price)
	}
)

export const selectCartTotalPrice = (cartType) => createSelector(
	selectCartItemsTotal(cartType),
	selectCartDiscountInCurrency(cartType),
	selectExtraDiscountInCurrency(cartType),
	selectCouponDiscountInCurrency(cartType),
	(itemsTotal, discountInCurrency, extraDiscountInCurrency, couponDiscountInCurrency) => {
		return roundPrice(itemsTotal - discountInCurrency - extraDiscountInCurrency - couponDiscountInCurrency)
	}
)

export const selectCartTotalPriceWithGiftCoupons = (cartType, giftCouponSum = 0) => createSelector(
	selectCartTotalPrice(cartType),
	(totalPrice) => {
		return roundPrice(totalPrice - giftCouponSum)
	}
)

export const selectDiscountForItem = (price) => createSelector(
	selectCartDiscount,
	(discount) => {
		return getPercentDiscountInCurrency(discount, price)
	}
)

export const selectDeliveryCost = (cartType) => createSelector(
	selectCurrentDeliveryCost,
	(deliveryCost) => {
		return cartType === 'DELIVERY' ? deliveryCost : 0
	}
)

const selectGiftCoupons = cartType => createSelector(
	selectCashDeskGiftCoupons,
	selectDeliveryCartGiftCoupons,
	(cashDeskGiftCoupons, deliveryCartGiftCoupons) => {
		return cartType === 'DELIVERY' ? deliveryCartGiftCoupons : cashDeskGiftCoupons
	}
)

export const selectClientMonitorItems = (cartType, desktopDeviceId) => createSelector(
	selectResultItemsInCart(cartType),
	selectCartDiscount,
	selectCartDiscountType,
	selectCartDiscountInCurrency(cartType),
	selectCartExtraDiscount,
	selectCartExtraDiscountType,
	selectExtraDiscountInCurrency(cartType),
	selectDeliveryCost(cartType),
	selectCouponDiscountInCurrency(cartType),
	selectGiftCoupons(cartType),
	(
		resultItems,
		discountValue, discountType, discountInCurrency,
		extraDiscountValue, extraDiscountType, extraDiscountInCurrency,
		deliveryCost,
		couponDiscountInCurrency, giftCoupons
	) => {
		return {
			desktopDeviceId,
			purchaseItems: convertToClientMonitorPurchaseItems(resultItems, discountValue, discountType, discountInCurrency, extraDiscountValue, extraDiscountType, extraDiscountInCurrency, deliveryCost, couponDiscountInCurrency),
			payments: getGiftCouponsPayment(giftCoupons)
		}
	}
)

export const selectGiftCouponSum = (cartType) => createSelector(
	selectGiftCoupons(cartType),
	(giftCoupons) => {
		return giftCoupons?.length ? giftCoupons?.reduce((res, el) => res + el.cartBalance, 0) : 0
	}
)

export const selectMoneyExchangeInCart = (cartType) => createSelector(
	selectDeliveryCartItems,
	selectCashDeskItems,
	(deliveryCartItems, cashDeskItems) => {
		const items = cartType === 'DELIVERY' ? deliveryCartItems : cashDeskItems
		return !!items?.find(item => item.type === 'CASH_EXCHANGE')
	}
)
