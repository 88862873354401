import { roundPrice } from '../index'

export const getGiftCouponsPayment = (giftCoupons) => {
	return giftCoupons?.reduce((res, el) => [...res, {
		method: 'GIFT_COUPON', total: el.cartBalance, payed: true, couponMetaData: {
			couponId: el.id, code: el.code,
		},
	}], [])
}

export const getGiftCouponsSum = (giftCoupons) => {
	return giftCoupons.length ? roundPrice(giftCoupons.reduce((res, el) => res + el.cartBalance, 0)) : 0
}

