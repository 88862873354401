import styled from 'styled-components'
import { theme } from '../../../style/theme'
import colors from '../../../style/colors'

export const TseWarningWrapper = styled.div`
	font-size: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.5em;

	@media screen and (max-width: ${theme.point720}) {
    > label {
			width: 100%;
		}
  }
`
TseWarningWrapper.displayName = 'TseWarningWrapper'

export const TseWarningText = styled.div`
	font-family: ${theme.fontRobotoMedium};
	color: ${colors.purple};
	text-align: center;
	font-size: 1.25em;
	font-style: normal;
	font-weight: 500;

	> span {
		font-family: ${theme.fontRobotoMedium};
		font-weight: 500;
		text-decoration: underline;
		cursor: pointer;
	}
`
TseWarningText.displayName = 'TseWarningText'
