import { retrieveErrorFromApi } from '../../../helper'
import { appendTerminals, setPayPalNotification, setPaymentConfigs, setPaymentIsLoading, setPaymentPaypalAccount, setPaymentPaypalAccountNotFound, setPaymentPaypalIsLoading, setPaymentStripeAccount, setPaymentStripeAccountNotFound, setPaymentStripeIsLoading, setPaymentTestStatus, setStripeNotification, setTerminalErrorIsInvalid, setTerminalId, setTerminals, setTerminalsLength } from '../../action/payment'
import { setAppErrors, setSystemFormButtonSave } from '../../action/system'
import getPaymentConfigs from '../../axios/payment/paymentConfigs/get'
import updatePaymentConfigs from '../../axios/payment/paymentConfigs/put'
import testPaymentConfig from '../../axios/payment/paymentConfigs/test/post'
import getStripeAccount from '../../axios/payment/stripeAccount/get'
import createStripeAccountLink from '../../axios/payment/stripeAccount/link/post'
import createStripeAccount from '../../axios/payment/stripeAccount/post'
import deleteTerminal from '../../axios/payment/terminals/delete'
import fetchTerminals from '../../axios/payment/terminals/getAll'
import storeTerminal from '../../axios/payment/terminals/post'
import updateTerminal from '../../axios/payment/terminals/put'
import { resetSystemFormButtonSaveThunk, succeedSystemFormButtonSaveThunk } from '../app'
import updateStripeAccountCapabilities from '../../axios/payment/stripeAccount/put'
import getPaypalAccount from '../../axios/payment/paypalAccount/get'
import createPaypalAccount from '../../axios/payment/paypalAccount/post'
import getPayPalNotification from '../../axios/payment/notification/get'
import updatePayPalNotification from '../../axios/payment/notification/put'
import deletePaypalAccount from '../../axios/payment/paypalAccount/delete'

export const getPaymentConfigsThunk = () => dispatch => {
	return getPaymentConfigs()
		.then((res) => {
			dispatch(setPaymentIsLoading(false))
			dispatch(setPaymentConfigs(res.data))
		}).catch((error) => {
			dispatch(setPaymentIsLoading(false))
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updatePaymentConfigsThunk = (paymentConfigs) => dispatch => {
	dispatch(setSystemFormButtonSave({ processing: true }))
	return updatePaymentConfigs(paymentConfigs)
		.then((res) => {
			dispatch(succeedSystemFormButtonSaveThunk())
			dispatch(setPaymentConfigs(res.data))
		}).catch((error) => {
			dispatch(resetSystemFormButtonSaveThunk())
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const testPaymentConfigsThunk = (paymentMethod, paymentConfig) => dispatch => {
	dispatch(setPaymentTestStatus({ paymentMethod, isLoading: true }))
	return testPaymentConfig(paymentMethod, paymentConfig)
		.then((res) => {
			dispatch(setPaymentTestStatus({ paymentMethod, ...res.data }))
		}).catch((error) => {
			dispatch(setPaymentTestStatus({ paymentMethod, isLoading: false }))
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getStripeAccountThunk = () => dispatch => {
	return getStripeAccount()
		.then((res) => {
			dispatch(setPaymentStripeAccountNotFound(false))
			dispatch(setPaymentStripeAccount(res.data))
		}).catch(() => {
			dispatch(setPaymentStripeAccountNotFound(true))
		})
}

export const createStripeAccountThunk = () => dispatch => {
	dispatch(setPaymentStripeIsLoading(true))
	return createStripeAccount()
		.then((res) => {
			window.location.href = res.data.accountLink
			if (window.location.href.includes('settings/payments'))
				dispatch(setPaymentStripeIsLoading(false))
		}).catch((error) => {
			dispatch(setPaymentStripeIsLoading(false))
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const createStripeAccountLinkThunk = () => dispatch => {
	dispatch(setPaymentStripeIsLoading(true))
	return createStripeAccountLink()
		.then((res) => {
			window.location.href = res.data.accountLink
			if (window.location.href.includes('settings/payments'))
				dispatch(setPaymentStripeIsLoading(false))
		}).catch((error) => {
			dispatch(setPaymentStripeIsLoading(false))
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateStripeAccountCapabilitiesThunk = () => dispatch => {
	dispatch(setPaymentStripeIsLoading(true))
	return updateStripeAccountCapabilities()
		.then((res) => {
			window.location.href = res.data.accountLink
			if (window.location.href.includes('settings/payments'))
				dispatch(setPaymentStripeIsLoading(false))
		}).catch((error) => {
			dispatch(setPaymentStripeIsLoading(false))
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateTerminalByActiveThunk = (id, data) => (dispatch) => {
	return updateTerminal(id, data)
		.then(() => {
			return dispatch(getTerminalsThunk(0, 10))
		})
		.catch((error) => {
			return dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const deleteTerminalThunk = (id) => dispatch => {
	return deleteTerminal(id)
		.then(() => {
			dispatch(setTerminalId(null))
			return dispatch(getTerminalsThunk(0, 10))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getTerminalsThunk = (page, size) => dispatch => {
	return fetchTerminals(page, size)
		.then(res => {
			dispatch(setTerminalsLength(parseInt(res.headers['x-terminals-total'])))
			if (page > 0 && size > 0) {
				return dispatch(appendTerminals(res.data))
			}
			dispatch(setTerminals(res.data))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const storeTerminalThunk = (data) => dispatch => {
	return storeTerminal(data)
		.then((res) => {
			return dispatch(setTerminalId(res.data.id))
		}).catch(error => {
			if (error.response.data?.apierror.errorStatusCode === 'registration_code_is_not_valid') {
				return dispatch(setTerminalErrorIsInvalid(true))
			} else {
				return dispatch(setAppErrors(retrieveErrorFromApi(error)))
			}
		})
}

export const updateTerminalThunk = (id, data) => dispatch => {
	dispatch(setSystemFormButtonSave({ processing: true }))

	return updateTerminal(id, data)
		.then(() => {
			dispatch(succeedSystemFormButtonSaveThunk())
			return dispatch(getTerminalsThunk(0, 10))
		}).catch(error => {
			dispatch(resetSystemFormButtonSaveThunk())
			return dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getPaypalAccountThunk = () => dispatch => {
	return getPaypalAccount()
		.then((res) => {
			dispatch(setPaymentPaypalAccountNotFound(false))
			dispatch(setPaymentPaypalAccount(res.data))
		}).catch(() => {
			dispatch(setPaymentPaypalAccountNotFound(true))
		})
}

export const createPaypalAccountThunk = (agreeWithConditions) => dispatch => {
	dispatch(setPaymentPaypalIsLoading(true))
	return createPaypalAccount(agreeWithConditions)
		.then((res) => {
			window.location.href = res.data.accountLink
		}).catch((error) => {
			dispatch(setPaymentPaypalIsLoading(false))
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const deletePaypalAccountThunk = () => dispatch => {
	return deletePaypalAccount()
		.then(() => {
			dispatch(setPaymentPaypalAccount(null))
			dispatch(getPaypalAccountThunk())
			return dispatch(getPaymentConfigsThunk())
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getPayPalNotificationThunk = () => dispatch => {
	return getPayPalNotification()
		.then((res) => {
			dispatch(setPayPalNotification(res.data))
		}).catch((error) => {
			if (error?.response?.data?.apierror?.status === 'NOT_FOUND')
				dispatch(setPayPalNotification({ newConditionsDeadlineWarningDisabled: false, newConditionsWarningDisabled: false }))
			else dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updatePayPalNotificationThunk = (data) => dispatch => {
	return updatePayPalNotification(data)
		.then((res) => {
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const getStripeNotificationThunk = () => dispatch => {
	return getStripeAccount()
		.then((res) => {
			dispatch(setPaymentStripeAccountNotFound(false))
			dispatch(setStripeNotification(res.data))
		}).catch(() => {
			dispatch(setPaymentStripeAccountNotFound(true))
		})
}
