import { createSelector } from 'reselect'
import { selectCompanyData, selectCompanyIndex } from '../system'

export const selectDealerContacts = store => store.support.dealerContacts

export const selectSupportWhatsappHref = createSelector(
	selectDealerContacts,
	selectCompanyIndex,
	selectCompanyData('name'),
	selectCompanyData('firstName'),
	(dealerContacts, companyIndex, name, firstName) => {
		if (dealerContacts) {
			const dealerWhatsappNumber = dealerContacts?.whatsAppNumber
			if (!dealerWhatsappNumber) {
				return null
			}
			const preparedWhatsAppNumber = dealerWhatsappNumber?.split('').filter(item => /\d/.test(item)).join('')
			const dealerWhatsappHref = firstName ? `https://wa.me/${preparedWhatsAppNumber}?text=Hallo!%20Ich%20bin%20${firstName},%20Firma%20${name},%20Kundennummer%20${companyIndex}` :
				`https://wa.me/${preparedWhatsAppNumber}?text=Hallo!%20Ich%20bin%20Ihre%20Kunden,%20Firma%20${name},%20Kundennummer%20${companyIndex}`
			return dealerWhatsappHref
		} else {
			return firstName ? `https://wa.me/4951154681414?text=Hallo!%20Ich%20bin%20${firstName},%20Firma%20${name},%20Kundennummer%20${companyIndex}` :
				`https://wa.me/4951154681414?text=Hallo!%20Ich%20bin%20Ihre%20Kunden,%20Firma%20${name},%20Kundennummer%20${companyIndex}`
		}
	},
)

export const selectSupportPhone = createSelector(
	selectDealerContacts,
	(dealerContacts) => {
		if (dealerContacts) {
			return dealerContacts?.supportNumber
		} else {
			return '+49 (0) 511 546-814-14'
		}
	},
)
