import React, { useCallback, useEffect, useState } from 'react'
import { ButtonsWrapper, PrintReceiptConfirmationWrap } from './index.styled'
import { useTranslation } from 'react-i18next'
import CustomModal from '../CustomModal'
import Button from '../../buttons/Button'
import CheckBoxInputControlled from '../../inputs/CheckboxInput/controlled'
import { useDispatch, useSelector } from 'react-redux'
import { printRequestsThunk } from '../../../../redux/thunk/order/printRequests'
import { getPrintSettingsThunk, updatePrintSettingsThunk } from '../../../../redux/thunk/printReceipt'
import { setPrintReceiptOrderId, setPrintReceiptPrintSettings } from '../../../../redux/action/printReceipt'
import { setAppEventZIndex } from '../../../../redux/action/system'
import { PRINT_MESSAGE_MODAL_EVENT_Z_INDEX } from '../../../GlobalAppModal/printMessageModal'
import KeyBindings from '../../keybindings'
import { selectIsNeededZIndex } from '../../../../redux/selector/system'
import { getButtonOptions } from './helper'

const EVENT_Z_INDEX = 63

const PrintReceiptConfirmationModal = React.memo(({ module, orderType, returnZIndex }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isNeededZIndex = useSelector(selectIsNeededZIndex(EVENT_Z_INDEX))
	const orderId = useSelector(store => store.printReceipt.orderId)
	const printSettings = useSelector(state => state.printReceipt.printSettings)
	const showPrintReceiptModal = printSettings.length ? printSettings[0]?.disabled : false
	const printSettingId = printSettings.length ? printSettings[0]?.id : null
	const [dontAskAnymore, setDontAskAnymore] = useState(false)
	const [activeButtonIndex, setActiveButtonIndex] = useState(1)
	const printMessageModalShow = useSelector(store => store.system.modal?.printMessageModalShow)
	const cancelButtonOptions = getButtonOptions(activeButtonIndex, 0)
	const acceptButtonOptions = getButtonOptions(activeButtonIndex, 1)

	useEffect(() => {
		if (orderId) {
			dispatch(getPrintSettingsThunk(module, orderType))
		}
	}, [orderId, module, orderType])  // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (showPrintReceiptModal) {
			if (printMessageModalShow) {
				dispatch(setAppEventZIndex(PRINT_MESSAGE_MODAL_EVENT_Z_INDEX))
			} else {
				dispatch(setAppEventZIndex(EVENT_Z_INDEX))
			}
			setActiveButtonIndex(1)
		}
	}, [printMessageModalShow, showPrintReceiptModal])// eslint-disable-line react-hooks/exhaustive-deps

	const updatePrintSettings = useCallback((value) => {
		if (printSettingId) {
			dispatch(updatePrintSettingsThunk(printSettingId, { disabled: !value }))
		}
	}, [dispatch, printSettingId])


	const handleChange = useCallback((e) => {
		setDontAskAnymore(e.target.checked)
		updatePrintSettings(e.target.checked)
	}, [updatePrintSettings])

	const handleClose = useCallback(() => {
		dispatch(setPrintReceiptOrderId(null))
		dispatch(setPrintReceiptPrintSettings([]))
		setDontAskAnymore(false)
	}, [dispatch])

	const handleCancel = useCallback(() => {
		handleClose()
	}, [handleClose])

	const handleAccept = useCallback(async () => {
		await dispatch(printRequestsThunk({ orderId }))
		handleClose()
	}, [dispatch, orderId, handleClose])

	const handleChangeActiveButton = useCallback(() => {
		if (activeButtonIndex === 0) {
			setActiveButtonIndex(activeButtonIndex + 1)
		} else {
			setActiveButtonIndex(activeButtonIndex - 1)
		}
	}, [activeButtonIndex])

	const handleEnter = useCallback(() => {
		if (activeButtonIndex === 0) {
			handleCancel()
		} else {
			handleAccept()
		}
	}, [activeButtonIndex, handleCancel, handleAccept])

	return (
		<>
			<CustomModal isOpen={showPrintReceiptModal}
									size="auto"
									close={handleClose}
									title={t('Modal.requestPrintReceiptModal.title')}
									disableBackButton={true}
									zIndex={EVENT_Z_INDEX}
									returnZIndex={returnZIndex}>
				<PrintReceiptConfirmationWrap>
					<ButtonsWrapper>
						<Button name="cancel_print" icon="no-print-receipt" text={t('Modal.cancel')} color={cancelButtonOptions.color} onClick={handleCancel} keyButton={cancelButtonOptions.key} zIndex={EVENT_Z_INDEX} className="big fullWidth" />
						<Button name="accept_print" icon="print-receipt" text={t('Modal.accept')} color={acceptButtonOptions.color} onClick={handleAccept} keyButton={acceptButtonOptions.key} zIndex={EVENT_Z_INDEX} className="big fullWidth" />
					</ButtonsWrapper>
					<CheckBoxInputControlled label={t('Modal.requestPrintReceiptModal.noAsk')}
																	id="dontAskAnymore"
																	testId="dontAskAnymore"
																	name="dontAskAnymore"
																	checked={dontAskAnymore}
																	onChange={handleChange} />
				</PrintReceiptConfirmationWrap>
			</CustomModal>

			{isNeededZIndex &&
				<KeyBindings enter={handleEnter}
										 arrowUp={handleChangeActiveButton}
										 arrowDown={handleChangeActiveButton}
										 arrowRight={handleChangeActiveButton}
										 arrowLeft={handleChangeActiveButton}
										 tab={handleChangeActiveButton} />}
		</>
	)
})

PrintReceiptConfirmationModal.displayName = 'PrintReceiptConfirmationModal'
export default PrintReceiptConfirmationModal
