import React, { useMemo } from 'react'
import { getCategoryImage } from '../../../redux/reducer/category/constants'

const CategoryIcon = React.memo(({ iconUrl, category }) => {
	const iconUrlValue = iconUrl?.split('/').pop().replace('.svg', '')
	const iconValue = useMemo(() => iconUrlValue || getCategoryImage(category), [iconUrlValue, category])
	const imgSrc = iconUrl || `/img/category/${iconValue}.svg`

	return imgSrc &&
		<svg>
			<use width="100%" height="100%" href={`${imgSrc}?version=2#category_icon`}></use>
		</svg>
})

CategoryIcon.displayName = 'CategoryIcon'
export default CategoryIcon
