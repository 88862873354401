import React from 'react'
import { useSelector } from 'react-redux'
import { selectLastOrderIdByDriver } from '../../../../../redux/selector/order'
import { TableOrderMobileTitle } from '../../OrdersTable/index.styled'
import DriverReturnTime from '../../TableTdElements/DriverReturnTime'
import PlatformDriverArriveTime from '../../TableTdElements/PlatformDriverArriveTime'
import OrderStatus from '../../TableTdElements/OrderStatus'
import OrderType from '../../TableTdElements/OrderType'
import OrderPayment from '../../TableTdElements/OrderPayment'
import CustomerData from '../../TableTdElements/CustomerData'
import PlatformType from '../../TableTdElements/PlatformType'
import OrderDate from '../../TableTdElements/OrderDate'
import OrderDayCounter from '../../TableTdElements/OrderDayCounter'
import { TableTd } from '../../style/table/td/index.styled'
import { TableTdStatus } from '../../old/index.styled'

const UnpaidOrdersTableTdList = React.memo(({ tableHeadersStatic, item }) => {
	const lastOrderIdThisDriverCompleted = useSelector(selectLastOrderIdByDriver(item.processedBy))
	const isCanceled = item?.status?.status === 'CANCELED'
	const isDisabled = isCanceled || !!item?.zNumber
	const beingPreparedOrder = ['BEING_PREPARED', 'READY'].includes(item.status.status)
	const isDeliveryByPlatform = item?.orderType === 'DELIVERY_BY_PLATFORM'
	const partnerDeliveryMetaData = item?.deliveryMetaData?.partner
	const updatedDeliveryByPlatform = !!(item?.orderType === 'DELIVERY' && partnerDeliveryMetaData?.type && partnerDeliveryMetaData?.pickupEta)
	const isPlatformDriverOrder = isDeliveryByPlatform || updatedDeliveryByPlatform
	const driverArriveTime = isDeliveryByPlatform ? item?.platformMetaData?.estimatedProcessTimestamp : partnerDeliveryMetaData?.pickupEta

	return (
		<>
			<TableTd data-title={tableHeadersStatic[0].title}>
				<TableOrderMobileTitle>{tableHeadersStatic[0].title} </TableOrderMobileTitle>
				<OrderDayCounter item={item} />
			</TableTd>
			<TableTd data-title={tableHeadersStatic[1].title}>
				<TableOrderMobileTitle>{tableHeadersStatic[1].title}</TableOrderMobileTitle>
				<OrderDate item={item} />
			</TableTd>
			<TableTd data-title={tableHeadersStatic[2].title} className="col-mob-3-5">
				<TableOrderMobileTitle>{tableHeadersStatic[2].title} </TableOrderMobileTitle>
				<PlatformType item={item} />
			</TableTd>
			<TableTd data-title={tableHeadersStatic[3].title} className="col-tablet-4-7 col-mob-1-5 row-mob-2">
				<TableOrderMobileTitle>{tableHeadersStatic[3].title} </TableOrderMobileTitle>
				<CustomerData item={item} />
			</TableTd>
			<TableTd data-title={tableHeadersStatic[4].title} className="col-tablet-1-3">
				<TableOrderMobileTitle>{tableHeadersStatic[4].title} </TableOrderMobileTitle>
				<OrderPayment item={item} />
			</TableTd>
			<TableTd data-title={tableHeadersStatic[5].title} className={'col-tablet-3-5 col-mob-1-5 row-tablet-2 row-mob-4' + (isDisabled ? ' disabled' : '')}>
				<TableOrderMobileTitle>{tableHeadersStatic[5].title}</TableOrderMobileTitle>
				<OrderType item={item} />
				{lastOrderIdThisDriverCompleted === item.orderId &&
					<DriverReturnTime duration={item.customer.geoData?.duration}
														modifiedDate={item.status?.modifiedDate} />}
				{isPlatformDriverOrder && beingPreparedOrder &&
					<PlatformDriverArriveTime estimatedProcessTimestamp={driverArriveTime}
																		platform={item?.platformMetaData?.platformType} />}
			</TableTd>
			<TableTdStatus data-title={tableHeadersStatic[6].title}
										 className={'const-color col-tablet-5-7 col-mob-1-5 ' + item?.status.status}>
				<TableOrderMobileTitle>{tableHeadersStatic[6].title} </TableOrderMobileTitle>
				<OrderStatus item={item} />
			</TableTdStatus>
		</>
	)
})

UnpaidOrdersTableTdList.displayName = 'UnpaidOrdersTableTdList'
export default UnpaidOrdersTableTdList
