import React, { useCallback, useState } from 'react'
import { ClearKey, EnterKey, KeyboardMain, NumberKey, ShiftKey, SpaceKey, TextKey, ToggleKey } from './index.styled'
import MainIcon from '../../../../../../../img/svg/main'

const Keyboard = ({ handleType, currentValue, enter }) => {
	const [lettersMode, setLettersMode] = useState(true)
	const layout = lettersMode ? 'letters' : ''

	const handleEnter = useCallback(() => {
		enter()
	}, [enter])

	const handleKeyClick = useCallback((value) => {
		handleType(currentValue + value)
	}, [handleType, currentValue])

	const handleClear = useCallback(() => {
		handleType(currentValue?.replace(/.$/, ''))
	}, [handleType, currentValue])

	const handleToggleClick = useCallback(() => {
		setLettersMode(!lettersMode)
	}, [lettersMode])

	const handleMainClick = useCallback(e => {
		e.stopPropagation()
	}, [])

	return (
		<KeyboardMain className={layout} onClick={handleMainClick}>
			<NumberKey className={layout} onClick={() => handleKeyClick('7')}>7</NumberKey>
			<NumberKey className={layout} onClick={() => handleKeyClick('8')}>8</NumberKey>
			<NumberKey className={layout} onClick={() => handleKeyClick('9')}>9</NumberKey>
			<NumberKey className={layout} onClick={() => handleKeyClick('4')}>4</NumberKey>
			<NumberKey className={layout} onClick={() => handleKeyClick('5')}>5</NumberKey>
			<NumberKey className={layout} onClick={() => handleKeyClick('6')}>6</NumberKey>
			<NumberKey className={layout} onClick={() => handleKeyClick('1')}>1</NumberKey>
			<NumberKey className={layout} onClick={() => handleKeyClick('2')}>2</NumberKey>
			<NumberKey className={layout} onClick={() => handleKeyClick('3')}>3</NumberKey>
			<NumberKey className={layout} onClick={() => handleKeyClick('-')}>-</NumberKey>
			<NumberKey className={layout} onClick={() => handleKeyClick('0')}>0</NumberKey>
			<NumberKey className={layout} onClick={() => handleKeyClick('+')}>+</NumberKey>
			<NumberKey className={layout} onClick={() => handleKeyClick(',')}>,</NumberKey>
			<ClearKey className={layout} onClick={handleClear}>
				<MainIcon icon="backspace" testId="backspace" />
			</ClearKey>
			<EnterKey className={layout} onClick={handleEnter}>
				<MainIcon icon="enter" testId="enter" />
			</EnterKey>
			<SpaceKey className={layout} onClick={() => handleKeyClick(' ')}>SPACE</SpaceKey>

			<TextKey className={layout} onClick={() => handleKeyClick('q')}>q</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('w')}>w</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('e')}>e</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('r')}>r</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('t')}>t</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('y')}>y</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('u')}>u</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('i')}>i</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('o')}>o</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('p')}>p</TextKey>

			<ShiftKey className={layout} onClick={() => handleKeyClick('')}>shift</ShiftKey>

			<TextKey className={layout} onClick={() => handleKeyClick('a')}>a</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('s')}>s</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('d')}>d</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('f')}>f</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('g')}>g</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('h')}>h</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('j')}>j</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('k')}>k</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('l')}>l</TextKey>

			<TextKey className={layout} onClick={() => handleKeyClick('z')}>z</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('x')}>x</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('c')}>c</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('v')}>v</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('b')}>b</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('n')}>n</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('m')}>m</TextKey>

			<TextKey className={layout} onClick={() => handleKeyClick('~')}>~</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('!')}>!</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('#')}>#</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('$')}>$</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('%')}>%</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('^')}>^</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('&')}>&</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('*')}>*</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('(')}>(</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick(')')}>)</TextKey>
			<TextKey className={layout} onClick={() => handleKeyClick('_')}>_</TextKey>

			<TextKey className={layout} onClick={() => handleKeyClick('@')}>@</TextKey>

			<ToggleKey className={layout} onClick={handleToggleClick}>{lettersMode ? '123' : 'ABC'}</ToggleKey>
		</KeyboardMain>
	)
}

Keyboard.displayName = 'Keyboard'
export default Keyboard
