import React from 'react'
import { useTranslation } from 'react-i18next'
import { OrderContainer, OrderCount } from './index.styled'
import OrderTotals from './OrderTotals'
import OrderHeader from './OrderHeader'
import OrderProducts from './OrderProducts'

const Order = React.memo(({ order }) => {
	const { t } = useTranslation()

	return (
		<OrderContainer data-testid="order">
			<OrderHeader order={order} />
			<OrderProducts order={order} />
			<OrderTotals order={order} />
			<OrderCount>{t('Modal.count')}: <span>{order?.items?.length}</span></OrderCount>
		</OrderContainer>
	)
})

Order.displayName = 'Order'
export default Order
