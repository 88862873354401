import OrderAndPayPaymentIcon from '../../../../../img/svg/orderAndPay/payment'
import React from 'react'
import { QrCodeIconsWrap, QrCodeTextWrap } from './index.styled'

const QrCodeButtonText = React.memo(({ text }) => (
	<QrCodeTextWrap>
		{text}
		<QrCodeIconsWrap>
			<OrderAndPayPaymentIcon icon="APPLE_PAY" />
			<OrderAndPayPaymentIcon icon="GOOGLE_PAY" />
			<OrderAndPayPaymentIcon icon="AMERICAN_EXPRESS" />
			<OrderAndPayPaymentIcon icon="KLARNA" />
			<OrderAndPayPaymentIcon icon="SOFORT" />
			<OrderAndPayPaymentIcon icon="GIROPAY" />
			<OrderAndPayPaymentIcon icon="PAYPAL" />
		</QrCodeIconsWrap>
	</QrCodeTextWrap>
))

QrCodeButtonText.displayName = 'QrCodeButtonText'
export default QrCodeButtonText
