import styled from 'styled-components'
import { theme } from '../../../../../style/theme'
import { colorTheme } from '../../../../../style/theme/colorTheme'

export const SwapModalAreas = styled.div`
  display: grid;
  align-items: center;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  gap: 10px;
  margin-bottom: 20px;
  overflow-x: auto;

  @media screen and (max-width: ${theme.point720}) {
    gap: 0.63rem;
    grid-auto-rows: min-content;
    grid-auto-flow: initial;
    grid-auto-columns: initial;
  }
`
SwapModalAreas.displayName = 'SwapModalAreas'

export const SwapTablesWrapper = styled.div`
  width: 100%;
  background: ${props => props.colorFloor};
	border: 5px solid ${props => props.color || colorTheme.restaurant.swapModal.border};
	background-image: ${props => props.bgdImg ? `url(${props.bgdImg})` : ''};
	background-size: auto;
  transition: 0.2s;
  height: 90%;

  @media screen and (max-width: ${theme.point720}) {
    padding: 0.63em 0.44em;
    border-radius: 0 0 0.75em 0.75em;
    background-color: transparent;
    height: 100%;
  }
`
SwapTablesWrapper.displayName = 'SwapTablesWrapper'

export const SwapTablesScroll = styled.div`
  overflow-y: auto;
  height: 100%;
  position: relative;

  .loader {
    position: absolute;
    top: 50%;
    left: calc(50% - 75px);
  }

  @media screen and (max-width: ${theme.point720}) {
    background-color: transparent;
    margin: 0;
    padding: 0;
    border-radius: 0;
    height: 100%;
  }
`
SwapTablesScroll.displayName = 'SwapTablesScroll'

export const SwapTables = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 1fr;
  gap: 5px;
  filter: none;
  transition: 0.2s;

  .loader {
    grid-column: 3 / 5;
    height: 2em;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: ${theme.point820}) {
    grid-template-columns: repeat(4, 1fr);
    gap: 0.31em;

    .loader {
      grid-column: 2 / 4;
    }
  }

  @media screen and (max-width: ${theme.point720}) {
    grid-template-columns: repeat(3, 1fr);
    gap: 0.31em;
    filter: none;
    margin-bottom: 0;

    .loader {
      grid-column: 2;
    }
  }
`
SwapTables.displayName = 'SwapTables'
