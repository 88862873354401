import { ADD_TABLE_CART_NEW_ORDER_ITEM_SUBITEM, ADD_TABLE_CART_NEW_ORDER_REMARK_TO_ITEM, ADD_TABLE_CART_ORDER_ITEM, ADD_TABLE_CART_REMARK_ORDER_TO_ITEM, RE_INITIALIZE_TABLE_CART, REMOVE_TABLE_CART_ORDER_ITEM, REMOVE_TABLE_CART_ORDER_ITEM_REMARK, REMOVE_TABLE_CART_ORDER_ITEM_SUBITEM, SET_TABLE_CART_ITEM_DISH_COURSE_INDEX, SET_TABLE_CART_ITEM_FOCUS_INDEX, SET_TABLE_CART_LOADING, SET_TABLE_CART_MODAL_CUSTOM_PRODUCT_IS_OPEN, SET_TABLE_CART_NEW_ORDER, SET_TABLE_CART_ORDER_FOCUS_INDEX, SET_TABLE_CART_ORDERS, SET_TABLE_CART_SELECTED_DISH_COURSE_INDEX, SET_TABLE_CART_SHOW_DISH_COURSE_MODAL, SET_TABLE_CART_SHOW_MOBILE_CART, SET_TABLE_CART_SPLITS, UPDATE_TABLE_CART_ORDER_ITEM } from '../../actionTypes/tableCart'

export const reInitializeTableCart = () => ({ type: RE_INITIALIZE_TABLE_CART })

export const setTableCartOrders = value => ({ type: SET_TABLE_CART_ORDERS, value })

export const addTableCartOrderItem = value => ({ type: ADD_TABLE_CART_ORDER_ITEM, value })

export const removeTableCartOrderItem = value => ({ type: REMOVE_TABLE_CART_ORDER_ITEM, value })

export const removeTableCartOrderSubItem = value => ({ type: REMOVE_TABLE_CART_ORDER_ITEM_SUBITEM, value })

export const updateTableCartOrderItem = value => ({ type: UPDATE_TABLE_CART_ORDER_ITEM, value })

export const setTableCartOrderFocusIndex = value => ({ type: SET_TABLE_CART_ORDER_FOCUS_INDEX, value })

export const setTableCartItemFocusIndex = value => ({ type: SET_TABLE_CART_ITEM_FOCUS_INDEX, value })

export const setTableCartSplits = value => ({ type: SET_TABLE_CART_SPLITS, value })

export const removeTableCartItemRemark = value => ({ type: REMOVE_TABLE_CART_ORDER_ITEM_REMARK, value })

export const addTableCartRemarkToItem = value => ({ type: ADD_TABLE_CART_REMARK_ORDER_TO_ITEM, value })

export const setTableCartLoading = value => ({ type: SET_TABLE_CART_LOADING, value })

export const setTableCartNewOrder = value => ({ type: SET_TABLE_CART_NEW_ORDER, value })

export const addTableCartNewOrderSubItem = value => ({ type: ADD_TABLE_CART_NEW_ORDER_ITEM_SUBITEM, value })

export const addTableCartNewOrderRemarkToItem = value => ({ type: ADD_TABLE_CART_NEW_ORDER_REMARK_TO_ITEM, value })

export const setTableCartSelectedDishCourseIndex = value => ({ type: SET_TABLE_CART_SELECTED_DISH_COURSE_INDEX, value })

export const setTableCartShowDishCourseModal = value => ({ type: SET_TABLE_CART_SHOW_DISH_COURSE_MODAL, value })

export const setTableCartItemDishCourseIndex = value => ({ type: SET_TABLE_CART_ITEM_DISH_COURSE_INDEX, value })

export const setTableCartModalCustomProductIsOpen = value => ({ type: SET_TABLE_CART_MODAL_CUSTOM_PRODUCT_IS_OPEN, value })

export const setTableCartShowMobileCart = value => ({ type: SET_TABLE_CART_SHOW_MOBILE_CART, value })
