import { setAppErrors } from '../../../action/system'
import { retrieveErrorFromApi } from '../../../../helper'
import createTableOrder from '../../../axios/restaurant/table/order/post'
import fetchTableOrders from '../../../axios/restaurant/table/order'
import { removeTableCartOrderSubItem, setTableCartLoading, setTableCartOrders } from '../../../action/tableCart'
import closeTableOrders from '../../../axios/restaurant/table/order/closeOrders/post'
import printTableOrders from '../../../axios/restaurant/table/order/printOrders/post'
import { fetchRestaurantTablesThunk } from '../table'
import deleteTableOrderItem from '../../../axios/restaurant/table/order/deleteItem/delete'
import deleteTableOrderSubItem from '../../../axios/restaurant/table/order/deleteSubItem/delete'
import { setRestaurantShowProcessPaymentErrorModal, setRestaurantTableClosedErrorModal } from '../../../action/restaurant'
import { setPrintReceiptOrderId } from '../../../action/printReceipt'

export const fetchTableOrdersThunk = (tableId, ungroup, status) => dispatch => {
	return fetchTableOrders(tableId, ungroup, status)
		.then(res => {
			if (res.data) {
				return dispatch(setTableCartOrders(res.data))
			}
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const createTableOrderThunk = (tableId, data, hasModalLogin, callbackSuccess) => (dispatch, getState) => {
	return createTableOrder(tableId, data, hasModalLogin)
		.then(res => {
			if (typeof callbackSuccess === 'function') {
				callbackSuccess()
			}
			dispatch(setTableCartLoading(false))
			return dispatch(fetchRestaurantTablesThunk(getState().restaurant.table.areaId))
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		}).finally(() => dispatch(setTableCartLoading(false)))
}

export const closeTableOrdersThunk = (tableId, data, hasModalLogin) => (dispatch, getState) => {
	const isEcTerminalPaymentMethod = data.paymentMethod === 'EC_TERMINAL'
	return closeTableOrders(tableId, data, hasModalLogin)
		.then(res => {
			if (!isEcTerminalPaymentMethod) {
				dispatch(setPrintReceiptOrderId(res.data.orderId))
			}
		}).catch(error => {
			const errorStatusCode = error.response.data?.apierror.errorStatusCode
			if (errorStatusCode === 'terminal_payment_session_invalid') {
				const paymentEC = data.payments.find(payment => payment.method === 'EC_TERMINAL')
				/* eslint no-console: off */
				console.error(`Close table order: paymentId=${data.paymentId} totalPrice=${paymentEC?.total} companyIndex=${getState().login.companyIndex}`)
			} else if (errorStatusCode === 'table_order_is_not_exist') {
				return dispatch(setRestaurantTableClosedErrorModal(true))
			} else if (errorStatusCode === 'order_is_being_paid') {
				return dispatch(setRestaurantShowProcessPaymentErrorModal(true))
			}
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
			throw new Error(error.response.data?.apierror?.message)
		})
}

export const printTableOrdersThunk = (tableId) => dispatch => {
	return printTableOrders(tableId)
		.then(res => {
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const deleteTableOrderItemThunk = (tableId, orderId, itemPositionId, accessToken, hasAdminModalLogin) => dispatch => {
	dispatch(setTableCartLoading(true))
	return deleteTableOrderItem(tableId, orderId, itemPositionId, accessToken, hasAdminModalLogin)
		.then(res => {
			return dispatch(fetchTableOrdersThunk(tableId))
		}).catch(error => {
			const responseData = error?.response?.data
			const errorStatusCode = responseData?.apierror.errorStatusCode
			if (errorStatusCode === 'order_is_being_paid') {
				return dispatch(setRestaurantShowProcessPaymentErrorModal(true))
			} else if (responseData?.apierror?.status !== 'NOT_FOUND') {
				return dispatch(setAppErrors(retrieveErrorFromApi(error)))
			} else {
				// eslint-disable-next-line
				console.warn('Table delete item warn')
				return dispatch(fetchTableOrdersThunk(tableId))
			}
		}).finally(() => dispatch(setTableCartLoading(false)))
}

export const deleteTableOrderSubItemThunk = (tableId, orderId, itemPositionId, subItemPositionId, accessToken, hasAdminModalLogin, orderIndex, itemIndex, subItemIndex) => dispatch => {
	dispatch(setTableCartLoading(true))
	return deleteTableOrderSubItem(tableId, orderId, itemPositionId, subItemPositionId, accessToken, hasAdminModalLogin)
		.then(res => {
			return dispatch(fetchTableOrdersThunk(tableId))
		}).catch(error => {
			const responseData = error?.response?.data
			const errorStatusCode = responseData?.apierror.errorStatusCode
			if (responseData.apierror?.status === 'NOT_FOUND') {
				// eslint-disable-next-line
				console.warn('Table delete sub item warn')
				return dispatch(removeTableCartOrderSubItem({ orderIndex, itemIndex, subItemIndex }))
			} else if (errorStatusCode === 'order_is_being_paid') {
				return dispatch(setRestaurantShowProcessPaymentErrorModal(true))
			} else {
				return dispatch(setAppErrors(retrieveErrorFromApi(error)))
			}
		}).finally(() => dispatch(setTableCartLoading(false)))
}
