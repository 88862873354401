import React from 'react'
import { prepareSetChair, recalcParam } from '../../../../../helper/restaurant'
import { getTablePrice, tableIsBusy } from '../../../../../helper'
import { RestWrapper } from '../index.styled'
import { RestChair, RestTable, RestTablePrice, RestTableTitle, RestTableWaiter, RestTableWaiterWrap, RestTableWhite } from './index.styled'
import Currency from '../../../../Elements/currency'
import MainIcon from '../../../../../img/svg/main'
import { useSelector } from 'react-redux'
import { selectTableWaiterCall } from '../../../../../redux/selector/restaurant'
import { useTranslation } from 'react-i18next'

const RestaurantTable = ({ index, table, bgdColor, testId, onClick, zIndex, processedBy }) => {
	const tableWaiterCall = useSelector(selectTableWaiterCall(table))
	const { t } = useTranslation()

	return (
		<RestWrapper id="restaurant-tables" key={index} {...table.meta} bgdColor={bgdColor} zIndex={zIndex}>
			{table.meta && prepareSetChair(recalcParam(table.meta), false).map((ch, y) => <RestChair key={y} {...ch} />)}
			<RestTableWhite id={`table_${index + 1}`} data-testid={testId} {...table.meta} onClick={onClick}>
				<RestTable {...table.meta} className={tableIsBusy(table) ? 'busy' : ''}>
					<RestTableTitle {...table} className="number">
						{table.number}
					</RestTableTitle>
					{tableIsBusy(table) && (
						<RestTablePrice {...table.meta}>
							<Currency>{getTablePrice(table)}</Currency>
						</RestTablePrice>
					)}
				</RestTable>
			</RestTableWhite>

			{(processedBy && !tableWaiterCall) && (
				<RestTableWaiterWrap data-testid="table-waiter">
					<MainIcon icon="tableWaiter" />
					<RestTableWaiter>{processedBy}</RestTableWaiter>
				</RestTableWaiterWrap>
			)}

			{tableWaiterCall && (
				<RestTableWaiterWrap className="waiter-call" data-testid="table-waiter-call">
					<MainIcon icon="waiter-call" />
					<RestTableWaiter>{t('restaurant.waiterCall')} {processedBy}</RestTableWaiter>
				</RestTableWaiterWrap>
			)}
		</RestWrapper>
	)
}

RestaurantTable.displayName = 'RestaurantTable'
export default RestaurantTable
