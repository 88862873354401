import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setRestaurantOrderModal, setRestaurantOrderSplitModal, setRestaurantPaymentModal, setRestaurantSplitModal, setRestaurantTable, setRestaurantTableModal } from '../../../../../redux/action/restaurant'
import { useNavigate } from 'react-router-dom'
import { printTableOrdersThunk } from '../../../../../redux/thunk/restaurant/order'
import { getTablePrice } from '../../../../../helper'
import ButtonsModal from '../../../../Elements/modal/ButtonsModal'
import { PaymentCost, PaymentTitle, PaymentTitleWrapper } from '../../../../Elements/cart/index.styled'
import Currency from '../../../../Elements/currency'
import { selectGiftCouponInSplit } from '../../../../../redux/selector/restaurant'
import PinCodeModalLogin from '../../../../Elements/PinCodeModalLogin'

const EVENT_Z_INDEX = 32

const ModalTable = () => {
	const table = useSelector(state => state.restaurant.table)
	const showTableModal = useSelector(state => state.restaurant.showTableModal)
	const pinCodeModalLoginIsNeeded = useSelector(state => state.modalLogin.pinCodeModalLoginIsNeeded)
	const modalLoginUsername = useSelector(state => state.modalLogin.username)
	const [modalLoginIsOpen, setModalLoginIsOpen] = useState(false)
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [selectedButtonIndex, setSelectedButtonIndex] = useState(null)
	const hasGiftCoupon = useSelector(selectGiftCouponInSplit)
	const hasAccessToChangeTable = pinCodeModalLoginIsNeeded ? modalLoginUsername : true

	const handleProtectedButtonClick = (index) => {
		dispatch(setRestaurantTableModal(false))
		if (hasAccessToChangeTable) {
			doAfterLogin(index)
		} else {
			setSelectedButtonIndex(index)
			setModalLoginIsOpen(true)
		}
	}

	const navigateToCart = () => {
		navigate('/restaurant/cart')
	}

	let doAfterLogin = (actionIndex) => {
		switch (actionIndex) {
			case 0:
				navigateToCart()
				break
			case 2:
				navigateToPayment()
				break
			case 3:
				navigateToOrder()
				break
			default:
				break
		}
	}

	const handleClose = () => {
		dispatch(setRestaurantTable(null))
		dispatch(setRestaurantTableModal(false))
	}

	const navigateToPayment = () => {
		const ordersWithoutItemsHaveStatusCanceled = table?.orders?.filter(order => order.items.some(im => im.status !== 'CANCELED'))

		if (table?.orders?.length && ordersWithoutItemsHaveStatusCanceled.length) {
			dispatch(setRestaurantPaymentModal(true))
		} else {
			dispatch(setRestaurantSplitModal(true))
		}
	}

	const navigateToOrder = () => {
		if (table?.orders?.length) {
			dispatch(setRestaurantOrderModal(true))
		} else {
			dispatch(setRestaurantOrderSplitModal(true))
		}
	}

	const handlePrintClick = () => {
		dispatch(printTableOrdersThunk(table.id))
		dispatch(setRestaurantTableModal(false))
	}

	const handleModalLoginClose = () => {
		setModalLoginIsOpen(false)
	}

	const handleViewSplits = () => {
		dispatch(setRestaurantSplitModal(true))
		dispatch(setRestaurantTableModal(false))
	}

	const buttons = [
		{ icon: 'menu-card', text: t('restaurant.tables.modal.order'), onClick: () => handleProtectedButtonClick(0), show: !hasGiftCoupon },
		{ icon: 'print', text: t('restaurant.tables.modal.print_interim_check'), onClick: handlePrintClick, show: table?.orders?.length > 0 },
		{ icon: 'splits', text: t('restaurant.tables.modal.viewSplits'), onClick: handleViewSplits, show: table?.splits?.length > 0 },
		{ icon: 'payment', text: t('restaurant.tables.modal.payment'), onClick: () => handleProtectedButtonClick(2), show: true },
		{ icon: 'swap', text: t('restaurant.tables.modal.swap'), onClick: () => handleProtectedButtonClick(3), show: true },
	]

	return (
		<>
			<ButtonsModal isOpen={showTableModal && table}
										close={handleClose}
										title={`${t('restaurant.tables.modal.table')} #` + (table?.number || '')}
										size="small"
										autoFocus={false}
										zIndex={EVENT_Z_INDEX}
										buttons={buttons}>
				<PaymentTitleWrapper>
					<PaymentTitle>{t('Cart.titlePrice')}: </PaymentTitle>
					<PaymentCost><Currency>{getTablePrice(table)}</Currency></PaymentCost>
				</PaymentTitleWrapper>
			</ButtonsModal>

			<PinCodeModalLogin isOpen={modalLoginIsOpen}
												 close={handleModalLoginClose}
												 module="RESTAURANT"
												 title={t('restaurant.tables.modal.register_waiter')}
												 group="WAITER"
												 onLogin={() => doAfterLogin(selectedButtonIndex)}
												 returnZIndex={EVENT_Z_INDEX} />
		</>
	)
}

ModalTable.displayName = 'ModalTable'
export default ModalTable
