import { PATTERN_SIZE_TABLE, fillMeta, point1024, point1280, point1440, point1660, point720, point820 } from './constants'

const detectFontSize = () => {
	if (point720) return 16
	if (point820) return 14
	if (point1024) return 10.7
	if (point1280) return 10.7
	if (point1440) return 12
	if (point1660) return 13.8
	else return 16
}

const pxToMeasurement = (obj) => {
	const fontSize = detectFontSize()
	let value
	switch (obj.type) {
		case 'EM':
			value = Math.floor((obj.value / fontSize) * 100) / 100
			break
		default:
			value = obj.value
	}
	return { value, type: obj.type }
}

export const fillTables = (tables) => {
	if (!tables || !tables.length || tables.length === 0) return []
	let ii = 0
	let yy = 50
	return tables.reduce((res, row) => {
		const { meta } = row
		if (meta.formType) return [...res, { ...row, meta: { ...row.meta } }]
		let newmeta = { ...fillMeta }
		const { width, height } = PATTERN_SIZE_TABLE.reduce((e, res) => e.formType === newmeta.formType ? e : res, { width: 0, height: 0 })
		newmeta['width'] = pxToMeasurement({ ...newmeta['width'], value: width })
		newmeta['height'] = pxToMeasurement({ ...newmeta['height'], value: height })
		if (ii === 6) {
			ii = 0
			yy = yy + height + 45
		}
		newmeta['coordX'] = pxToMeasurement({ ...newmeta['coordX'], value: newmeta['coordX']['value'] + (ii * (width + 45)) })
		newmeta['coordY'] = pxToMeasurement({ ...newmeta['coordY'], value: yy })
		ii++
		return [...res, { ...row, meta: { ...row.meta, ...newmeta } }]
	}, [])
}

const measurementToPx = (obj) => {
	const fontSize = detectFontSize()
	switch (obj.type) {
		case 'EM':
			return obj.value * fontSize
		default:
			return obj.value
	}
}

export const prepareTop = (Pos_y, Pos_x, formType, height, width) => {
	if (formType === 'RECTANGLE') {
		if (Pos_x < 0) return Pos_y
		if (Pos_x > width || width - Pos_x < 5) return Pos_y
		if (Pos_y < height / 2) return -8
		if (Pos_y > height / 2) return height + 6
	}
	return Pos_y
}

export const prepareLeft = (Pos_y, Pos_x, formType, height, width) => {
	if (formType === 'RECTANGLE') {
		if (Pos_x > width || width - Pos_x < 5) return width + 4
	}
	return Pos_x
}

const calcDeltaSizeChair = (tab) => {
	const { width, height } = PATTERN_SIZE_TABLE.reduce((e, res) => e.formType === tab.formType ? e : res, { width: 0, height: 0 })
	if (!width) return 1

	return Math.floor(((tab.width > tab.height ? tab.width : tab.height) / (width > height ? width : height)) * 100) / 100
}

const calcSideChair = (side_num, table) => {
	const koef = 1
	if (table.chairCount <= 6 || !table.chairCount) {
		let s_chair = table.chairCount > 1 ? Number(table.chairCount / 2) : 1
		let remainder = table.chairCount === 1 ? 0 : table.chairCount % 2
		let side_chair
		if (side_num === 1) {
			side_chair = [s_chair + remainder, 0]
		} else {
			side_chair = [s_chair + remainder, s_chair]
		}
		return { side_chair, koef }
	}
	if (table.chairCount < 15) {
		let s_chair = table.chairCount - 8 > 1 ? Math.floor(Number((table.chairCount - 8) / 2)) : 1
		let remainder = table.chairCount - 8 === 1 ? 0 : Math.floor((table.chairCount - 8) % 2)
		let side_chair
		if (table.chairCount - 8 === 1) {
			side_chair = [4, 4, s_chair + remainder, 0]
		} else {
			side_chair = [4, 4, s_chair + remainder, s_chair]
		}
		return { side_chair, koef }
	}
	if (table.chairCount < 20) {
		let s_chair = table.chairCount - 10 > 1 ? Math.floor(Number((table.chairCount - 10) / 2)) : 1
		let remainder = table.chairCount - 10 === 1 ? 0 : Math.floor((table.chairCount - 10) % 2)
		let side_chair
		if (table.chairCount - 10 === 1) {
			side_chair = [5, 5, s_chair + remainder, 0]
		} else {
			side_chair = [5, 5, s_chair + remainder, s_chair]
		}

		return { side_chair, koef: 1.5 }
	}
	if (table.chairCount < 25) {
		let s_chair = table.chairCount - 14 > 1 ? Math.floor(Number((table.chairCount - 14) / 2)) : 1
		let remainder = table.chairCount - 14 === 1 ? 0 : Math.floor((table.chairCount - 14) % 2)
		let side_chair
		if (table.chairCount - 14 === 1) {
			side_chair = [7, 7, s_chair + remainder, 0]
		} else {
			side_chair = [7, 7, s_chair + remainder, s_chair]
		}

		return { side_chair, koef: 2 }
	}
	if (table.chairCount >= 25) {
		let s_chair = table.chairCount - 20 > 1 ? Math.floor(Number((table.chairCount - 20) / 2)) : 1
		let remainder = table.chairCount - 20 === 1 ? 0 : Math.floor((table.chairCount - 20) % 2)
		let side_chair
		if (table.chairCount - 20 === 1) {
			side_chair = [10, 10, s_chair + remainder, 0]
		} else {
			side_chair = [10, 10, s_chair + remainder, s_chair]
		}

		return { side_chair, koef: 2.5 }
	}
	return {}
}

export const recalcParam = (table) => {
	const { formType, chairCount, width, height } = table
	const recWidth = measurementToPx(width) + 40
	const recHeight = measurementToPx(height) + 40
	return { formType, chairCount, width: recWidth, height: recHeight }
}

const prepareSetChairSq = (table, settings) => {
	let sChair = []
	let x = 0, y = 0
	let side_num = table.chairCount > 6 ? 4 : table.chairCount > 1 ? 2 : 1
	let s_chair = table.chairCount > 1 ? Number(table.chairCount / 2) : 1
	let remainder = table.chairCount % 2
	let { side_chair, koef } = calcSideChair(side_num, table)
	let x_fix
	let y_fix
	x_fix = [table.width / (s_chair + remainder + 1), table.width / (s_chair + remainder + 1), table.width / 3]
	y_fix = [7, table.height - 33]
	for (let i = 0; i < side_num; i++) {
		for (let z = 0; z < side_chair[i]; z++) {
			x_fix = [table.width / (side_chair[i] + 2), table.width / (side_chair[i] + 2), 3, table.width - 32]
			y_fix = [3, table.height - 32, table.height / (side_chair[i] + 2), table.height / (side_chair[i] + 2)]
			if (i < 2) {
				x = x_fix[i] + z * table.width / (5 * koef)
				y = y_fix[i]
			} else {
				x = x_fix[i]
				y = y_fix[i] + z * table.height / (5 * koef)
			}
			const top = y
			const left = x
			const delta = calcDeltaSizeChair(table)
			sChair = [...sChair, { top, left, delta }]

		}
	}
	return !settings ? sChair : { [table.id]: sChair }
}

export const prepareSetChair = (table, settings = true) => {
	if (table.formType === 'RECTANGLE') {
		return prepareSetChairSq(table, settings)
	}
	let sChair = []
	let angle = 0
	const step = (2 * Math.PI) / table.chairCount
	for (let i = 1; i <= table.chairCount; i++) {
		let top = 0
		let left = 0

		if (table.formType === 'OVAL' || table.formType === 'CIRCLE') {
			top = Math.round(table.height / 2 + (table.height / 2 - 18) * Math.sin(angle) - 15)
			left = Math.round(table.width / 2 + (table.width / 2 - 18) * Math.cos(angle) - 15)

			if (top === 39 && left === 9 && (table.width === 208 || table.width === 209)) {
				top = 9
				left = 93
			}

			angle += step
		} else {
			const y = Math.round(table.height / 1.6 * Math.sin(angle) + table.height / 2 - 2)
			const x = Math.round(table.width / 2.1 * Math.cos(angle) + table.height / 2)

			top = prepareTop(y, x, table.formType, table.height, table.width)
			left = prepareLeft(y, x, table.formType, table.height, table.width)

			angle += step
		}
		const delta = calcDeltaSizeChair(table)
		sChair = [...sChair, { top, left, delta }]
	}
	return !settings ? sChair : { [table.id]: sChair }
}
