import { SET_QR_CODE_CONFIG, SET_MENU_CONFIG } from '../../actionTypes/orderAndPayConfigs'

const initialState = {
	qrCodeConfig: null,
	menuConfig: null,
}

export function orderAndPayConfigsReducer (state = initialState, action) {
	switch (action.type) {
		case SET_QR_CODE_CONFIG:
			return {
				...state,
				qrCodeConfig: action.value,
			}
		case SET_MENU_CONFIG:
			return {
				...state,
				menuConfig: action.value,
			}
		default:
			return state
	}
}
