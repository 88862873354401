import styled from 'styled-components'
import { colorTheme } from '../../../../../../style/theme/colorTheme'
import { theme } from '../../../../../../style/theme'

export const TableHead = styled.thead`
	@media screen and (max-width: ${theme.point820}) {
		display: none;
	}
`

export const TableHeadTr = styled.tr`
	height: 3.3em;
	position: relative;

	&:after {
		display: block;
		border-radius: 0.6em;
		content: '';
		width: 100%;
		height: 3.3em;
		position: absolute;
		pointer-events: none;
		top: 0;
		left: 0;
		box-shadow: 0.07em 1.12em 3.12em 0em rgba(0, 0, 0, 0.15);
	}

	${props => props.isScrollBody && `
		position: sticky;
		top: 0;
		z-index: 3;
  	border-bottom: 2px solid gray;
		border-radius: 0;
  `}
`

export const TableTh = styled.th`
	padding: 0.3em;
	background: ${colorTheme.table.tableTh.background};
	text-align: left;
	color: ${colorTheme.table.tableTh.color};
	font-family: ${theme.fontRobotoRegular};
	font-size: 1em;
	font-weight: normal;
	width: ${props => props.width ? props.width : 'initial'};

	&:first-child {
		border-bottom-left-radius: 0.6em;
		border-top-left-radius: 0.6em;
		padding-left: 1.75em;
	}

	&:last-child {
		border-bottom-right-radius: 0.6em;
		border-top-right-radius: 0.6em;
		padding-right: 1em;
	}

	& svg {
		width: 1.3em;
		height: 0.8em;
		vertical-align: middle;
	}

	&.sortBy {
		color: ${colorTheme.table.tableTh.sortBy};
		text-decoration: underline;
		cursor: pointer;
	}

	&.bold {
		font-weight: 600;
	}

	&.cart-setting {
		&:first-child {
			padding-left: 1em;
		}

		&:nth-child(2) {
			padding-right: 0.75em;
		}

		&:last-child {
			padding-left: 0.75em;
			padding-right: 0;
		}
	}
`

export const TableThInner = styled.div`
	display: flex;
	gap: 0.25em;

	svg {
		width: 0.85em;
		height: 0.6em;
	}

	&.partner-driver {
		width: min-content;

		svg {
			width: 1.9em;
			height: 1.05em;
			flex-shrink: 0;
		}
	}
`
