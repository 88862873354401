import { getNeededDateFormat } from '../../../../../../helper'

export const isShowIngredients = (item) => {
	return !!(['MAIN_DISH', 'BEVERAGE'].includes(item.type) && item?.ingredients)
}

export const getOrderGroupCategories = (items) => {
	let category = null
	if (!items) return
	return items
		.reduce((res, el) => {
			if (!el?.category) {
				const products = items.filter((e) => !e?.category)
				return !res.find((e) => e.category === '') ? [...res, { category: '', products }] : res
			} else if (category !== el?.category) {
				const products = items.filter((e) => e.category === el.category)
				category = el.category
				return !res.find((e) => e.category === el.category) ? [...res, { category, products }] : res
			}
			return res
		}, [])
		.sort((a, b) => {
			if (a.category.length === 0) return 1
			if (b.category.length === 0) return -1
			return 0
		})
}

export const getOrderTime = (orderDate) => {
	const date = getNeededDateFormat(orderDate, 'dd.MM')
	const time = getNeededDateFormat(orderDate, 'H:mm')
	return { date, time }
}
