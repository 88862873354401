import styled from 'styled-components'
import colors from '../../../style/colors'
import { theme } from '../../../style/theme'

export const ModalContent = styled.div`
  display: grid;
	align-items: start;
	gap: 0.63em;
	max-height: calc(100vh - 20em);
	width: 100%;
	max-width: 51.88em;
  overflow-y: auto;

	@media screen and (max-width: ${theme.point720}) {
		max-height: 100%;
  }
`

ModalContent.displayName = 'ModalContent'

export const OrderInfoBlock = styled.div`
  border-radius: 0.75em;
  border: 1px solid ${colors.gray_light};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.87em;
  height: 7.5em;

	> .FOODORA_PLUGIN {
		width: 9.3em;
		height: 1.68em;
	}

	&.with-image {
		display: grid;
		position: relative;
	}

  @media screen and (max-width: ${theme.point820}) {
		gap: 0.63em;
		padding: 0.63em;

		&.with-image {
			gap: 1.43em;
		}
  }

  @media screen and (max-width: ${theme.point720}) {
		&.with-image {
			gap: 1.25em;
		}
  }
`
OrderInfoBlock.displayName = 'OrderInfoBlock'

export const OrderWarning = styled.div`
	display: flex;
	gap: 0.44em;

	> svg {
		width: 1.5em;
		height: 1.5em;
		flex-shrink: 0;
		fill: ${colors.blue};
	}
`
OrderWarning.displayName = 'OrderWarning'

export const OrderWarningText = styled.div`
	font-family: ${theme.fontRoboto};
	color: ${colors.gray_dark};
	font-weight: 400;
	text-align: left;
`
OrderWarningText.displayName = 'OrderWarningText'

export const OrderInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.63em;

	${OrderInfoBlock}:first-child, ${OrderWarning} {
		grid-column: 1 / 3;
	}

  @media screen and (max-width: ${theme.point720}) {
    grid-template-columns: 1fr;

		${OrderInfoBlock}:first-child, ${OrderWarning} {
			grid-column: 1;
		}
  }
`

OrderInfo.displayName = 'OrderInfo'

export const OrderInfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.31em;

  > svg {
    width: 1.63em;
    height: 1.63em;
    flex-shrink: 0;
  }
`
OrderInfoItem.displayName = 'OrderInfoItem'

export const OrderTimeBlock = styled.div`
	display: flex;
	gap: 1.88em;

	@media screen and (max-width: ${theme.point820}) {
    flex-direction: column;
		align-items: start;
		gap: 0.63em;
		grid-column: 2;
		grid-row: 1;
  }
`
OrderTimeBlock.displayName = 'OrderTimeBlock'

export const CarIconWrapper = styled.div`
	position: absolute;
	left: 1.87em;
	display: flex;
	gap: 0.13em;

	> svg.car_express {
		width: 5.19em;
	 	height: 2.94em;
	}

	> svg.express {
		width: 4.94em;
	 	height: 1.56em;
	}

	@media screen and (max-width: ${theme.point820}) {
		position: static;
		grid-column: 1;
	}
`
CarIconWrapper.displayName = 'CarIconWrapper'

export const OrderWrap = styled.div`
  height: 15em;
  width: 100%;
  display: flex;
  flex-direction: column;
	padding: 0.63em 0 0 0.63em;

  @media screen and (max-width: ${theme.point820}) {
    height: auto;
    width: 100%;
  }
`
OrderWrap.displayName = 'OrderWrap'

export const OrderInfoText = styled.div`
	font-size: 1.25em;
	font-family: ${theme.fontRobotoMedium};
	font-weight: 500;
	color: ${colors.purple};

	&.warn {
		color: ${colors.red_bright};
	}
`
OrderInfoText.displayName = 'OrderInfoText'
