import styled from 'styled-components'
import { theme } from '../../../style/theme'
import { colorTheme } from '../../../style/theme/colorTheme'

export const DatePickerWrapper = styled.div`
  font-size: 1rem;

  .react-datepicker {
    width: 100%;
    font-size: 1em;
    border-top: 1px solid ${colorTheme.datepicker.borderTop};
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    background: transparent;
  }

  .react-datepicker__header {
    background: transparent;
    border: none;
    padding: 0;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__day-names {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin-bottom: 1.13em;
  }

  .react-datepicker__day-name {
    font-family: ${theme.fontRobotoMedium};
    font-size: 1.25em;
    color: ${colorTheme.datepicker.text};
    text-transform: uppercase;
    width: auto;
    height: auto;
    display: block;
    margin: 0;
    padding: 0;
  }

  .react-datepicker__month {
    margin: 0;
    display: grid;
    gap: 0.19em;
    filter: drop-shadow(0px 1.25em 6.25em rgba(0, 0, 0, 0.15));
  }

  .react-datepicker__week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0.19em;
  }

  .react-datepicker__month-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.19em;
  }

  .react-datepicker__day,
  .react-datepicker__month-text {
    background: ${colorTheme.datepicker.day.background};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5em;
    font-family: ${theme.fontRobotoMedium};
    font-size: 1.5em;
    font-weight: normal;
    color: ${colorTheme.datepicker.day.text};
    min-width: 3.13em;
    min-height: 2.92em;
    margin: 0;

    &:nth-child(6), &:nth-child(7) {
      color: ${colorTheme.datepicker.day.textGray};
    }
  }

  .react-datepicker__day--outside-month {
    opacity: 0.5;
  }

  .react-datepicker__day--selected,
  .react-datepicker__month--selected,
  .react-datepicker__month--selected:hover,
  .react-datepicker__day--selected:nth-child(6),
  .react-datepicker__day--selected:nth-child(7) {
    background: ${colorTheme.datepicker.day.selected.background};
    color: ${colorTheme.datepicker.day.selected.text};
    font-weight: bold;
  }

  .react-datepicker__day--disabled {
    background: ${colorTheme.datepicker.day.disabled.background};
    opacity: 0.3;
    cursor: not-allowed;
  }

  .react-datepicker__input-time-container {
    margin: 1.63em 0 1.56em;
  }

  .react-datepicker-time__caption {
    display: none;
  }

  .react-datepicker-time__input-container {
    width: 100%;
  }

  .react-datepicker-time__input {
    margin: 0 !important;
    width: 100%;
  }

  .react-datepicker-popper {
      //background-color: ${colorTheme.datepicker.popper.background};
    //padding: 1.5em;
    //box-sizing: border-box;
    //border-radius: 1em;
    //box-shadow: 0 0 10em 3em rgba(0, 0, 0, 0.3);
    z-index: 100 !important;
  }

  &.small {
    font-size: 0.6rem;

    .react-datepicker-popper {
      background-color: ${colorTheme.datepicker.popper.background};
      padding: 1.5em;
      box-sizing: border-box;
      border-radius: 1em;
      box-shadow: 0 0 10em 3em rgba(0, 0, 0, 0.3);
      z-index: 2;
    }

    .react-datepicker__triangle {
      display: none !important;
    }
  }

  @media screen and (max-width: ${theme.point720}) {
    .react-datepicker__day,
    .react-datepicker__month-text {
      width: 100%;
      min-width: 2.5em;
      min-height: 3em;
    }

    .react-datepicker__day-names {
      margin-bottom: 0.63em;
    }

    .react-datepicker__input-time-container {
      margin: 0.63em 0 0.63em;
    }
  }

  @media screen and (max-width: ${theme.point520}) {
    .react-datepicker__day,
    .react-datepicker__month-text {
      min-width: 2em;
      min-height: 2em;
    }
  }
`

export const DatePickerHeaderWrapper = styled.div`
  padding: 0;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${theme.fontRobotoMedium};
  font-size: 1.5em;
  font-weight: 500;
  color: ${colorTheme.datepicker.header.text};
  margin-top: 1em;
  margin-bottom: 1.42em;
	user-select: none;

  > button {
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    display: flex;
    width: 0.54em;
    height: 0.96em;
    cursor: pointer;

    > svg {
      width: 100%;
      height: 100%;
      stroke: ${colorTheme.datepicker.arrow};
    }
  }

  .img {
    margin-right: 0.54em;
    width: 1.25em;
    height: 1.13em;
    fill: ${colorTheme.datepicker.icon};
  }

  .prev {
    margin-right: 0.83em;
  }

  .next {
    margin-left: 0.83em;
  }

  @media screen and (max-width: ${theme.point720}) {
    margin-top: 0.63em;
    margin-bottom: 0.63em;
  }
`
DatePickerHeaderWrapper.displayName = 'DatePickerHeaderWrapper'

export const DatePickerTimeWrapper = styled.div`
  display: flex;
`

export const DatePickerSelect = styled.label`
  font-size: 1em;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0;
  height: 4.69em;
  width: 50%;
  border-radius: 0.75em;
  border: 2px solid ${colorTheme.datepicker.select.border};
  background: transparent;
  cursor: pointer;

  select {
    appearance: none;
    font-family: ${theme.fontRobotoMedium};
    font-size: 1.5em;
    color: ${colorTheme.datepicker.select.text};
    width: 100%;
    height: 100%;
    border-radius: 0.5em;
    padding: 0.2em 0.92em;
    background: transparent;
    cursor: pointer;
  }

  &:first-child {
    border-radius: 0.75em 0 0 0.75em;
    border-right-width: 1px;

    select {
      padding-left: 2.71em;
    }
  }

  &:nth-child(2) {
    border-left-width: 1px;
    border-radius: 0 0.75em 0.75em 0;
  }

  .clock {
    position: absolute;
    top: calc(50% - 0.94em);
    left: 1.38em;
    margin: 0;
    width: 1.88em;
    height: 1.88em;
    z-index: -1;
  }

  .arrow {
    position: absolute;
    top: calc(50% - 0.75em);
    right: 1.38em;
    margin: 0;
    width: 0.88em;
    height: 1.5em;
    z-index: -1;
  }

  @media screen and (max-width: ${theme.point720}) {
    select {
      z-index: 1;
    }

    .clock, .arrow {
      z-index: 0;
    }
  }
`
