import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactDOM from 'react-dom'
import ButtonsModal from '../../modal/ButtonsModal'
import keyboardImg from '../../../../img/svg/input/keyboard.svg'
import { useDispatch, useSelector } from 'react-redux'
import { InputRemarkWrapper, ModalPaymentWrapper } from './index.styled'
import { selectFormattedCurrency, selectHasAnyGroup } from '../../../../redux/selector/system'
import Keyboard from '../../keyboard'
import ModalChangeCalculator from '../../changeCalculator'
import { PaymentCost, PaymentTitle, PaymentTitleWrapper } from '../index.styled'
import ModalTerminalButtons from '../ModalTerminalButtons'
import Currency from '../../currency'
import { createCartPaymentInvoiceConfigsThunk, getCartPaymentInvoiceConfigsThunk, getCartPaymentTerminalsThunk, getCartPaymentThunk } from '../../../../redux/thunk/cartPayment'
import ModalTerminalPaymentProcess from '../ModalTerminalPaymentProcess'
import { setCartPaymentSelectedTerminal } from '../../../../redux/action/cartPayment'
import InvoiceModals from '../InvoiceModals'
import QrCodeButtonText from './QrCodeButtonText'
import ModalQrCodePayment from '../ModalQrCodePayment'

const EVENT_Z_INDEX = 4
const PER_LOAD = 10
const EC_TERMINAL_LIMIT_SUM = 0.5

const ModalPayment = React.memo(({
	id = 'cart-payment', isOpen, closeModal, openModal,
	remark, totalSum, module, orderType, handlePayment, resetCart,
	changeCalculatorIsNeeded, isDeferredPayment = false, updatePaymentMode = false, isPreorder = false,
	isMoneyExchange = false, printReceipt = false, updateOrder = false,
}) => {
	const { t } = useTranslation()
	const [remarkText, setRemarkText] = useState('')
	const [paymentMethod, setPaymentMethod] = useState('')
	const [showKeyboard, setShowKeyboard] = useState(false)
	const [modalChangeCalculatorIsOpen, setModalChangeCalculatorIsOpen] = useState(false)
	const [modalTerminalButtonsIsOpen, setModalTerminalButtonsIsOpen] = useState(false)
	const [modalTerminalPaymentProcessIsOpen, setModalTerminalPaymentProcessIsOpen] = useState(false)
	const [ecTerminalTips, setEcTerminalTips] = useState(0)
	const [modalInvoiceConfigsIsOpen, setModalInvoiceConfigsIsOpen] = useState(false)
	const [modalInvoiceCustomerDataIsOpen, setModalInvoiceCustomerDataIsOpen] = useState(false)
	const [modalQrCodePaymentIsOpen, setModalQrCodePaymentIsOpen] = useState(false)

	const inputRemark = useRef()
	const domElement = document.getElementById('content')
	const hasAdminRole = useSelector(selectHasAnyGroup(['OWNER', 'SUPERADMIN', 'ADMIN']))

	const payments = useSelector(store => store.cartPayment.payments)
	const terminals = useSelector(store => store.cartPayment.terminals)
	const terminalsLength = useSelector(store => store.cartPayment.terminalsLength)
	const waiterAccessToken = useSelector(state => state.modalLogin.accessToken)
	const formattedEcTerminalLimitSum = useSelector(selectFormattedCurrency(EC_TERMINAL_LIMIT_SUM))
	const hasModalLogin = !!waiterAccessToken

	const dispatch = useDispatch()

	const existTerminal = payments.find(payment => payment.paymentMethod === 'EC_TERMINAL') || false
	const isNeededDeferredPayment = orderType === 'PICKUP' && !updatePaymentMode
	const isDelivery = orderType === 'DELIVERY'
	const isExistInvoicePaymentConfig = !!useSelector(state => state.cartPayment.invoiceConfigs)
	const disableInvoicePayment = !hasAdminRole && !isExistInvoicePaymentConfig
	const isTerminalPaymentLow = totalSum < EC_TERMINAL_LIMIT_SUM
	const disableTerminalPayment = !isDelivery ? !terminalsLength || isTerminalPaymentLow : false

	useEffect(() => {
		if (isOpen) {
			dispatch(getCartPaymentThunk(hasModalLogin))
			dispatch(getCartPaymentTerminalsThunk(0, PER_LOAD, hasModalLogin))
			dispatch(getCartPaymentInvoiceConfigsThunk())
		}
	}, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (remark) setRemarkText(remark)
	}, [remark])

	const resetRemark = useCallback(() => {
		setRemarkText('')
	}, [])

	const handleEcTerminalPaymentClick = useCallback((remark) => {
		if (!isDelivery) {
			if (terminalsLength > 1) {
				setModalTerminalButtonsIsOpen(true)
			} else {
				dispatch(setCartPaymentSelectedTerminal(terminals[0]))
				if (isMoneyExchange) {
					setModalTerminalPaymentProcessIsOpen(true)
				} else {
					setModalChangeCalculatorIsOpen(true)
				}
			}
		} else {
			const paymentMethodAfterChange = 'EC_AT_DOORSTEP'
			handlePayment({ paymentMethod: paymentMethodAfterChange, remark })
			resetRemark()
		}
	}, [dispatch, handlePayment, isDelivery, resetRemark, terminals, terminalsLength, isMoneyExchange])

	const handleCashPaymentClick = useCallback((remark) => {
		if (changeCalculatorIsNeeded) {
			setModalChangeCalculatorIsOpen(true)
		} else {
			handlePayment({ paymentMethod: 'CASH', remark })
			resetRemark()
		}
	}, [changeCalculatorIsNeeded, handlePayment, resetRemark])

	const handleInvoicePaymentClick = useCallback(() => {
		if (!isExistInvoicePaymentConfig) {
			setModalInvoiceConfigsIsOpen(true)
		} else {
			setModalInvoiceCustomerDataIsOpen(true)
		}
	}, [isExistInvoicePaymentConfig])

	const handlePaymentClick = useCallback((paymentMethod) => {
		setPaymentMethod(paymentMethod)
		const remarkNotEmpty = remarkText.trim() === '' ? null : remarkText
		const isECTerminal = paymentMethod === 'EC_TERMINAL'
		const isCash = paymentMethod === 'CASH'
		const isInvoice = paymentMethod === 'INVOICE'
		const isQrCode = paymentMethod === 'QR_CODE'

		if (isECTerminal) {
			handleEcTerminalPaymentClick(remarkNotEmpty)
		} else if (isCash) {
			handleCashPaymentClick(remarkNotEmpty)
		} else if (isInvoice) {
			handleInvoicePaymentClick()
		} else if (isQrCode) {
			setModalQrCodePaymentIsOpen(true)
		} else {
			handlePayment({ paymentMethod, remark: remarkNotEmpty })
			resetRemark()
		}
		closeModal(!(isECTerminal && !isDelivery))
	}, [closeModal, handlePayment, remarkText, setPaymentMethod, isDelivery, handleCashPaymentClick, handleEcTerminalPaymentClick, handleInvoicePaymentClick, resetRemark])

	const handleDeferredPaymentClick = useCallback(() => {
		const remarkNotEmpty = remarkText.trim() === '' ? null : remarkText
		handlePayment({ paymentMethod: 'CASH', remark: remarkNotEmpty, deferred: true })
		resetRemark()
		closeModal(true)
	}, [closeModal, handlePayment, remarkText, resetRemark])

	const closeModalTerminalPaymentProcess = useCallback(() => {
		dispatch(setCartPaymentSelectedTerminal(null))
		setModalTerminalPaymentProcessIsOpen(false)
	}, [dispatch])

	const handlePaymentTerminal = useCallback(async (paymentId, tipsFromTerminal) => {
		const remarkNotEmpty = remarkText.trim() === '' ? null : remarkText
		await handlePayment({ paymentId, paymentMethod: 'EC_TERMINAL', tips: ecTerminalTips + tipsFromTerminal, remark: remarkNotEmpty })
		resetRemark()
	}, [handlePayment, ecTerminalTips, remarkText, resetRemark])

	const handlePaymentInvoice = useCallback((customer) => {
		setModalInvoiceCustomerDataIsOpen(false)
		const remarkNotEmpty = remarkText.trim() === '' ? null : remarkText
		handlePayment({ paymentMethod: 'INVOICE', remark: remarkNotEmpty, customer })
		resetRemark()
	}, [handlePayment, remarkText, resetRemark])

	const disablePaymentButton = useCallback((paymentMethod) => {
		switch (paymentMethod) {
			case 'EC_TERMINAL':
				return disableTerminalPayment
			case 'INVOICE':
				return disableInvoicePayment
			default:
				return false
		}
	}, [disableInvoicePayment, disableTerminalPayment])

	const showPaymentButton = useCallback((paymentMethod) => {
		switch (paymentMethod) {
			case 'INVOICE':
				return !isDeferredPayment && !updatePaymentMode && !isMoneyExchange
			case 'QR_CODE':
				return !isPreorder && !isDelivery && !isDeferredPayment && !updatePaymentMode && !isMoneyExchange && !updateOrder
			case 'CASH':
				return !isMoneyExchange
			default:
				return true
		}
	}, [isPreorder, isDelivery, isDeferredPayment, updatePaymentMode, isMoneyExchange, updateOrder])

	const paymentButtonMessage = useCallback((paymentMethod) => {
		const isEcTerminal = paymentMethod === 'EC_TERMINAL'
		const isInvoice = paymentMethod === 'INVOICE'
		if (isInvoice && disableInvoicePayment) {
			return { text: t('Modal.payment.invoice_disable_message') }
		} else if (!isDelivery && isEcTerminal && isTerminalPaymentLow) {
			return { text: t('Modal.payment.terminal_disable_message', { sum: formattedEcTerminalLimitSum }) }
		} else {
			return null
		}
	}, [isDelivery, disableInvoicePayment, isTerminalPaymentLow, t, formattedEcTerminalLimitSum])

	const dynamicPaymentButtons = useMemo(() => {
		const staticOnlinePayment = { icon: 'ONLINE', text: t('order.payment_type.online'), onClick: () => handlePaymentClick('ONLINE'), show: !isDeferredPayment && !isMoneyExchange, disabled: false, testId: 'paymentButton' }
		const staticCreditCardPayment = { icon: 'CREDIT_CARD_AT_DOORSTEP', text: t('order.payment_type.credit_card'), onClick: () => handlePaymentClick('CREDIT_CARD_AT_DOORSTEP'), show: !existTerminal && !isMoneyExchange, disabled: false, testId: 'paymentButton' }
		const deferredPaymentButton = { icon: 'payment', text: t('order.payment_type.deferred_payment'), onClick: () => handleDeferredPaymentClick(), show: isNeededDeferredPayment && !isMoneyExchange, disabled: false, testId: 'paymentButton' }
		const dynamicPayments = payments.map(payment => {
			const isQrCode = payment.paymentMethod === 'QR_CODE'
			return ({
				icon: payment?.paymentMethod,
				text: isQrCode ? <QrCodeButtonText text={t(`Payments.payments.${payment.paymentMethod}`)} /> : t(`Payments.payments.${payment.paymentMethod}`),
				onClick: () => handlePaymentClick(payment.paymentMethod),
				show: showPaymentButton(payment.paymentMethod),
				disabled: payment?.disabledForPay || disablePaymentButton(payment.paymentMethod),
				testId: 'paymentButton',
				message: paymentButtonMessage(payment.paymentMethod),
			})
		})
		dynamicPayments.splice(1, 0, staticOnlinePayment, staticCreditCardPayment)
		return [...dynamicPayments, deferredPaymentButton]
	}, [payments, handlePaymentClick, existTerminal, isDeferredPayment, isNeededDeferredPayment, handleDeferredPaymentClick, t, disablePaymentButton, showPaymentButton, isMoneyExchange, paymentButtonMessage])

	const onChangeRemark = event => {
		const value = event.target.value
		setRemarkText(value)
	}

	const toggleKeyboard = useCallback(() => {
		setShowKeyboard(!showKeyboard)
	}, [showKeyboard])

	const closeKeyboard = useCallback(() => {
		setShowKeyboard(false)
	}, [])

	const handleEnter = useCallback(() => {
		setShowKeyboard(false)
	}, [])

	const handleClose = useCallback(() => {
		resetRemark()
		closeModal()
	}, [closeModal, resetRemark])

	const handleKeyboardType = useCallback((textCallbackKay) => {
		setRemarkText(textCallbackKay)
	}, [])

	const closeChangeModal = useCallback(() => {
		setModalChangeCalculatorIsOpen(false)
		dispatch(setCartPaymentSelectedTerminal(null))
		if (paymentMethod === 'EC_TERMINAL' && terminalsLength > 1) {
			setModalTerminalButtonsIsOpen(true)
		} else {
			openModal()
		}
	}, [openModal, terminalsLength, paymentMethod, dispatch])

	const handleModalChangePayment = useCallback(({ differenceSum }) => {
		if (paymentMethod === 'EC_TERMINAL') {
			setEcTerminalTips(differenceSum)
			setModalTerminalPaymentProcessIsOpen(true)
			setModalChangeCalculatorIsOpen(false)
		} else {
			setModalChangeCalculatorIsOpen(false)
			const remarkNotEmpty = remarkText.trim() === '' ? null : remarkText
			handlePayment({ paymentMethod: 'CASH', remark: remarkNotEmpty })
			resetRemark()
		}
	}, [paymentMethod, remarkText, handlePayment, resetRemark])

	const closeTerminalButtonsModal = useCallback(() => {
		setModalTerminalButtonsIsOpen(false)
		openModal()
	}, [openModal])

	const reOpenModalChangeCalculator = useCallback(() => {
		setModalTerminalPaymentProcessIsOpen(false)
		if (isMoneyExchange) {
			closeChangeModal()
		} else {
			setModalChangeCalculatorIsOpen(true)
		}
	}, [closeChangeModal, isMoneyExchange])

	const handleTerminalSelected = useCallback(() => {
		setModalTerminalButtonsIsOpen(false)
		if (isMoneyExchange) {
			setModalTerminalPaymentProcessIsOpen(true)
		} else {
			setModalChangeCalculatorIsOpen(true)
		}
	}, [isMoneyExchange])

	const submitInvoiceConfig = useCallback(async (data) => {
		await dispatch(createCartPaymentInvoiceConfigsThunk(data))
		setModalInvoiceConfigsIsOpen(false)
		setModalInvoiceCustomerDataIsOpen(true)
	}, [dispatch])

	const closeInvoiceConfigsModal = useCallback(() => {
		setModalInvoiceConfigsIsOpen(false)
		openModal()
	}, [openModal])

	const closeInvoiceCustomerDataModal = useCallback(() => {
		setModalInvoiceCustomerDataIsOpen(false)
		openModal()
	}, [openModal])

	const closeModalQrCodePayment = useCallback(() => {
		setModalQrCodePaymentIsOpen(false)
	}, [])

	const handlePaymentQrCode = useCallback(async (paymentId, paymentLink) => {
		const remarkNotEmpty = remarkText.trim() === '' ? null : remarkText
		await handlePayment({ paymentId, paymentLink, paymentMethod: 'QR_CODE', remark: remarkNotEmpty })
		resetRemark()
	}, [handlePayment, remarkText, resetRemark])

	return (
		<ModalPaymentWrapper>
			<ButtonsModal id={id}
										isOpen={isOpen}
										close={handleClose}
										size="small"
										title={t('Modal.payment.title')}
										zIndex={EVENT_Z_INDEX}
										buttons={dynamicPaymentButtons}>
				<PaymentTitleWrapper>
					<PaymentTitle>{t('Cart.titlePrice')}: </PaymentTitle>
					<PaymentCost><Currency>{totalSum}</Currency></PaymentCost>
				</PaymentTitleWrapper>
				{!isDeferredPayment && !isMoneyExchange &&
					<InputRemarkWrapper className={showKeyboard ? 'active' : ''}>
						<input name="remark"
									 onChange={onChangeRemark}
									 value={remarkText}
									 ref={inputRemark}
									 placeholder={t('Modal.notice')}
									 autoComplete="off"
									 role="presentation"
									 inputMode="tel" />
						<img src={keyboardImg} alt="keyboard" onClick={toggleKeyboard} />
					</InputRemarkWrapper>}
			</ButtonsModal>
			{showKeyboard && ReactDOM.createPortal(
				<Keyboard onlyNumbers={false}
									handleType={handleKeyboardType}
									currentValue={remarkText}
									close={closeKeyboard}
									enter={handleEnter} />, domElement)}

			<ModalTerminalButtons isOpen={modalTerminalButtonsIsOpen}
														close={closeTerminalButtonsModal}
														totalPrice={totalSum}
														onTerminalSelected={handleTerminalSelected} />
			<ModalChangeCalculator isOpen={modalChangeCalculatorIsOpen}
														 close={closeChangeModal}
														 totalPrice={totalSum}
														 isEcTerminal={paymentMethod === 'EC_TERMINAL'}
														 handlePayment={handleModalChangePayment} />
			<ModalTerminalPaymentProcess isOpen={modalTerminalPaymentProcessIsOpen}
																	 totalPrice={totalSum + ecTerminalTips}
																	 navigateBack={reOpenModalChangeCalculator}
																	 close={closeModalTerminalPaymentProcess}
																	 handlePayment={handlePaymentTerminal}
																	 isMoneyExchange={isMoneyExchange}
																	 printReceipt={printReceipt}
																	 resetCart={resetCart}
																	 ecTerminalTips={ecTerminalTips} />
			<InvoiceModals invoiceConfigsModalIsOpen={modalInvoiceConfigsIsOpen}
										 closeInvoiceConfigsModal={closeInvoiceConfigsModal}
										 submitInvoiceConfig={submitInvoiceConfig}
										 invoiceCustomerDataModalIsOpen={modalInvoiceCustomerDataIsOpen}
										 closeInvoiceCustomerDataModal={closeInvoiceCustomerDataModal}
										 handlePayment={handlePaymentInvoice}
										 captureCustomerData={isDelivery} />
			<ModalQrCodePayment isOpen={modalQrCodePaymentIsOpen}
													totalPrice={totalSum}
													close={closeModalQrCodePayment}
													openPaymentModal={openModal}
													handlePayment={handlePaymentQrCode}
													printReceipt={module === 'CASH_DESK'}
													resetCart={resetCart} />

		</ModalPaymentWrapper>
	)
})

ModalPayment.displayName = 'ModalPayment'
export default ModalPayment
