import { OrderCompanyTitle, OrderHeaderWrap, OrderRemark, OrderSubTitle } from '../index.styled'
import { getOrderTime } from '../helper'
import { getNeededDateFormat } from '../../../../../../helper'
import React from 'react'
import { useTranslation } from 'react-i18next'

const OrderHeader = React.memo(({ order }) => {
	const { t } = useTranslation()
	const showCustomerFloor = order?.customer?.extraAddressInfo && order?.platformMetaData?.platformType === 'ONLINE_SHOP'

	const customerNumberText = order?.customer?.customerNumber &&
		<>{t('CompanyData.CompanyId')}: {order?.customer?.customerNumber}, </>
	const phoneNumberText = order?.customer?.phoneNumber &&
		<>Tel. {order?.customer?.phoneNumber}, </>
	const companyNameText = order?.customer?.companyName &&
		<>{order?.customer?.companyName}, </>
	const customerNameText = order?.customer?.name &&
		<>{order?.customer?.name}, </>
	const addressText = (order?.customer?.street || order?.customer?.streetNumber || order?.customer?.city || order?.customer?.postalCode) &&
		<>{order?.customer?.street} {order?.customer?.streetNumber}, {order?.customer?.city}, {order?.customer?.postalCode}</>
	const customerFloorText = showCustomerFloor &&
		<>, {order?.customer?.extraAddressInfo}</>

	const partnerDeliveryMetaData = order?.deliveryMetaData?.partner
	const orderEstimatedProcessTimestamp = order?.platformMetaData?.estimatedProcessTimestamp
	const isDeliveryByPlatform = order?.orderType === 'DELIVERY_BY_PLATFORM'
	const updatedDeliveryByPlatform = !!(order?.orderType === 'DELIVERY' && partnerDeliveryMetaData?.type && partnerDeliveryMetaData?.pickupEta)
	const isPlatformDriverOrder = isDeliveryByPlatform || updatedDeliveryByPlatform
	const driverArriveTime = isDeliveryByPlatform ?
		(orderEstimatedProcessTimestamp || order?.preOrder) :
		partnerDeliveryMetaData?.pickupEta
	const isPartnerDriverOrder = partnerDeliveryMetaData?.deliveryId
	const expectedDeliveryTime = isPlatformDriverOrder ? partnerDeliveryMetaData?.dropoffEta : orderEstimatedProcessTimestamp
	const isExpressDelivery = order?.expressDelivery

	return (
		<OrderHeaderWrap>
			<OrderCompanyTitle data-testid="customer-info" className="center">
				<div>{t('Modal.invoice')}
					{order?.platformMetaData?.platformName &&
						<b> {order?.platformMetaData?.platformName}: #{order?.platformMetaData?.refId}</b>}
					{order?.kioskMetaData &&
						<b> {t('Modal.kiosk')}: #{order?.kioskMetaData?.refId}</b>}
				</div>
				<div>{t('Modal.orderDate', getOrderTime(order?.orderDate))}
					{order?.orderDayCounter && <b> {t('Modal.number')}: {order?.orderDayCounter}</b>}
				</div>
				{customerNumberText}
				{customerNameText}
				{phoneNumberText}
				{companyNameText}
				{addressText}
				{customerFloorText}
			</OrderCompanyTitle>
			{order?.preOrder && !isDeliveryByPlatform &&
				<OrderSubTitle className="center bold">{t('Modal.preorderDate', getOrderTime(order?.preOrder))}</OrderSubTitle>}
			{isExpressDelivery && order?.expectedDeliveryTime &&
				<OrderSubTitle className="center bold">{t('Modal.expressDate', getOrderTime(order?.expectedDeliveryTime))}</OrderSubTitle>}
			{isExpressDelivery && order?.expectedPickupTime &&
				<OrderSubTitle className="center bold">{t('Modal.expressDate', getOrderTime(order?.expectedPickupTime))}</OrderSubTitle>}
			{(order?.orderType === 'DELIVERY' && expectedDeliveryTime && !order?.preOrder) &&
				<OrderSubTitle className="center bold">{t('Modal.deliveryTime', { time: getNeededDateFormat(expectedDeliveryTime, 'H:mm') })}</OrderSubTitle>}
			{isPlatformDriverOrder && !isPartnerDriverOrder &&
				<OrderSubTitle className="center bold">
					{t('Modal.platformDriverArriveTime', { arriveTime: getNeededDateFormat(driverArriveTime, 'H:mm | dd.MM') })}
				</OrderSubTitle>}
			{order?.remark && <OrderRemark>{order?.remark}</OrderRemark>}
		</OrderHeaderWrap>
	)
})

OrderHeader.displayName = 'OrderHeader'
export default OrderHeader
