import React from 'react'
import CartProductSearch from '../../../../Elements/cart/CartProductSearch'
import ModalCustomProduct from '../../../../Elements/cart/ModalCustomProduct'
import { addTableCartNewOrderRemarkToItem, addTableCartNewOrderSubItem, addTableCartRemarkToItem, setTableCartModalCustomProductIsOpen, updateTableCartOrderItem } from '../../../../../redux/action/tableCart'
import { selectCurrentOrdersInTableCart, selectGiftCouponInTableCart } from '../../../../../redux/selector/restaurant'
import { useDispatch, useSelector } from 'react-redux'
import { notifyToast } from '../../../../../helper'
import { setCartCategoryCategoryId, setCartCategoryProductType } from '../../../../../redux/action/cartCategory'
import { useTranslation } from 'react-i18next'
import searchProduct from '../../../../../redux/axios/product/searchProduct'
import { setAppEventZIndex } from '../../../../../redux/action/system'

const CartProducts = React.memo(({ createNewOrder, submitCart }) => {
	const { t } = useTranslation()
	const orderFocusIndex = useSelector(state => state.tableCart.orderFocusIndex)
	const itemFocusIndex = useSelector(state => state.tableCart.itemFocusIndex)
	const modalCustomProductIsOpen = useSelector(state => state.tableCart.modalCustomProductIsOpen)
	const hasGiftCoupon = useSelector(selectGiftCouponInTableCart)
	const orders = useSelector(selectCurrentOrdersInTableCart)
	const focusedItem = (orderFocusIndex >= 0 && itemFocusIndex >= 0 && orders && orders[orderFocusIndex]?.items) ?
		orders[orderFocusIndex].items[itemFocusIndex] : null
	const focusedItemRemark = orders && orders[orderFocusIndex]?.items && orders[orderFocusIndex].items[itemFocusIndex]?.remark
	const itemExist = !!orders && orders[orderFocusIndex]?.items?.length
	const dispatch = useDispatch()

	const addProduct = (item, updateMode = false) => {
		if (updateMode) {
			if (focusedItem) {
				dispatch(updateTableCartOrderItem({ item, itemIndex: itemFocusIndex }))
			}
		} else {
			createNewOrder(item)
		}
	}

	const addTopping = (toppingItem) => {
		if (orders[orderFocusIndex].items[itemFocusIndex].type === 'MENU') {
			return notifyToast({ message: t('shoppingCart.warning') }, 'warning')
		}
		if (!orders[orderFocusIndex].id) {
			dispatch(addTableCartNewOrderSubItem({ itemIndex: itemFocusIndex, item: toppingItem }))
		} else {
			return notifyToast({ message: t('shoppingCart.mainDishWarning') }, 'warning')
		}
	}

	const addRemarkToItem = (comment) => {
		if (focusedItem) {
			if (!orders[orderFocusIndex].id) {
				dispatch(addTableCartNewOrderRemarkToItem({ itemFocusIndex, comment }))
			} else {
				dispatch(addTableCartRemarkToItem({ orderFocusIndex, itemFocusIndex, comment }))
			}
		}
	}

	const closeModalCustomProduct = () => {
		dispatch(setTableCartModalCustomProductIsOpen(false))
		dispatch(searchProduct(''))
		dispatch(setAppEventZIndex(0))
	}

	const clearSearchParams = () => {
		dispatch(setCartCategoryProductType(null))
		dispatch(setCartCategoryCategoryId(null))
	}

	return (
		<>
			<CartProductSearch {...{ focusedItem, module: 'RESTAURANT', orderType: 'RESTAURANT', clearSearchParams, addTopping, hasGiftCoupon }}
												 handleF2={submitCart} addMainDish={addProduct} cartChangedTrigger={orders} />

			<ModalCustomProduct isOpen={modalCustomProductIsOpen}
													close={closeModalCustomProduct}
													addProduct={addProduct}
													addRemarkToItem={addRemarkToItem}
													focusedItemRemark={focusedItemRemark}
													itemExist={itemExist} />
		</>
	)
})

CartProducts.displayName = 'CartProducts'
export default CartProducts
