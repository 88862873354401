import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import MainIcon from '../../../img/svg/main'
import { useDispatch, useSelector } from 'react-redux'
import CustomInfoModal from '../../Elements/modal/CustomInfoModal'
import whatsapp_icon from '../../../img/svg/whatsapp_icon_green.svg'
import { QRCodeSVG } from 'qrcode.react'
import Button from '../../Elements/buttons/Button'
import { selectCompanyData, selectCompanyIndex } from '../../../redux/selector/system'
import { ExtraContactsContent, ExtraContactsInfo, ExtraContactsLink, ExtraContactsSub, ExtraContactsTitle, ExtraContactsWatsappContacts, ExtraContactsWrap } from './index.styled'
import { setSupportShowExtraContactsModal } from '../../../redux/action/support'

const EVENT_Z_INDEX = 994

const ExtraContactsOfSupportModal = React.memo(() => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const showModal = useSelector(store => store.support.showExtraContactsModal)
	const companyIndex = useSelector(selectCompanyIndex)
	const name = useSelector(selectCompanyData('name'))
	const firstName = useSelector(selectCompanyData('firstName'))
	const whatsappHref = firstName ? `https://wa.me/4951154681414?text=Hallo!%20Ich%20bin%20${firstName},%20Firma%20${name},%20Kundennummer%20${companyIndex}` :
		`https://wa.me/4951154681414?text=Hallo!%20Ich%20bin%20Ihre%20Kunden,%20Firma%20${name},%20Kundennummer%20${companyIndex}`

	const handleClose = useCallback(() => {
		dispatch(setSupportShowExtraContactsModal(false))
	}, [dispatch])

	return (
		<CustomInfoModal isOpen={showModal}
										 title={t('app.modals.Attention')}
										 close={handleClose}
										 zIndex={EVENT_Z_INDEX}
										 isIcon={false}
										 modalColor="orange"
										 toFront={true}
										 button={<Button icon="checkmark" onClick={handleClose} tabIndex="1" text="Ok" color="green" keyButton="F2" />}>
			<ExtraContactsWrap className="contacts">
				<ExtraContactsTitle>{t('app.modals.contact_us')}</ExtraContactsTitle>
				<ExtraContactsContent className="contacts">
					<QRCodeSVG value={whatsappHref}
										 level="M"
										 imageSettings={{
											 src: whatsapp_icon,
											 width: 32,
											 height: 32,
											 x: null,
											 y: null,
											 excavate: true,
										 }} />
					<ExtraContactsInfo>
						<MainIcon icon="qr-phone" />
						<ExtraContactsWatsappContacts>
							<ExtraContactsSub>{t('app.modals.warningWatsappText')}</ExtraContactsSub>
							<ExtraContactsSub>{t('app.modals.warningContactsText')}:</ExtraContactsSub>
							<ExtraContactsLink href="tel:+49 511 546-814-14">+49 (0) 511 546-814-14</ExtraContactsLink>
						</ExtraContactsWatsappContacts>
					</ExtraContactsInfo>
				</ExtraContactsContent>
			</ExtraContactsWrap>
		</CustomInfoModal>
	)
})

ExtraContactsOfSupportModal.displayName = 'ExtraContactsOfSupportModal'
export default ExtraContactsOfSupportModal
