import { SET_AREAS, SET_AREA, SET_AREAS_LENGTH, SET_SHOW_AREA_FORM, APPEND_AREAS, SET_SHOW_CREATE_TABLES_FORM, SET_SHOW_QR_CODE_DOWNLOAD_MODAL } from '../../actionTypes/area'

const initialState = {
  areas: [],
  area: null,
  areasTotalLength: 0,
  modal: {
    showAreaForm: false,
    showCreateTablesForm: { open: false, item: {} },
    showQrCodeDownloadModal: { open: false, item: {} }
  }
}

export function areaReducer (state = initialState, action) {
  switch (action.type) {
    case SET_AREAS:
      return { ...state, areas: action.value }
    case SET_AREAS_LENGTH:
      return { ...state, areasTotalLength: action.value }
    case SET_AREA:
      return { ...state, area: action.value }
    case SET_SHOW_AREA_FORM:
      return {
        ...state,
        modal: {
          ...state.modal,
          showAreaForm: action.value,
        },
      }
		case SET_SHOW_CREATE_TABLES_FORM:
				return {
					...state,
					modal: {
						...state.modal,
						showCreateTablesForm: action.value,
				},
			}
    case SET_SHOW_QR_CODE_DOWNLOAD_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          showQrCodeDownloadModal: action.value,
      },
    }
    case APPEND_AREAS:
      return { ...state, areas: state.areas ? state.areas.concat(...action.value) : action.value }
    default:
      return state
  }
}
