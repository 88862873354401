import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Areas from '../../index'
import { setRestaurantSwapModal,setRestaurantOrdersMove } from '../../../../../redux/action/restaurant'
import colors from '../../../../../style/colors'
import { fetchRestaurantTablesThunk } from '../../../../../redux/thunk/restaurant/table'
import Loader from '../../../../Elements/loaders'
import CustomModal from '../../../../Elements/modal/CustomModal'
import { SwapModalAreas, SwapTables, SwapTablesScroll, SwapTablesWrapper } from './index.styled'
import SwapTable from './SwapTable'
import SwapTableNew from './SwapTableNew'
import { theme } from '../../../../../style/theme'
import { useWindowBreakPoint } from '../../../../../hooks/useWindowBreakPoint'
import { RestWrapper, RestWall } from '../index.styled'
import { fillTables } from '../../../../../helper/restaurant'

const ModalTableSwap = () => {
	const { tables, tablesLoading, areas, areaId, table: selectedTable, showSwapModal } = useSelector(state => state.restaurant)
	const dispatch = useDispatch()
	const isMobile = useWindowBreakPoint(theme.point720)

	const areaColor = areas?.find(area => area.id === areaId)?.meta?.color

	useEffect(() => {
		if (areaId && showSwapModal && selectedTable) {
			dispatch(fetchRestaurantTablesThunk(areaId))
		}
	}, [areaId, showSwapModal]) //eslint-disable-line react-hooks/exhaustive-deps

	const closeModal = () => {
		dispatch(setRestaurantOrdersMove([]))
		dispatch(setRestaurantSwapModal(false))
	}

	let restTable = []
	if (!isMobile && tables && tables.length) {
		restTable = fillTables(tables)
	}

	const area = areas?.filter(a => a.id === areaId)
	let walls = []
	let floor = {}
	if (!isMobile && area && area.length) {
		walls = area[0]?.walls || []
		floor = area[0]?.floor || {}
	}

	const SwapTablesComp = (
		<SwapTablesWrapper color={areaColor}
											 colorFloor={floor?.meta?.color}
											 bgdImg={floor?.meta?.color !=='' ? '' : `/img/floors/${floor?.meta?.backgroundImage}.webp`}
											 id="restaurant-table-swap-list">
			<SwapTablesScroll>
				<SwapTables>
					{!tablesLoading ?
						isMobile ?
						(tables?.map((table, index) =>
							<SwapTable key={index} index={index} table={table} />))
							:
								(restTable?.map((table, index) =>
									<SwapTableNew key={index} index={index} table={table} />
								))
								:
								<div className="loader"><Loader color={colors.white} /></div>}
					{!isMobile ? (walls.map((wall, i) =>
						<RestWrapper key={i} {...wall.meta} >
							<RestWall id={`wall_${i}`} data-testid={`wall_${i}`} {...wall.meta}/>
						</RestWrapper>
							)) : null
						}
				</SwapTables>
			</SwapTablesScroll>
		</SwapTablesWrapper>
	)

	return (
		<CustomModal isOpen={!!(showSwapModal && selectedTable)}
								 close={closeModal}
								 size={'larger'}
								 title={`Tisch #${selectedTable?.number} Wechseln`}>
			<SwapModalAreas id="swap-area-list">
				<Areas swapMode={true}>
					{isMobile && SwapTablesComp}
				</Areas>
			</SwapModalAreas>
			{!isMobile && SwapTablesComp}
		</CustomModal>
	)
}

ModalTableSwap.displayName = 'ModalTableSwap'
export default ModalTableSwap
