import styled from 'styled-components'
import { theme } from '../../../../../style/theme'

export const QrCodeTextWrap = styled.div`
	font-size: inherit;
	font-family: ${theme.fontRobotoMedium};
	display: flex;
	flex-direction: column;
	gap: 0.23em;
`
QrCodeTextWrap.displayName = 'QrCodeTextWrap'

export const QrCodeIconsWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 0.23em 0.45em;

	> .APPLE_PAY, .GOOGLE_PAY {
		width: 1.7em;
		height: 0.73em;
	}

	> .AMERICAN_EXPRESS {
		width: 2em;
		height: 0.73em;
	}

	> .KLARNA {
		width: 2.2em;
		height: 0.5em;
	}

	> .SOFORT {
		width: 2.2em;
		height: 0.73em;
	}

	> .GIROPAY {
		width: 2.7em;
		height: 0.73em;
	}

	> .PAYPAL {
		width: 2.4em;
		height: 0.64em;
	}
`
QrCodeIconsWrap.displayName = 'QrCodeIconsWrap'
