import { APPEND_CART_PAYMENT_TERMINALS, SET_CART_PAYMENT_INVOICE_CONFIGS, SET_CART_PAYMENT_PAYMENTS, SET_CART_PAYMENT_QR_CODE_PAYMENT_SESSION, SET_CART_PAYMENT_QR_CODE_PAYMENT_SESSION_IS_CREATING, SET_CART_PAYMENT_QR_CODE_PAYMENT_SESSION_STARTED, SET_CART_PAYMENT_SELECTED_TERMINAL, SET_CART_PAYMENT_SESSION_CREATE_IS_LOADING, SET_CART_PAYMENT_SESSION_PROCESS_IS_LOADING, SET_CART_PAYMENT_TERMINAL_PAYMENT_SESSION, SET_CART_PAYMENT_TERMINAL_PAYMENT_SESSION_INVALID, SET_CART_PAYMENT_TERMINAL_PROCESS_ERROR, SET_CART_PAYMENT_TERMINALS, SET_CART_PAYMENT_TERMINALS_LENGTH, SET_PROCESS_PAYMENT_IS_LOADING } from '../../actionTypes/cartPayment'

export const setCartPaymentPayments = value => ({ type: SET_CART_PAYMENT_PAYMENTS, value })

export const setCartPaymentTerminals = value => ({ type: SET_CART_PAYMENT_TERMINALS, value })

export const appendCartPaymentTerminals = value => ({ type: APPEND_CART_PAYMENT_TERMINALS, value })

export const setCartPaymentTerminalsLength = value => ({ type: SET_CART_PAYMENT_TERMINALS_LENGTH, value })

export const setCartPaymentSelectedTerminal = value => ({ type: SET_CART_PAYMENT_SELECTED_TERMINAL, value })

export const setProcessPaymentIsLoading = value => ({ type: SET_PROCESS_PAYMENT_IS_LOADING, value })

export const setCartPaymentTerminalPaymentSession = value => ({ type: SET_CART_PAYMENT_TERMINAL_PAYMENT_SESSION, value })

export const setCartPaymentTerminalPaymentSessionInvalid = value => ({ type: SET_CART_PAYMENT_TERMINAL_PAYMENT_SESSION_INVALID, value })

export const setCartPaymentTerminalError = value => ({ type: SET_CART_PAYMENT_TERMINAL_PROCESS_ERROR, value })

export const setCartPaymentInvoiceConfigs = value => ({ type: SET_CART_PAYMENT_INVOICE_CONFIGS, value })

export const setCartPaymentSessionCreateIsLoading = value => ({ type: SET_CART_PAYMENT_SESSION_CREATE_IS_LOADING, value })

export const setCartPaymentSessionProcessIsLoading = value => ({ type: SET_CART_PAYMENT_SESSION_PROCESS_IS_LOADING, value })

export const setCartPaymentQrCodePaymentSession = value => ({ type: SET_CART_PAYMENT_QR_CODE_PAYMENT_SESSION, value })

export const setCartPaymentQrCodePaymentSessionIsCreating = value => ({ type: SET_CART_PAYMENT_QR_CODE_PAYMENT_SESSION_IS_CREATING, value })

export const setCartPaymentQrCodePaymentSessionStarted = value => ({ type: SET_CART_PAYMENT_QR_CODE_PAYMENT_SESSION_STARTED, value })
