import getAutoPrintConfigs from '../../axios/printer/printerSettings'
import { setAppErrors } from '../../action/system'
import { retrieveErrorFromApi } from '../../../helper'
import { setPrintReceiptOrderId, setPrintReceiptPrintSettings } from '../../action/printReceipt'
import updateAutoPrintConfigs from '../../axios/printer/printerSettings/put'

export const getPrintSettingsThunk = (module, businessScope) => dispatch => {
	return getAutoPrintConfigs(module, businessScope)
		.then(res => {
			const showModal = res.data.length ? res.data[0]?.disabled : false
			if (!showModal) {
				dispatch(setPrintReceiptOrderId(null))
			}
			dispatch(setPrintReceiptPrintSettings(res.data))
		}).catch((error) => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updatePrintSettingsThunk = (id, data) => dispatch => {
	return updateAutoPrintConfigs(id, data)
		.then((res) => {
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
