import React, { useMemo, useState } from 'react'
import CustomTable from '../../../Elements/table/old'
import TableTr from '../../../Elements/table/old/tr'
import { TableMobileTitle, TableTd, TableTdGrid, TableTdStatus } from '../../../Elements/table/old/index.styled'
import { OrderRestaurantMeta } from '../index.styled'
import MainIcon from '../../../../img/svg/main'
import { statusOptionsStatic } from '../../../../redux/reducer/order/constants'
import OrderPayment from '../../../Elements/table/TableTdElements/OrderPayment'
import { getNeededDateFormat } from '../../../../helper'
import { fetchRestaurantOrdersThunk } from '../../../../redux/thunk/restaurant/orders'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ModalOrder from '../modalOrder'
import OrderDayCounter from '../../../Elements/table/TableTdElements/OrderDayCounter'

const OrdersTable = React.memo(() => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const orders = useSelector(state => state.restaurant.orders)
	const ordersTotal = useSelector(state => state.restaurant.ordersTotal)
	const [activeRow, setActiveRow] = useState(0)
	const [selectedOrder, setSelectedOrder] = useState(null)

	const tableHeadersStatic = useMemo(() => [
		{ title: t('order.table.nr') },
		{ title: t('order.table.nr_table') },
		{ title: t('order.table.service') },
		{ title: t('order.table.status') },
		{ title: t('order.table.sum') },
		{ title: t('order.table.time') },
	], [t])

	const onLoad = (page, size) => {
		dispatch(fetchRestaurantOrdersThunk(page, size))
	}

	const selectOrder = (item, i) => {
		if (item?.status?.status !== 'CANCELED') {
			setActiveRow(i)
			setSelectedOrder(item)
		}
	}
	return (
		<>
			<CustomTable headers={tableHeadersStatic}
									 onLoad={onLoad}
									 currentLength={orders?.length}
									 totalLength={ordersTotal}
									 perLoad={20}>
				{orders?.map((item, i) =>
					<TableTr key={i}
									 isActive={i === activeRow}
									 onClick={() => selectOrder(item, i)}>
						<TableTd className="number">
							<OrderDayCounter item={item} />
						</TableTd>
						<TableTd>
							<OrderRestaurantMeta color={item.restaurantMetaData?.area.color}>
								<div className="color" />
								<div className="meta">
									<div className="area">{item.restaurantMetaData?.area.name}; Tisch {item.restaurantMetaData?.table.number}</div>
								</div>
							</OrderRestaurantMeta>
						</TableTd>
						<TableTd style={{ wordBreak: 'break-all' }}>
							<TableTdGrid>
								<TableMobileTitle>{tableHeadersStatic[2].title}</TableMobileTitle>
								<MainIcon icon="user" />
								{item.processedBy}
							</TableTdGrid>
						</TableTd>
						<TableTdStatus className={`const-color ${item?.status?.status}`}
													 column="5 / 7">
							<TableTdGrid>
								<TableMobileTitle>{tableHeadersStatic[3].title}</TableMobileTitle>
								<MainIcon icon={item?.status?.status} />
								{t(statusOptionsStatic.filter(status => status.value === item.status?.status)[0]?.label)}
							</TableTdGrid>
						</TableTdStatus>
						<TableTd column="1 / 3" row="3">
							<TableMobileTitle>{tableHeadersStatic[4].title}</TableMobileTitle>
							<OrderPayment item={item} />
						</TableTd>
						<TableTd column="3 / 5" row="3">
							<TableTdGrid>
								<TableMobileTitle>{tableHeadersStatic[5].title}</TableMobileTitle>
								<MainIcon icon="time" />
								{getNeededDateFormat(item.orderDate, 'HH:mm')}
							</TableTdGrid>
						</TableTd>
					</TableTr>)}
			</CustomTable>
			<ModalOrder {...{ selectedOrder, setSelectedOrder }} />
		</>
	)
})

OrdersTable.displayName = 'OrdersTable'
export default OrdersTable
