/* eslint-disable */
import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { TableCouponMobileTitle } from '../../../Coupon/index.styled'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const OrderTableWrapper = styled.div`
	@media screen and (max-width: ${theme.point820}) {
		margin-top: 1.43em;

		tr {
			grid-template-columns: 5.5em 5.5em 11.29em 1fr 1fr 1fr;
			grid-template-rows: auto auto;
			grid-auto-flow: dense;
		}
	}
`
OrderTableWrapper.displayName = 'OrderTableWrapper'

export const TextBig = styled.div`
	font-size: 1em;

	&.unpaid {
		color: ${colorTheme.table.tableTr.dangerText};
	}

	&.light {
		color: ${colorTheme.table.tableTr.textLight};
	}

	&.mg-bt {
		margin-bottom: -0.1em;
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.15em;
	}
`
TextBig.displayName = 'TextBig'

export const TableOrderMobileTitle = styled(TableCouponMobileTitle)`
	margin-bottom: 0.36em;
	font-weight: 600;
	font-family: ${theme.fontRoboto};
`
TableOrderMobileTitle.displayName = 'TableOrderMobileTitle'

export const PartnerDriverTooltipText = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.4em;

	span {
		font-family: ${theme.fontRobotoMedium};
		font-weight: 500;
		text-decoration: underline;
		cursor: pointer;
		color: ${colorTheme.tooltip.activeText};
	}
`
PartnerDriverTooltipText.displayName = 'PartnerDriverTooltipText'

export const OrderDataWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5em;
	padding: 0.5em 0;
`
OrderDataWrapper.displayName = 'OrderDataWrapper'
