import { getNeededDateFormat } from '../../../helper'

const MS_IN_A_DAY = 1000 * 60 * 60 * 24
export const getDateDifferenceInDays = (deactivationDate, dateTo = new Date()) => {
	if (!deactivationDate) return null

	const deactivationTime = new Date(deactivationDate).getTime()
	const dateToTime = new Date(dateTo).getTime()

	return Math.round((deactivationTime - dateToTime) / MS_IN_A_DAY)
}

export const getActivationData = (companyData, t) => {
	const deactivationDate = companyData?.deactivationDate
	const subscription = companyData?.subscription
	const isTestMode = !subscription?.status
	const dateDifferenceInDays = getDateDifferenceInDays(deactivationDate)
	const isDeactivated = !!deactivationDate && (dateDifferenceInDays <= 0)
	const willBeDeactivated = !!deactivationDate && (dateDifferenceInDays > 0)
	const date = getNeededDateFormat(deactivationDate)
	if (isTestMode && !isDeactivated) {
		return { text: t('partnerPortal.activation.test', { days: dateDifferenceInDays }), className: 'warn', prolongation: { class: 'warn' } }
	} else if (isTestMode && isDeactivated) {
		return { text: t('partnerPortal.activation.test_deactivated', { date }), className: 'info', prolongation: { class: 'info' } }
	} else if (subscription.status === 'UNPAID' && willBeDeactivated) {
		return { text: t('partnerPortal.activation.will_be_deactivated_due_unpaid', { date }), className: 'error', prolongation: null }
	} else if (willBeDeactivated) {
		return { text: t('partnerPortal.activation.will_be_deactivated', { date }), className: 'warn', prolongation: null }
	} else if (subscription.status === 'UNPAID' && isDeactivated) {
		return { text: t('partnerPortal.activation.is_deactivated_due_unpaid', { date }), className: 'error', prolongation: { class: 'error' } }
	} else if (isDeactivated) {
		return { text: t('partnerPortal.activation.is_deactivated', { date }), className: 'info-light', prolongation: { class: 'info' } }
	} else if (subscription.status === 'LIVE') {
		return null
	} else {
		// eslint-disable-next-line no-console
		console.error('Invalid subscription status: ', companyData.index, subscription.status, deactivationDate)
		return { text: '!!! ERROR !!!!', className: 'error' }
	}
}

export const getConditionValue = (conditions, type) => {
	return conditions?.find(condition => condition?.financialDataType === type)?.value ?? 0
}

export const getConditionsValue = (conditions) => {
	const percentsConditionValue = conditions?.find(condition => condition?.financialDataType === 'PERCENTS')?.value ?? 0
	const currencyConditionValue = conditions?.find(condition => condition?.financialDataType === 'CURRENCY')?.value ?? 0
	return conditions ? { percentsConditionValue, currencyConditionValue } : null
}
