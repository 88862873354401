/* eslint no-console: off */
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { isDevEnvironment, roundPrice } from '../../../../helper'
import MainIcon from '../../../../img/svg/main'
import { setCartPaymentTerminalPaymentSession, setCartPaymentTerminalPaymentSessionInvalid, setProcessPaymentIsLoading } from '../../../../redux/action/cartPayment'
import { selectCompanyIndex } from '../../../../redux/selector/system'
import { createTerminalPaymentSessionThunk, deleteTerminalPaymentSessionThunk, getTerminalPaymentSessionThunk, processTerminalPaymentSessionThunk, resetTerminalPaymentErrorsThunk, simulateTerminalPaymentThunk } from '../../../../redux/thunk/cartPayment'
import Button from '../../buttons/Button'
import TextInputControlled from '../../inputs/TextInput/controlled'
import CustomModal from '../../modal/CustomModal'
import { ButtonsWrapper, PaymentsTerminalInfo, PaymentsTerminalStatus, PaymentsTerminalText, PaymentsTerminalWrap, TestBlockWrap, TestCardBlock, TipsBlock } from './index.styled'
import Loader from '../../loaders'
import colors from '../../../../style/colors'
import TerminalLoader from './TerminalLoader'
import { getPaymentStatusMessage, getPercentsTips } from './helper'
import { setPrintReceiptOrderId } from '../../../../redux/action/printReceipt'

const EVENT_Z_INDEX = 47

const testTips = [5, 10, 15]

const ModalTerminalPaymentProcess = ({
	isOpen, totalPrice, close, navigateBack, handlePayment, resetCart,
	isMoneyExchange = false, printReceipt = false,
	ecTerminalTips, paymentSource = 'CASH_REGISTER',
}) => {
	const { t } = useTranslation()
	const selectedTerminal = useSelector(state => state.cartPayment.selectedTerminal)
	const paymentSession = useSelector(state => state.cartPayment.terminalPaymentSession)
	const processPaymentIsLoading = useSelector(state => state.cartPayment.processPaymentIsLoading)
	const paymentSessionCreateIsLoading = useSelector(state => state.cartPayment.paymentSessionCreateIsLoading)
	const paymentSessionProcessIsLoading = useSelector(state => state.cartPayment.paymentSessionProcessIsLoading)
	const companyIndex = useSelector(selectCompanyIndex)
	const roundTotalPrice = roundPrice(totalPrice)
	const dispatch = useDispatch()
	const [testCardNumber, setTestCardNumber] = useState('')
	const [testTipValue, setTestTipValue] = useState(0)
	const [activeTipIndex, setActiveTipIndex] = useState(null)
	const [simulatePaymentIsLoading, setSimulatePaymentIsLoading] = useState(false)
	const [handleBackIsLoading, setHandleBackIsLoading] = useState(false)
	const [handleRetryIsLoading, setHandleRetryIsLoading] = useState(false)
	const [processingPayment, setProcessingPayment] = useState(false)
	const [disableScreenTips, setDisableScreenTips] = useState(false)
	const waiterAccessToken = useSelector(state => state.modalLogin.accessToken)
	const hasModalLogin = !!waiterAccessToken
	const paymentSessionInvalid = useSelector(state => state.cartPayment.terminalPaymentSessionInvalid)
	const terminalProcessError = useSelector(state => state.cartPayment.terminalProcessError)
	const paymentSessionId = paymentSession?.sessionId
	const paymentSessionTips = paymentSession?.tips
	const paymentCanBeCollected = !!paymentSession
	const paymentIsCollected = paymentSession?.status === 'SUCCEEDED'
	const paymentIsCanceled = paymentSession?.status === 'CANCELED'
	const paymentOrderId = paymentSession?.orderId
	const paymentError = (!processPaymentIsLoading && paymentSession?.status === 'CARD_VALIDATION_FAILED') || paymentSessionInvalid || terminalProcessError || paymentIsCanceled
	// const paymentErrorCode = paymentSession?.failureCode todo
	const layout = paymentError ? 'error' : ''
	const simulateButtonIsOpen = paymentCanBeCollected && processPaymentIsLoading && !paymentIsCanceled
	const disableBackAndRetryButtons = handleBackIsLoading || handleRetryIsLoading || processingPayment || paymentSessionCreateIsLoading || paymentSessionProcessIsLoading
	const paymentStatusMessage = getPaymentStatusMessage(selectedTerminal, processingPayment, handleBackIsLoading, paymentError, terminalProcessError, paymentIsCanceled, t)

	useEffect(() => {
		if (isOpen && (isMoneyExchange || ecTerminalTips)) {
			setDisableScreenTips(true)
		}
	}, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isOpen && selectedTerminal?.id) {
			dispatch(createTerminalPaymentSessionThunk(selectedTerminal.id, totalPrice, paymentSource, hasModalLogin))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	useEffect(() => {
		const interval = setInterval(() => {
			if (isOpen && paymentSessionId) {
				dispatch(getTerminalPaymentSessionThunk(selectedTerminal.id, paymentSessionId, hasModalLogin))
			}
		}, 3000)

		return () => clearInterval(interval)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen, paymentSessionId])

	useEffect(() => {
		if (paymentCanBeCollected && isOpen && !paymentOrderId) {
			setProcessingPayment(true)
			handlePlaceOrder(paymentSessionId, paymentSessionTips)
		}
		// eslint-disable-next-line
	}, [paymentCanBeCollected, isOpen, paymentSessionId])

	useEffect(() => {
		if (paymentCanBeCollected && paymentOrderId && isOpen) {
			dispatch(processTerminalPaymentSessionThunk(selectedTerminal.id, paymentSessionId, hasModalLogin, { totalSum: totalPrice, showScreenTips: !disableScreenTips }))
		}
		// eslint-disable-next-line
	}, [paymentCanBeCollected, isOpen, paymentOrderId, disableScreenTips])

	const handleReset = useCallback(async () => {
		dispatch(resetTerminalPaymentErrorsThunk())
		dispatch(setCartPaymentTerminalPaymentSession(null))
		setTestCardNumber('')
		dispatch(setProcessPaymentIsLoading(false))
		setProcessingPayment(false)
		setHandleBackIsLoading(false)
		setTestTipValue(0)
		setActiveTipIndex(null)
		setDisableScreenTips(false)
	}, [dispatch])

	const handleFinishTerminalPaymentSession = useCallback(async () => {
		await handleReset()
		close()
		if (printReceipt) dispatch(setPrintReceiptOrderId(paymentOrderId))
		resetCart()
	}, [close, dispatch, handleReset, printReceipt, resetCart, paymentOrderId])

	useEffect(() => {
		if (paymentIsCollected && isOpen) {
			handleFinishTerminalPaymentSession()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paymentIsCollected, isOpen])

	const simulatePayment = async () => {
		console.log(`Try to simulate payment: companyIndex=${companyIndex} with  paymentSessionId=${paymentSessionId}`)
		setSimulatePaymentIsLoading(true)
		await dispatch(simulateTerminalPaymentThunk(selectedTerminal.id, { testCardNumber: testCardNumber, tips: testTipValue }, hasModalLogin))
		setSimulatePaymentIsLoading(false)
	}

	const onChangeTestCardNumber = useCallback((e) => {
		setTestCardNumber(e.target.value)
	}, [])

	const handlePlaceOrder = useCallback(async (paymentId, tips) => {
		try {
			await handlePayment(paymentId, tips)
			setProcessingPayment(false)
		} catch (e) {
			setProcessingPayment(false)
			return dispatch(setCartPaymentTerminalPaymentSessionInvalid(true))
		}

	}, [dispatch, handlePayment])

	const handleBack = useCallback(async () => {
		if (processingPayment) return

		if (paymentSessionId && !paymentIsCanceled) {
			setHandleBackIsLoading(true)
			console.log(`HandleBack: terminal=${selectedTerminal?.id} session=${paymentSessionId} companyIndex=${companyIndex}`)
			const deleteTerminalPaymentSessionStatus = await dispatch(deleteTerminalPaymentSessionThunk(selectedTerminal.id, paymentSessionId, hasModalLogin))
			setHandleBackIsLoading(false)
			if (deleteTerminalPaymentSessionStatus === 'terminal_payment_cancel_not_available' || deleteTerminalPaymentSessionStatus === 'unexpected_error') {
				return
			}
		}
		await handleReset()
		navigateBack()
	}, [handleReset, dispatch, navigateBack, paymentSessionId, selectedTerminal?.id, hasModalLogin, companyIndex, processingPayment, paymentIsCanceled])

	const handleRetryPayment = useCallback(async () => {
		setHandleRetryIsLoading(true)
		dispatch(resetTerminalPaymentErrorsThunk())
		console.log(`HandleRetryPayment: terminal=${selectedTerminal?.id} session=${paymentSessionId} companyIndex=${companyIndex}`)
		if (paymentIsCanceled) {
			dispatch(setCartPaymentTerminalPaymentSession(null))
			dispatch(setProcessPaymentIsLoading(false))
			await dispatch(createTerminalPaymentSessionThunk(selectedTerminal.id, totalPrice, paymentSource, hasModalLogin))
		} else {
			await dispatch(processTerminalPaymentSessionThunk(selectedTerminal.id, paymentSessionId, hasModalLogin, { totalSum: totalPrice, showScreenTips: !disableScreenTips }))
		}
		setHandleRetryIsLoading(false)
	}, [paymentSessionId, hasModalLogin, dispatch, selectedTerminal?.id, totalPrice, companyIndex, disableScreenTips, paymentSource, paymentIsCanceled])

	const handleTips = useCallback((value, i) => {
		const tips = getPercentsTips(totalPrice, value)
		setTestTipValue(tips)
		setActiveTipIndex(i)
	}, [totalPrice])

	return (
		<CustomModal isOpen={isOpen}
								 id="modal-payments-terminal"
								 close={handleBack}
								 size="auto"
								 title={t('changeCalculator.title')}
								 titlePrice={roundTotalPrice}
								 titleUpperCase={true}
								 titleColor="blue"
								 showBackButton={false}
								 disableBackButton={true}
								 showCloseButton={!disableBackAndRetryButtons}
								 enableOutsideClick={!disableBackAndRetryButtons}
								 zIndex={EVENT_Z_INDEX}>
			<PaymentsTerminalWrap className={layout}>
				{paymentError ?
					<MainIcon icon="error" testId="terminal-error" /> :
					<TerminalLoader />}
				<PaymentsTerminalInfo>
					<PaymentsTerminalText>
						{t('paymentsTerminal.terminal')}: {selectedTerminal?.name} / {t('paymentsTerminal.EC-Terminal')} ({selectedTerminal?.registrationCode})
					</PaymentsTerminalText>
					<PaymentsTerminalStatus className={layout}>
						{paymentStatusMessage}
						{/*todo: {paymentErrorCode}*/}
					</PaymentsTerminalStatus>
				</PaymentsTerminalInfo>
				{isDevEnvironment() &&
					<TestBlockWrap>
						{!disableScreenTips &&
							<TipsBlock>
								{testTips.map((tip, i) => (
									<Button name="tips" key={tip}
													color={i === activeTipIndex ? 'green' : 'blue'}
													onClick={() => handleTips(tip, i)}>
										Tips {tip}%
									</Button>))}
							</TipsBlock>}
						<TestCardBlock>
							<Button name="payment-fail" color="red" onClick={() => setTestCardNumber('4000000000000002')}>0002</Button>
							<Button name="payment-succeed" color="green" onClick={() => setTestCardNumber('4242424242424242')}>4242</Button>
							<TextInputControlled name="test-card-number" color="gray" size="small" value={testCardNumber} onChange={onChangeTestCardNumber} />
							{simulateButtonIsOpen &&
								<Button name="process-payment" color="blue" onClick={simulatePayment} disabled={simulatePaymentIsLoading}>
									{simulatePaymentIsLoading ? <Loader color={colors.white} /> : 'Simulate payment'}
								</Button>}
						</TestCardBlock>
					</TestBlockWrap>}
			</PaymentsTerminalWrap>

			<ButtonsWrapper>
				{!processingPayment &&
					<Button name="send" icon="clear"
									keyButton="F3" zIndex={EVENT_Z_INDEX}
									color="gray"
									onClick={handleBack}
									disabled={disableBackAndRetryButtons}>
						{handleBackIsLoading ? <Loader color={colors.white} /> : t('Cart.Buttons.otherPaymentMethod')}
					</Button>}
				{paymentError &&
					<Button name="retry" icon="retry"
									keyButton="F2" zIndex={EVENT_Z_INDEX}
									color="green"
									onClick={handleRetryPayment}
									disabled={disableBackAndRetryButtons}>
						{handleRetryIsLoading ? <Loader color={colors.white} /> : t('Cart.Buttons.pay_again')}
					</Button>}
			</ButtonsWrapper>
		</CustomModal>
	)
}

ModalTerminalPaymentProcess.displayName = 'ModalPaymentsTerminal'
export default ModalTerminalPaymentProcess


