import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import colors from '../../../../style/colors'

export const DeactivationModalWrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.5em;

	&.contacts {
		gap: 1.875em;
	}

	@media screen and (max-width: ${theme.point720}) {
    > label {
			width: 100%;
		}
  }
`
DeactivationModalWrap.displayName = 'DeactivationModalWrap'

export const DeactivationModalText = styled.div`
	font-family: ${theme.fontRobotoMedium};
	color: #383A50;
	text-align: center;
	font-size: 1.25em;
	font-style: normal;
	font-weight: 500;
`
DeactivationModalText.displayName = 'DeactivationModalText'

export const DeactivationModalContent = styled.div`
	display: flex;
	gap: 1.25em;

	> svg {
		width: 10.5em;
		height: 10.5em;
		flex-shrink: 0;
	}

	@media screen and (max-width: ${theme.point720}) {
    flex-direction: column;
		align-items: center;
  }
`
DeactivationModalContent.displayName = 'DeactivationModalContent'

export const DeactivationModalInfo = styled.div`
	display: flex;
	gap: 0.75em;
	width: 20.3em;

	> svg {
		width: 2.12em;
		height: 3.5em;
		flex-shrink: 0;
	}
`
DeactivationModalInfo.displayName = 'DeactivationModalInfo'

export const DeactivationModalContacts = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.43em;
`
DeactivationModalContacts.displayName = 'DeactivationModalContacts'

export const DeactivationModalWatsappContacts = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.63em;
`
DeactivationModalWatsappContacts.displayName = 'DeactivationModalWatsappContacts'

export const DeactivationModalSub = styled.div`
	font-size: 1em;
	font-weight: 400;
	font-family: ${theme.fontRobotoMedium};
	text-align: left;
`
DeactivationModalSub.displayName = 'DeactivationModalSub'

export const DeactivationModalLink = styled.a`
	font-size: 1.25em;
	font-weight: 500;
	text-decoration-line: underline;
	cursor: pointer;
	color: ${colors.blue};
	font-family: ${theme.fontRobotoMedium};
	text-align: left;

	@media screen and (max-width: ${theme.point720}) {
		&.center {
			text-align: center;
			margin-bottom: 0.5em;
		}
	}
`
DeactivationModalLink.displayName = 'DeactivationModalLink'

export const DeactivationModalTitle = styled.div`
	color: #383A50;
	font-size: 1.5em;
	font-weight: 600;
	text-align: left;
`
DeactivationModalTitle.displayName = 'DeactivationModalTitle'

export const ContactsTitle = styled.div`
	color: #060606;
	font-size: 1.5em;
	font-weight: 600;
`
ContactsTitle.displayName = 'ContactsTitle'


