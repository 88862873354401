import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const ModalWrapper = styled.div`
	font-size: 1rem;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.33);
	backdrop-filter: blur(5px);
	z-index: 9;

	&.to-front {
		z-index: 1000;
	}

	&.modal-enter-done {
		opacity: 1;
		z-index: 999;
		background-color: #000000bf;

		&.to-front {
			z-index: 1000;
		}
	}

	&.modal-enter {
		opacity: 0;
		background-color: rgba(0, 0, 0, 0.33);
	}
	&.modal-enter-active {
		opacity: 1;
		background-color: #000000bf;
		transition:  all 100ms;
	}
	&.modal-exit {
		opacity: 1;
		background-color: #000000bf;
	}
	&.modal-exit-active {
		opacity: 0;
		background-color: rgba(0, 0, 0, 0.33);
		transition:  all 100ms;
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1rem;
		opacity: 1;
		background-color: transparent;
		backdrop-filter: none;
		transition:  all 250ms;

		&.modal-enter-done {
			transform: translateY(0);
			background-color: transparent;
			backdrop-filter: none;
		}
	}
`
ModalWrapper.displayName = 'ModalWrapper'

export const ModalMain = styled.main`
	height: auto;
	border-radius: 3.75em;
	width: 45em;
	border: 2px solid ${colorTheme.modal.info.border};
	background: ${colorTheme.modal.info.background};

	/*fixed xy axis*/
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	padding: 5.62em 3.12em 1.875em;
	margin-top: 0;

	&.orange {
		border-color: ${colorTheme.modal.info.warning.border};
	}

	&.warn {
		border-color: ${colorTheme.modal.info.warning.border};
	}

	&.info {
		border-color: ${colorTheme.modal.info.info.border};
	}

	&.error {
		border-color: ${colorTheme.modal.info.error.border};
	}

	@media screen and (max-width: ${theme.point820}) {
		width: 100%;
		border-radius: 0;
		border: none;
		z-index: 1;
		padding: 3.75em 0 0.93em;
		height: 100%;
		left: 0;
		top: auto;
		bottom: 0;
		transform: translate(0, 0);
		box-shadow: 0 4px 150px rgba(0, 0, 0, 0.41);
		margin: 0;
	}
`
ModalMain.displayName = 'ModalMain'

export const ModalBody = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.5em;

	> svg {
		width: 6.87em;
		height: 6.87em;
		fill: ${colorTheme.modal.info.icon};
	}

	&.orange {
		> svg {
			fill: ${colorTheme.modal.info.warning.icon};
		}
	}

	&.warn {
		> svg {
			fill: ${colorTheme.modal.info.warning.icon};
		}
	}

	&.info {
		> svg {
			fill: ${colorTheme.modal.info.info.icon};
		}
	}

	&.error {
		> svg {
			fill: ${colorTheme.modal.info.error.icon};
		}
	}
`

export const ModalCloseBlock = styled.div`
	position: absolute;
	right: 3.1em;
	top: 2.8em;
	display: flex;
	align-items: center;

	svg {
		cursor: pointer;
		width: 2.1em;
		height: 2.1em;
		fill: ${colorTheme.modal.info.closeKey.icon};
	}

	@media screen and (max-width: ${theme.point820}) {
		display: none;

		&.mobile {
			display: flex;
			position: static;

			svg {
				width: 1.19em;
				height: 1.19em;
			}
		}
	}
`
ModalCloseBlock.displayName = 'ModalCloseBlock'

export const ModalCloseKey = styled.div`
	width: 2.9em;
	height: 2.9em;
	box-sizing: border-box;
	border-radius: 0.5em;
	border: 1px solid ${colorTheme.modal.info.closeKey.border};
	background-color: ${colorTheme.modal.info.closeKey.background};
	color: ${colorTheme.modal.info.closeKey.text};
	font-family: ${theme.fontRobotoBold};
	font-weight: bold;
	text-transform: uppercase;
	font-size: 0.8em;
	line-height: 1.2em;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 1.7em;
`

export const ModalHeaderLeft = styled.div`
	z-index: 1;
	left: -2px;
	top: -2px;
	transform: none;
	width: auto;
	height: 3.25em;
	padding: 0.1em 3.13em;
	text-align: center;
	clip-path: inset(0 0 0 round 3.75em 0 3.75em 0);
	background-color: ${colorTheme.modal.info.header.background};
	position: absolute;

	&.orange {
		background-color: ${colorTheme.modal.info.warning.header.background};
	}

	&.warn {
		background-color: ${colorTheme.modal.info.warning.header.background};
	}

	&.info {
		background-color: ${colorTheme.modal.info.info.header.background};
	}

	&.error {
		background-color: ${colorTheme.modal.info.error.header.background};
	}

	@media screen and (max-width: ${theme.point820}) {
		clip-path: none;
		width: 100%;
		min-height: 3.75em;
		top: 0;
		left: 0;
		margin: 0;
		border-radius: 0;
		padding: 0 1em 0 0.63em;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
	}
`
ModalHeaderLeft.displayName = 'ModalHeaderLeft'

export const ModalHeaderLeftText = styled.div`
	line-height: 2.16em;
	font-size: 1.5em;
	color: ${colorTheme.modal.info.header.defaultText};

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.25em;
		font-weight: 700;
	}
`
ModalHeaderLeftText.displayName = 'ModalHeaderLeftText'

export const ModalContent = styled.div`
	border-radius: 1.5em;
	border: none;

	@media screen and (max-width: ${theme.point820}) {
		border-radius: 0;
		width: 100%;
		height: 100%;
		background: ${colorTheme.modal.info.background};
		position: relative;
		padding: 1.87em 1.25em 1.25em;
		overflow-y: auto;
	}
`
ModalContent.displayName = 'ModalContent'

export const ModalText = styled.div`
	font-family: ${theme.fontRobotoMedium};
	color: ${colorTheme.modal.info.text};
	text-align: center;
	font-size: 1.25em;
	font-style: normal;
	font-weight: 500;
`
ModalText.displayName = 'ModalText'

export const ModalFooter = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 1.875em;
	margin-bottom: 1.25em;

	> :last-child {
		margin-left: auto;
	}

	&.bottom-middle {
		margin-bottom: 1.62em;
	}

	@media screen and (max-width: ${theme.point820}) {
		gap: 0.63em;
		margin-bottom: 0;

		> * {
			width: 100%;
		}
	}
`
ModalFooter.displayName = 'ModalFooter'

