import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getPageThunk } from '../../redux/thunk/page'
import { useDispatch, useSelector } from 'react-redux'
import { LogoutButtonWrap, MenuProfile, MenuSettings, MenuSwitch, MenuWrapper, NavList, SettingItem, TseSettingItem } from './index.styled'
import { setAppAfterLogout, setAppModal, setPage } from '../../redux/action/system'
import Loader from '../Elements/loaders'
import { staticPages } from '../../redux/reducer/system/constants'
import { useLocation } from 'react-router-dom'
import { getPrinterDesktopSessionsThunk } from '../../redux/thunk/printer'
import { getVoipDesktopSessionsThunk } from '../../redux/thunk/phone'
import SwitchInputControlled from '../Elements/inputs/SwitchInput/controlled'
import { fetchAutoConfirmationThunk, putAutoConfirmationThunk } from '../../redux/thunk/order/acceptOrder'
import printer from '../../img/svg/header/printer.svg'
import phone from '../../img/svg/header/phone.svg'
import settings from '../../img/svg/header/settings.svg'
import socket from '../../img/svg/header/socket.svg'
import Logo from '../Elements/Logo'
import { theme } from '../../style/theme'
import Profile from '../Profile'
import Button from '../Elements/buttons/Button'
import Language from '../Language'
import { postLogoutThunk } from '../../redux/thunk/registration/auth'
import NavListItem from './NavListItem'
import { useWindowBreakPoint } from '../../hooks/useWindowBreakPoint'
import { getTseConfigThunk } from '../../redux/thunk/tse'
import TseDisabledModal from '../GlobalAppModal/TseDisabledModal'
import TseInformationModal from '../GlobalAppModal/TseInformationModal'
import TseInvalidDataWarningModal from '../GlobalAppModal/TseInvalidDataWarningModal'
import { setMapWidthMenu } from '../../redux/action/map'
import { useResizeDetector } from 'react-resize-detector'
import { selectCompanyData, selectContainsAnyRole } from '../../redux/selector/system'
import Header from '../Header'

const MenuList = React.memo(() => {
	const { t } = useTranslation()
	const autoConfirmation = useSelector(store => store.autoConfirmationOrder.autoConfirmation)
	const toggled = useSelector(store => store.system.toggled)
	const pages = useSelector(store => store.system.pages)
	const socketIoClientConnected = useSelector(store => !!store.socket.socketIoClient?.connected)
	const printerDesktopSessionsExist = useSelector(store => !!store.socket.printerDesktopSessions?.length)
	const voipDesktopSessionsExist = useSelector(store => !!store.socket.voipDesktopSessions?.length)
	const connectedVoipListExist = useSelector(store => !!store.phoneStore.phones?.filter(phone => phone.status === 'CONNECTED')?.length)
	const tseIsActive = useSelector(store => store.tse.config?.status === 'ACTIVE')
	const invalidDataTseStatus = useSelector(store => store.tse.config?.status === 'NOT_ENOUGH_DATA')
	const hasRoleSettingsWrite = useSelector(selectContainsAnyRole(['PROGRAM_SETTINGS_WRITE']))
	const companyCountryCode = useSelector(selectCompanyData('countryCode'))
	const [tseDisabledModalIsOpen, setTseDisabledModalIsOpen] = useState(false)
	const [tseInformationModalIsOpen, setTseInformationModalIsOpen] = useState(false)
	const [tseInvalidDataModalIsOpen, setTseInvalidDataModalIsOpen] = useState(false)
	const location = useLocation()
	const isTablet = useWindowBreakPoint(theme.point820)
	const dispatch = useDispatch()
	const { width ,  ref: menuRef } = useResizeDetector({})
	const filteredPages = useMemo(() =>
		staticPages.filter(o1 => pages.some(o2 => o1.id === o2)), [pages])
	const isLocatedInAustria = companyCountryCode === 'AUT'
	const shouldOpenTseInvalidDataModal = location?.state?.isLogIn && hasRoleSettingsWrite && invalidDataTseStatus && isLocatedInAustria

	const layoutPrinter = (toggled ? 'toggled ' : '') + (printerDesktopSessionsExist ? 'green ' : '')
	const layoutPhone = (toggled ? 'toggled ' : '') + (voipDesktopSessionsExist && connectedVoipListExist ? 'green ' : '')
	const layoutStatus = (toggled ? 'toggled ' : '') + 'green '
	const layoutSocketStatus = (toggled ? 'toggled ' : '') + (socketIoClientConnected ? 'green ' : '')
	const layoutTse = 'button ' + (toggled ? 'toggled ' : '') + (tseIsActive ? 'green ' : '')

	useEffect(() => {
		dispatch(getPageThunk())
		dispatch(fetchAutoConfirmationThunk())
		return () => dispatch(setPage([]))
	}, []) // eslint-disable-line react-hooks/exhaustive-deps


	useEffect(() => {
		dispatch(setMapWidthMenu(width))
	},[width, toggled]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(getPrinterDesktopSessionsThunk())
		dispatch(getVoipDesktopSessionsThunk())
		dispatch(getTseConfigThunk())
	}, [location?.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (shouldOpenTseInvalidDataModal) {
			setTseInvalidDataModalIsOpen(true)
		}
	}, [shouldOpenTseInvalidDataModal])

	const handleChangeAcceptOrder = useCallback((e) => {
		if (e.target.checked) {
			dispatch(setAppModal({ autoConfirmationModalShow: true }))
		} else {
			dispatch(putAutoConfirmationThunk({ active: false, deliveryTime: autoConfirmation.deliveryTime, pickUpTime: autoConfirmation.pickUpTime }))
		}
	}, [autoConfirmation, dispatch])

	const handleLogout = useCallback(e => {
		e.preventDefault()
		dispatch(setAppAfterLogout(true))
		dispatch(postLogoutThunk())
	}, [dispatch])

	const openTseModal = useCallback(() => {
		if (tseIsActive) {
			setTseInformationModalIsOpen(true)
		} else {
			if (invalidDataTseStatus && isLocatedInAustria) {
				setTseInvalidDataModalIsOpen(true)
			} else {
				setTseDisabledModalIsOpen(true)
			}
		}
	}, [tseIsActive, invalidDataTseStatus, isLocatedInAustria])

	const closeTseModal = useCallback(() => {
		if (tseIsActive) {
			setTseInformationModalIsOpen(false)
		} else {
			if (invalidDataTseStatus && isLocatedInAustria) {
				setTseInvalidDataModalIsOpen(false)
			} else {
				setTseDisabledModalIsOpen(false)
			}
		}
	}, [tseIsActive, invalidDataTseStatus, isLocatedInAustria])

	return (
		<>
			<MenuWrapper className={toggled ? 'toggled' : ''} ref={menuRef}>
				{isTablet ?
					<Header /> :
					<Logo toggled={toggled} />}
				{filteredPages.length ?
					<NavList>
						{filteredPages.map((item, i) =>
							<NavListItem key={i} item={item} />)}
					</NavList>
					: <Loader />}
				{!!filteredPages.length &&
					<MenuSwitch className={toggled ? 'toggled' : ''}>
						<SwitchInputControlled label={t('LeftBar.menu.auto_confirmation')}
												 checked={autoConfirmation.active}
												 onChange={handleChangeAcceptOrder} />
					</MenuSwitch>}
				{isTablet &&
					<MenuProfile>
						<Profile />
						<Language />
					</MenuProfile>}
				{isTablet &&
					<LogoutButtonWrap>
						<Button color="blue" text={t('LeftBar.menu.Logout')} icon="logout" onClick={handleLogout} className="logout" />
					</LogoutButtonWrap>}
				<MenuSettings className={toggled ? 'toggled' : ''}>
					<SettingItem id="printer-status" to="/settings/program/printer/config" className={layoutPrinter} tabIndex="-1">
						<img src={printer} alt="printers" />
					</SettingItem>
					<SettingItem id="phone-status" to="/settings/program/phone" className={layoutPhone} tabIndex="-1">
						<img src={phone} alt="phones" />
					</SettingItem>
					<SettingItem id="settings-status" to="/settings" className={layoutStatus} tabIndex="-1">
						<img src={settings} alt="settings" />
					</SettingItem>
					<SettingItem id="socket-status" to="/settings" className={layoutSocketStatus} tabIndex="-1">
						<img src={socket} alt="socket" />
					</SettingItem>
					<TseSettingItem className={layoutTse}
													onClick={openTseModal}
													tabIndex="-1">
						TSE
					</TseSettingItem>
				</MenuSettings>
			</MenuWrapper>
			<TseDisabledModal isOpen={tseDisabledModalIsOpen} close={closeTseModal} />
			<TseInformationModal isOpen={tseInformationModalIsOpen} close={closeTseModal} />
			<TseInvalidDataWarningModal isOpen={tseInvalidDataModalIsOpen} close={closeTseModal} />
		</>
	)
})

MenuList.displayName = 'MenuList'
export default MenuList
