import styled from 'styled-components'
import { theme } from '../../../style/theme'
import { colorTheme } from '../../../style/theme/colorTheme'

export const KeyboardWrapper = styled.div`
	position: absolute;
	font-size: 1rem;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;

	@media screen and (max-width: ${theme.point720}) {
		position: sticky;
		height: auto;
		width: 100%;
		grid-column: 1 / 4;
		bottom: 0;
	}
`
KeyboardWrapper.displayName = 'KeyboardWrapper'

export const KeyboardMain = styled.div`
	font-size: 1em;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(5, 1fr);
	column-gap: 0.19em;
	row-gap: 0.19em;
	margin-top: 0.63em;
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	background: ${colorTheme.keyboard.background};
	box-shadow: 0 4px 150px rgba(0, 0, 0, 0.41);
	border-radius: 0.75em 0.75em 0 0;
	padding: 0.25em;
	max-width: 450px;

	&.letters {
		grid-template-columns: repeat(11, 1fr);
		grid-template-rows: repeat(5, 1fr);
		max-width: 100%;
	}

	@media screen and (max-width: ${theme.point820}) {
		min-height: 15.2em;
		max-height: max-content;
		padding: 0.72em;
	}

	@media screen and (max-width: ${theme.point720}) {
		position: static;
		max-width: 100%;
		column-gap: 0.63em;
		row-gap: 0.63em;
		padding: 0;
		margin: 0;
		border-radius: 0;
		box-shadow: none;
		padding: 0.32em;

		&.letters {
			column-gap: 0.19em;
			row-gap: 0.63em;
		}
	}
`
KeyboardMain.displayName = 'KeyboardMain'

export const KeyboardCloseButton = styled.div`
	position: absolute;
	top: -3.38em;
	right: 0.25em;
	display: flex;
	width: 3em;
	height: 3em;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: ${colorTheme.keyboard.close.background};
	box-shadow: 0 4px 150px rgba(0, 0, 0, 0.41);

	> svg {
		width: 1.06em;
		height: 1.06em;
		fill: ${colorTheme.keyboard.close.icon};
	}

	@media screen and (max-width: ${theme.point720}) {
		width: 100%;
		height: 100%;
		position: static;
		background-color: ${colorTheme.keyboard.close.background};
		border-radius: 0.63em;
		display: flex;
		justify-content: center;
		align-items: center;
		user-select: none;
		cursor: pointer;
		grid-column: 4;
		grid-row: 5;
		border: 2px solid ${colorTheme.keyboard.close.border};
		min-height: 2.81em;
	}
`
KeyboardCloseButton.displayName = 'KeyboardCloseButton'

export const Key = styled.button`
	background-color: ${colorTheme.keyboard.key.background};
	border-radius: 0.33em;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid ${colorTheme.keyboard.key.border};
	color: ${colorTheme.keyboard.key.text};
	font-size: 2.25em;
	font-family: ${theme.fontRobotoRegular};
	padding: 0.67em 0;
	user-select: none;
	cursor: pointer;

	&:hover {
		background-color: ${colorTheme.keyboard.key.hover.background};
	}

	@media (hover: none) {
		&:hover {
			background-color: ${colorTheme.keyboard.key.background};
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.5em;
		border-radius: 0.5em;

		&.letters {
			border-radius: 0.3em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.38em;
		padding: 0;
		border-radius: 0.45em;
		min-height: 2.04em;
	}
`
Key.displayName = 'Key'

export const ClearKey = styled(Key)`
	background-color: ${colorTheme.keyboard.key.clear.background};
	grid-column: 4;
	grid-row: 1;

	> svg {
		width: 0.86em;
		height: 0.55em;
		fill: ${colorTheme.keyboard.key.clear.icon};
	}

	&.letters {
		grid-column: 11;
		grid-row: 1;
	}

	@media (hover: none) {
		&:hover {
			background-color: ${colorTheme.keyboard.key.clear.background};
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		> svg {
			width: 1.14em;
			height: 0.72em;
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		> svg {
			width: 1.09em;
			height: 0.68em;
		}
	}
`
ClearKey.displayName = 'ClearKey'

export const EnterKey = styled(Key)`
	background-color: ${colorTheme.keyboard.key.enter.background};
	grid-column: 4;
	grid-row: 2 / 5;

	> svg {
		width: 0.72em;
		height: 0.58em;
		fill: ${colorTheme.keyboard.key.enter.icon};
	}

	&.letters {
		grid-column: 10 / 12;
		grid-row: 2 / 4;
	}

	@media (hover: none) {
		&:hover {
			background-color: ${colorTheme.keyboard.key.enter.background};
		}
	}

	@media screen and (max-width: ${theme.point720}) {
		> svg {
			width: 1.18em;
			height: 0.95em;
		}
	}
`
EnterKey.displayName = 'EnterKey'

export const SpaceKey = styled(Key)`
	grid-column: 2 / 4;
	grid-row: 5;

	&.letters {
		grid-column: 2 / 10;
		grid-row: 5;
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.13em;
	}

	@media screen and (max-width: ${theme.point720}) {
		grid-column: 2;
		grid-row: 5;
	}
`
SpaceKey.displayName = 'SpaceKey'

export const ToggleKey = styled(Key)`
	&.letters {
		grid-column: 10 / 12;
		grid-row: 5;
	}

	@media screen and (max-width: ${theme.point820}) {
		font-size: 1.13em;
	}
`
ToggleKey.displayName = 'ToggleKey'

export const NumberKey = styled(Key)`
	&.letters {
		display: none;
	}
`
NumberKey.displayName = 'NumberKey'

export const TextKey = styled(Key)`
	display: none;

	&.letters {
		display: flex;
	}
`
TextKey.displayName = 'TextKey'

export const ShiftKey = styled(Key)`
	display: none;

	&.letters {
		display: flex;
		grid-column: 1 / 3;
		grid-row: 3;
	}
`
ShiftKey.displayName = 'ShiftKey'
