/* eslint no-console: off */
import CustomModal from '../../modal/CustomModal'
import React, { useCallback, useEffect, useState } from 'react'
import { roundPrice } from '../../../../helper'
import Button from '../../buttons/Button'
import HourglassLoader from '../../loaders/HourglassLoader'
import { QrCodeBlock, QrCodeModalWrapper, QrCodePaymentContent, QrCodePaymentErrorWrap, QrCodePaymentLoaderWrap, QrCodePaymentSuccessWrap, QrCodePaymentText, QrCodePaymentWrapper, QrCodeProcessWrap } from './index.styled'
import { useTranslation } from 'react-i18next'
import { QRCodeSVG } from 'qrcode.react'
import QrCodeLoader from './QrCodeLoader'
import { useDispatch, useSelector } from 'react-redux'
import { createQrPaymentSessionThunk, deleteQrPaymentSessionThunk, getQrPaymentSessionThunk } from '../../../../redux/thunk/cartPayment/qrCodePayment'
import { setCartPaymentQrCodePaymentSession, setCartPaymentQrCodePaymentSessionStarted } from '../../../../redux/action/cartPayment'
import { selectCompanyIndex } from '../../../../redux/selector/system'
import MainIcon from '../../../../img/svg/main'
import { deleteClientMonitorQrCodePaymentSessionThunk, storeClientMonitorQrCodePaymentSessionThunk } from '../../../../redux/thunk/clientMonitorCart'
import { setPrintReceiptOrderId } from '../../../../redux/action/printReceipt'
import ActionModal from '../../modal/ActionModal'

const EVENT_Z_INDEX = 78
const MAX_RETRIES_COUNT = 5

const ModalQrCodePayment = React.memo(({ isOpen, totalPrice, close, handlePayment, resetCart, openPaymentModal, printReceipt = false }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const waiterAccessToken = useSelector(state => state.modalLogin.accessToken)
	const qrCodePaymentSession = useSelector(state => state.cartPayment.qrCodePaymentSession)
	const qrCodePaymentSessionIsCreating = useSelector(state => state.cartPayment.qrCodePaymentSessionIsCreating)
	const qrCodePaymentSessionStarted = useSelector(state => state.cartPayment.qrCodePaymentSessionStarted)
	const companyIndex = useSelector(selectCompanyIndex)
	const [retriesCount, setRetriesCount] = useState(0)
	const [handleBackIsLoading, setHandleBackIsLoading] = useState(false)
	const [closingModalIsOpen, setClosingModalIsOpen] = useState(false)
	const hasModalLogin = !!waiterAccessToken
	const isExistQrCodeSession = !!qrCodePaymentSession && !qrCodePaymentSessionIsCreating
	const paymentSessionId = qrCodePaymentSession?.sessionId
	const paymentSessionLink = qrCodePaymentSession?.link
	const processingPayment = qrCodePaymentSession?.status === 'PAYMENT_CREDENTIALS_PROVIDED'
	const paymentSucceeded = qrCodePaymentSession?.status === 'SUCCEEDED'
	const paymentCancelled = qrCodePaymentSession?.status === 'CANCELED'
	const disableBackButton = handleBackIsLoading || processingPayment || qrCodePaymentSessionIsCreating
	const paymentError = qrCodePaymentSession?.status === 'CANCELED'
	const qrCodePaymentOrderId = qrCodePaymentSession?.orderId
	const paymentStarted = qrCodePaymentSessionStarted && !paymentError && !paymentSucceeded
	const layout = (qrCodePaymentSessionStarted ? 'started ' : '') + (paymentError ? 'error ' : '') + (paymentSucceeded ? 'success ' : '')

	useEffect(() => {
		if (isOpen) {
			dispatch(createQrPaymentSessionThunk(totalPrice, hasModalLogin))
		}
	}, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		let interval
		if (isOpen) {
			interval = setInterval(() => {
				if (isExistQrCodeSession && retriesCount < MAX_RETRIES_COUNT && !qrCodePaymentOrderId) {
					handleQrCodePayment(paymentSessionId, paymentSessionLink, interval)
				} else {
					clearInterval(interval)
					if (!qrCodePaymentOrderId) {
						handleBack()
					}
				}
			}, 1000)
		}

		return () => clearInterval(interval)
	}, [isOpen, paymentSessionId, paymentSessionLink, retriesCount, qrCodePaymentOrderId]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const interval = setInterval(() => {
			if (isOpen && paymentSessionId && !paymentSucceeded && !paymentCancelled) {
				dispatch(getQrPaymentSessionThunk(paymentSessionId, hasModalLogin))
			}
		}, 3000)

		return () => clearInterval(interval)
	}, [isOpen, paymentSessionId, paymentSucceeded, paymentCancelled]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (isOpen && isExistQrCodeSession && qrCodePaymentOrderId) {
			dispatch(storeClientMonitorQrCodePaymentSessionThunk())
		}
	}, [isOpen, isExistQrCodeSession, qrCodePaymentOrderId]) 	// eslint-disable-line react-hooks/exhaustive-deps

	const handleCloseClosingModal = useCallback(() => {
		setClosingModalIsOpen(false)
	}, [])

	const handleReset = useCallback(() => {
		dispatch(setCartPaymentQrCodePaymentSession(null))
		dispatch(setCartPaymentQrCodePaymentSessionStarted(false))
		setRetriesCount(0)
		handleCloseClosingModal()
	}, [dispatch, handleCloseClosingModal])

	const handleOpenPaymentModal = useCallback(() => {
		close()
		openPaymentModal()
	}, [close, openPaymentModal])

	const handleDeleteQrPaymentSession = useCallback(async () => {
		setHandleBackIsLoading(true)
		console.log(`Handle QR back: session=${paymentSessionId} companyIndex=${companyIndex}`)
		await dispatch(deleteQrPaymentSessionThunk(paymentSessionId, hasModalLogin))
		setHandleBackIsLoading(false)
		handleOpenPaymentModal()
	}, [handleOpenPaymentModal, companyIndex, dispatch, hasModalLogin, paymentSessionId])

	const handleFinishQrPaymentSession = useCallback(() => {
		close()
		if (printReceipt) dispatch(setPrintReceiptOrderId(qrCodePaymentOrderId))
		resetCart()
	}, [close, dispatch, resetCart, qrCodePaymentOrderId, printReceipt])

	const handleBack = useCallback(async () => {
		if (paymentSessionId && !paymentCancelled && !paymentSucceeded) {
			await handleDeleteQrPaymentSession()
		} else if (paymentSessionId && paymentSucceeded) {
			handleFinishQrPaymentSession()
		} else if (paymentSessionId && paymentCancelled) {
			handleOpenPaymentModal()
		}
		await dispatch(deleteClientMonitorQrCodePaymentSessionThunk())
		handleReset()
	}, [dispatch, handleReset, paymentSessionId, paymentCancelled, paymentSucceeded, handleOpenPaymentModal, handleDeleteQrPaymentSession, handleFinishQrPaymentSession])

	const handleQrStartedBack = useCallback(() => {
		setClosingModalIsOpen(true)
	}, [])

	const handleQrCodePayment = useCallback(async (sessionId, link, interval) => {
		try {
			await handlePayment(sessionId, link)
			clearInterval(interval)
		} catch (e) {
			setRetriesCount(prevState => prevState + 1)
		}
	}, [handlePayment])

	return (
		<>
			<CustomModal isOpen={isOpen}
									 id="modal-qr-code-payment"
									 close={paymentStarted ? handleQrStartedBack : handleBack}
									 size="small"
									 title={t('Modal.total') + ':'}
									 titlePrice={roundPrice(totalPrice)}
									 titleInOneRow={true}
									 titleUpperCase={true}
									 showBackButton={false}
									 disableBackButton={true}
									 showCloseButton={!disableBackButton}
									 enableOutsideClick={!disableBackButton}
									 zIndex={EVENT_Z_INDEX}>
				<QrCodeModalWrapper>
					<QrCodePaymentWrapper>
						<QrCodePaymentContent className={layout}>
							{!qrCodePaymentOrderId &&
								<QrCodePaymentLoaderWrap>
									<HourglassLoader className="dark" />
									<QrCodePaymentText>{t('Modal.qrCodePayment.loadingText')}</QrCodePaymentText>
								</QrCodePaymentLoaderWrap>}
							{qrCodePaymentOrderId && !qrCodePaymentSessionStarted && !paymentError && !paymentSucceeded &&
								<QrCodeProcessWrap>
									<QrCodeBlock data-testid="qr-code"><QRCodeSVG value={paymentSessionLink} level="M" /></QrCodeBlock>
									<QrCodeLoader />
									<QrCodePaymentText>{t('Modal.qrCodePayment.loadingText')}</QrCodePaymentText>
								</QrCodeProcessWrap>}
							{paymentStarted &&
								<QrCodePaymentLoaderWrap>
									<HourglassLoader className="dark" />
									<QrCodePaymentText>{t('Modal.qrCodePayment.startedText')}</QrCodePaymentText>
								</QrCodePaymentLoaderWrap>}
							{paymentError &&
								<QrCodePaymentErrorWrap>
									<MainIcon icon="error" testId="qr-code-error" />
									<QrCodePaymentText className={layout}>{t('Modal.qrCodePayment.errorText')}</QrCodePaymentText>
								</QrCodePaymentErrorWrap>}
							{paymentSucceeded &&
								<QrCodePaymentSuccessWrap>
									<MainIcon icon="green-circle-checkmark" testId="qr-code-success" />
									<QrCodePaymentText className={layout}>{t('Modal.qrCodePayment.successText')}</QrCodePaymentText>
								</QrCodePaymentSuccessWrap>}
						</QrCodePaymentContent>
						<Button name="back" icon={paymentSucceeded ? 'checkmark' : 'arrow-left'}
										text={paymentSucceeded ? t('buttons.controls.ready') : t('buttons.controls.back')}
										color={paymentSucceeded ? 'green' : 'gray'}
										className="fullWidth"
										keyButton="Escape" keyText="esc" zIndex={EVENT_Z_INDEX}
										onClick={paymentStarted ? handleQrStartedBack : handleBack} disabled={disableBackButton} />
					</QrCodePaymentWrapper>
				</QrCodeModalWrapper>
			</CustomModal>

			<ActionModal modalIsOpen={closingModalIsOpen}
									 accept={handleBack}
									 cancel={handleCloseClosingModal}
									 close={handleCloseClosingModal}
									 modalTitle={t('app.modals.Attention')}
									 modalBody={t('Modal.qrCodePayment.interruptText')}
									 zIndex={EVENT_Z_INDEX} />
		</>
	)
})

ModalQrCodePayment.displayName = 'ModalQrCodePayment'
export default ModalQrCodePayment
