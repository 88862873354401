import { SET_PRINT_RECEIPT_ORDER_ID, SET_PRINT_RECEIPT_PRINT_SETTINGS } from '../../actionTypes/printReceipt'

const initialState = {
	orderId: null,
	printSettings: []
}

export function printReceiptReducer(state = initialState, action) {
	switch (action.type) {
		case SET_PRINT_RECEIPT_ORDER_ID:
			return {
				...state,
				orderId: action.value
			}
		case SET_PRINT_RECEIPT_PRINT_SETTINGS:
			return {
				...state,
				printSettings: action.value
			}
		default:
			return state
	}
}


