import React, { useCallback } from 'react'
import CancelOrder from './order/cancel'
import { useDispatch, useSelector } from 'react-redux'
import CustomerOrderButtonsModal from './order/'
import OrderDataModal from '../../Elements/modal/order/OrderDataModal'
import SingleDriverModal from '../../DriverMonitor/modal/singleDriver'
import { setModalOrderCustomer } from '../../../redux/action/customer'
import ModalPayment from '../../Elements/cart/ModalPayment'
import { confirmOrderPaymentsThunk, updateOrderThunk } from '../../../redux/thunk/order'
import { setDeliveryCartOrder } from '../../../redux/action/deliveryCart'
import PrintCanceledOrder from './order/printCanceled'
import DriverConfirmationModal from '../../WoltDriver/DriverConfirmationModal'
import NoDriverModal from '../../WoltDriver/NoDriverModal'
import PrintReceiptConfirmationModal from '../../Elements/modal/PrintReceiptConfirmationModal'
import { getOrderModule } from '../../../helper/getOrderModule'
import { roundPrice } from '../../../helper'

const DeliveryModals = React.memo(() => {
	const showOrderData = useSelector(store => store.customerStore.modal.showOrderData)
	const showPaymentMethod = useSelector(store => store.customerStore.modal.showPaymentMethod)
	const deliveryCartOrder = useSelector(store => store.deliveryCart.order)
	const orderData = useSelector(store => store.order.selectedOrder)
	const showDriverConfirmationModal = useSelector(store => store.woltDriverIntegrations.deliveryPromises)
	const showNoDriverModal = useSelector(store => store.woltDriverIntegrations.driverNotFound)
	const dispatch = useDispatch()

	const orderPayment = deliveryCartOrder?.payments?.filter(item => item?.method === deliveryCartOrder?.paymentMethod)
	const isPaid = orderPayment?.[0]?.payed
	const totalSum = (isPaid && deliveryCartOrder?.paymentMethod === 'EC_TERMINAL') ? roundPrice(deliveryCartOrder?.totalPrice - deliveryCartOrder?.tips) : deliveryCartOrder?.totalPrice

	const giftCoupons = deliveryCartOrder?.payments?.filter(payment => payment.method === 'GIFT_COUPON')
	const giftCouponSum = giftCoupons?.length ? giftCoupons.reduce((res, el) => res + el.total, 0) : 0
	const totalSumWithoutGiftCoupons = roundPrice(totalSum - giftCouponSum)

	const closeModalPayment = useCallback((forceClose = true) => {
		forceClose && dispatch(setDeliveryCartOrder(null))
		dispatch(setModalOrderCustomer({ showPaymentMethod: false }))

	}, [dispatch])

	const handlePayment = useCallback(({ paymentId, paymentMethod, remark, tips = 0 }) => {
		const isEcTerminalPaymentMethod = paymentMethod === 'EC_TERMINAL'
		const resultTotalPrice = isEcTerminalPaymentMethod ? roundPrice(deliveryCartOrder.totalPrice - deliveryCartOrder.tips + tips) : roundPrice(deliveryCartOrder.totalPrice - deliveryCartOrder.tips)
		if (isPaid) {
			const orderData = {
				...deliveryCartOrder,
				totalPrice: resultTotalPrice,
				tips,
				paymentMethod, payments: [{ id: paymentId, method: paymentMethod, total: resultTotalPrice, payed: true }], remark,
			}
			dispatch(updateOrderThunk(orderData.orderId, orderData))
		} else {
			const orderPaymentId = isEcTerminalPaymentMethod ? paymentId : orderPayment[0].id
			const totalPriceWithoutGiftCoupon = roundPrice(deliveryCartOrder.totalPrice - giftCouponSum)
			const payments = [{ id: orderPaymentId, method: paymentMethod, total: totalPriceWithoutGiftCoupon }, ...giftCoupons]
			dispatch(confirmOrderPaymentsThunk(deliveryCartOrder.orderId, { payments, tips }))
		}
		if (!isEcTerminalPaymentMethod) {
			closeModalPayment()
		}
	}, [deliveryCartOrder, closeModalPayment, orderPayment, dispatch, giftCouponSum, giftCoupons, isPaid])

	const closeModalOrderData = useCallback(() => {
		dispatch(setModalOrderCustomer({ showOrderData: false }))
	}, [dispatch])

	const reOpenPaymentModal = useCallback(() => {
		dispatch(setModalOrderCustomer({ showPaymentMethod: true }))
	}, [dispatch])

	return (
		<>
			<CustomerOrderButtonsModal />
			<SingleDriverModal />
			<CancelOrder />
			<OrderDataModal order={orderData}
											modalIsOpen={showOrderData}
											close={closeModalOrderData} />
			<ModalPayment isOpen={showPaymentMethod}
										closeModal={closeModalPayment}
										openModal={reOpenPaymentModal}
										totalSum={isPaid ? totalSum : totalSumWithoutGiftCoupons}
										handlePayment={handlePayment}
										remark={deliveryCartOrder?.remark}
										isDeferredPayment={!isPaid}
										updatePaymentMode={true}
										orderType={deliveryCartOrder?.orderType}
										resetCart={closeModalPayment}
										printReceipt={!isPaid} />
			<PrintCanceledOrder />
			{showDriverConfirmationModal && <DriverConfirmationModal />}
			{showNoDriverModal && <NoDriverModal />}
			<PrintReceiptConfirmationModal module={getOrderModule(orderData?.orderType)}
																		 orderType={orderData?.orderType}
																		 returnZIndex={0} />
		</>
	)
})

DeliveryModals.displayName = 'DeliveryModals'
export default DeliveryModals
