import { subDays, subMonths } from 'date-fns'
import { APPEND_REPORTS, SET_DATE_FROM, SET_DATE_TO, SET_MONTH_FROM, SET_MONTH_TO, SET_REPORTS, SET_REPORT_LOADING, SET_REPORT_TOTAL_LENGTH, SET_STRIPE_REPORT_DATA, SET_STRIPE_REPORT_DATA_LOADING, SET_STRIPE_REPORT_PRINT_MESSAGE_DATA, SET_STRIPE_REPORT_TO_PRINT } from '../../actionTypes/report'

const initialState = {
	reports: [],
	dateFrom: subDays(new Date(), 7),
	dateTo: new Date(),
	monthFrom: subMonths(new Date(), 6),
	monthTo: new Date(),
	reportsTotalLength: 0,
	loading: false,
	stripeReportData: null,
	stripeReportDataLoading: false,
	stripeReportToPrint: null,
	stripeReportPrintMessageData: null
}

export function reportReducer (state = initialState, action) {
	switch (action.type) {
		case SET_REPORTS:
			return {
				...state,
				reports: action.reports,
			}
		case SET_REPORT_TOTAL_LENGTH:
			return {
				...state,
				reportsTotalLength: action.length,
			}
		case APPEND_REPORTS:
			return {
				...state,
				reports: state.reports.concat(...action.reports),
			}
		case SET_DATE_TO:
			return {
				...state,
				dateTo: action.dateTo,
			}
		case SET_DATE_FROM:
			return {
				...state,
				dateFrom: action.dateFrom,
			}
		case SET_MONTH_TO:
			return {
				...state,
				monthTo: action.monthTo,
			}
		case SET_MONTH_FROM:
			return {
				...state,
				monthFrom: action.monthFrom,
			}
		case SET_REPORT_LOADING:
			return {
				...state,
				loading: action.value,
			}
		case SET_STRIPE_REPORT_DATA:
			return {
				...state,
				stripeReportData: action.value,
			}
		case SET_STRIPE_REPORT_DATA_LOADING:
			return {
				...state,
				stripeReportDataLoading: action.value,
			}
		case SET_STRIPE_REPORT_TO_PRINT:
			return {
				...state,
				stripeReportToPrint: action.value,
			}
		case SET_STRIPE_REPORT_PRINT_MESSAGE_DATA:
			return {
				...state,
				stripeReportPrintMessageData: action.value,
			}
		default:
			return state
	}
}


