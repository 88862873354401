import React, { useEffect, useMemo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectOptions from '../../Elements/inputs/SelectInput/SelectOptions'
import { useDispatch, useSelector } from 'react-redux'
import CustomTable from '../../Elements/table'
import TableTr from '../../Elements/table/tr'
import SelectInputControlled from '../../Elements/inputs/SelectInput/controlled'
import { getCashDeskSettingsCartSettingsThunk, getCashDeskSettingsChangeSettingsThunk, getCashDeskSettingsPrintSettingsThunk, getCashDeskSettingsWaiterSettingsThunk, updateCashDeskSettingsCartSettingsThunk, updateCashDeskSettingsChangeSettingsThunk, updateCashDeskSettingsPrintSettingsThunk, updateCashDeskSettingsWaiterSettingsThunk } from '../../../redux/thunk/cashDeskSettings'
import WaiterCartSettings from '../../Elements/cartSettings/WaiterCartSettings'
import { handleTimeValidationOnBlur } from '../../WebShopSchedule/helper'
import { convertTimeWithSecondsToMinutes, convertTimeToMinutes, convertTimeToSeconds, getFloat, convertTimeWithHoursAndMinutes } from '../../../helper'
import { cartSettingsSort } from '../helper'
import { TableBoldTitle, TableMobileTitle } from '../../Elements/table/style/table/title/index.styled'
import { TableTd } from '../../Elements/table/style/table/td/index.styled'
import { ShortTableWrapper } from '../../Elements/table/style/table/index.styled'

const minWaiterLogoutTime = 3
const regexOnlyPositiveNumbers = /^(\d*)([.,]\d+)?$/

const CartSettingForCashDesk = React.memo(() => {
	const cartSettings = useSelector(store => store.cashDeskSettings.cartSettings)
	const changeSettings = useSelector(store => store.cashDeskSettings.changeSettings)
	const waiterSettings = useSelector(store => store.cashDeskSettings.waiterSettings)
	const printSettings = useSelector(store => store.cashDeskSettings.printSettings)
	const [waiterLogoutHours, setWaiterLogoutHours] = useState('')
	const [waiterLogoutMinutes, setWaiterLogoutMinutes] = useState('')
	const [waiterDiscount, setWaiterDiscount] = useState(0)
	const [showWaiterDiscountCheck, setShowWaiterDiscountCheck] = useState(false)
	const [timeError, setTimeError] = useState(false)
	const [discountError, setDiscountError] = useState(false)
	const dispatch = useDispatch()
	const { t } = useTranslation()

	useEffect(() => {
		dispatch(getCashDeskSettingsCartSettingsThunk('CASH_DESK'))
		dispatch(getCashDeskSettingsChangeSettingsThunk('CASH_DESK'))
		dispatch(getCashDeskSettingsWaiterSettingsThunk('CASH_DESK'))
		dispatch(getCashDeskSettingsPrintSettingsThunk('CASH_DESK'))
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (waiterSettings) {
			const time = convertTimeWithHoursAndMinutes(waiterSettings.logoutTime)
			setWaiterLogoutHours(time?.hours)
			setWaiterLogoutMinutes(time?.minutes)
			setWaiterDiscount(waiterSettings.discount)
		}
	}, [waiterSettings])

	const tableHeadersStaticCart = useMemo(() => [
		{ width: '50%', title: t('Settings.TitleCartSettings'), className: 'bold cart-setting' },
		{ width: '25%', title: t('Settings.pickup'), className: 'bold cart-setting' },
		{ width: '25%', title: t('Settings.counter'), className: 'bold cart-setting' },
	], [t])

	const tableHeadersStaticChange = useMemo(() => [
		{ width: '50%', title: t('Settings.TitleChangeSettings'), className: 'bold cart-setting' },
		{ width: '25%', title: t('Settings.pickup'), className: 'bold cart-setting' },
		{ width: '25%', title: t('Settings.counter'), className: 'bold cart-setting' },
	], [t])

	const tableHeadersStaticPrint = useMemo(() => [
		{ width: '50%', title: t('Settings.TitlePrintSettings'), className: 'bold cart-setting' },
		{ width: '25%', title: t('Settings.pickup'), className: 'bold cart-setting' },
		{ width: '25%', title: t('Settings.counter'), className: 'bold cart-setting' },
	], [t])

	const cartSettingsSorted = useMemo(() => {
		if (cartSettings) {
			return cartSettingsSort(cartSettings)
		}
	}, [cartSettings])

	const changeSettingsSorted = useMemo(() => {
		if (changeSettings) {
			return cartSettingsSort(changeSettings)
		}
	}, [changeSettings])

	const printSettingsSorted = useMemo(() => {
		if (printSettings) {
			return cartSettingsSort(printSettings)
		}
	}, [printSettings])

	const changeCartSetting = (e, businessScope) => {
		dispatch(updateCashDeskSettingsCartSettingsThunk({ businessScope, module: 'CASH_DESK', showProductQuantity: e.target.value }))
	}

	const changeChangeSetting = (e, businessScope) => {
		dispatch(updateCashDeskSettingsChangeSettingsThunk({ businessScope, module: 'CASH_DESK', showCalculatorWithChange: e.target.value }))
	}

	const changePrintSetting = (e, id) => {
		dispatch(updateCashDeskSettingsPrintSettingsThunk(id, { disabled: e.target.value }, 'CASH_DESK'))
	}

	const onChangeWaiterLogoutTime = useCallback((event, type) => {
		const time = type === 'hours' ? `${event.target.value}:${waiterLogoutMinutes}` : `${waiterLogoutHours}:${event.target.value}`
		if (convertTimeWithSecondsToMinutes(waiterSettings.logoutTime) !== time) {
			const value = event.target.value
			const newTime = handleTimeValidationOnBlur(time)
			if (type === 'hours') setWaiterLogoutHours(value)
			else setWaiterLogoutMinutes(value)

			if (convertTimeToMinutes(newTime) >= minWaiterLogoutTime) {
				const updateWaiterSettings = { ...waiterSettings, logoutTime: convertTimeToSeconds(newTime) }
				dispatch(updateCashDeskSettingsWaiterSettingsThunk(updateWaiterSettings))
				setTimeError(false)
			} else {
				setTimeError(true)
			}
		}
	}, [waiterSettings, waiterLogoutMinutes, waiterLogoutHours, dispatch])

	const onChangeWaiterDiscount = useCallback((event) => {
		const value = event.target.value
		setWaiterDiscount(value)
		setShowWaiterDiscountCheck(false)
		setDiscountError(false)
	}, [])

	const handleValidateDiscount = useCallback(() => {
		if (waiterDiscount === '') return t('app.validation.required')
		if (!regexOnlyPositiveNumbers.test(waiterDiscount)) return t('Settings.WaiterCartSettings.error.positive_number')
		if (getFloat(waiterDiscount) > 100) return t('Settings.WaiterCartSettings.error.discount_error', { discount: '100%' })
	}, [waiterDiscount, t])

	const onBlurWaiterDiscount = useCallback(() => {
		if (waiterSettings.discount !== waiterDiscount) {
			const hasError = handleValidateDiscount()

			if (hasError) {
				setDiscountError(true)
			} else {
				const updateWaiterSettings = { ...waiterSettings, discount: getFloat(waiterDiscount) }
				dispatch(updateCashDeskSettingsWaiterSettingsThunk(updateWaiterSettings))
				setShowWaiterDiscountCheck(true)
			}
		}

	}, [waiterSettings, waiterDiscount, dispatch, handleValidateDiscount])

	return (
		<ShortTableWrapper className="small">
			<WaiterCartSettings {...{
				waiterLogoutHours, waiterLogoutMinutes, onChangeWaiterLogoutTime, minWaiterLogoutTime,
				timeError, waiterDiscount, onChangeWaiterDiscount, onBlurWaiterDiscount, showWaiterDiscountCheck,
				discountError: discountError && handleValidateDiscount()
			}} />

			<CustomTable id="cartSettingsCashDesk-table"
				headers={tableHeadersStaticCart}
				onLoad={() => {
				}}
				currentLength={cartSettings?.length}
				totalLength={cartSettings?.length}>
				<TableTr className="bold-border row-tablet-cols-4 row-mob-cols-2">
					<TableTd className="cart-setting col-tablet-1-3">
						<TableMobileTitle className="small">{tableHeadersStaticCart[0].title}</TableMobileTitle>
						<TableBoldTitle>{t('Settings.TextCartSettings')}</TableBoldTitle>
					</TableTd>
					{cartSettingsSorted?.map((item, i) =>
						<TableTd key={i} className="cart-setting">
							<TableMobileTitle className="small">{tableHeadersStaticCart[i + 1].title}</TableMobileTitle>
							<SelectInputControlled name={`cartSettingsCashDesk-${i}`}
								value={item.showProductQuantity}
								onChange={e => changeCartSetting(e, item.businessScope)}>
								<SelectOptions options={[
									{ label: t('Settings.CartSelect.no'), value: false },
									{ label: t('Settings.CartSelect.yes'), value: true }]}
									labelBy="label"
									valueBy="value" />
							</SelectInputControlled>
						</TableTd>)}
				</TableTr>
			</CustomTable>

			<CustomTable id="changeSettingsCashDesk-table"
				headers={tableHeadersStaticChange}
				onLoad={() => {
				}}
				currentLength={changeSettings?.length}
				totalLength={changeSettings?.length}>
				<TableTr className="bold-border row-tablet-cols-4 row-mob-cols-2">
					<TableTd className="cart-setting col-tablet-1-3">
						<TableMobileTitle className="small">{tableHeadersStaticChange[0].title}</TableMobileTitle>
						<TableBoldTitle>{t('Settings.TextChangeSettings')}</TableBoldTitle>
					</TableTd>
					{changeSettingsSorted?.map((item, i) =>
						<TableTd key={i} className="cart-setting">
							<TableMobileTitle className="small">{tableHeadersStaticChange[i + 1].title}</TableMobileTitle>
							<SelectInputControlled name={`changeSettingsCashDesk-${i}`}
								value={item.showCalculatorWithChange}
								onChange={e => changeChangeSetting(e, item.businessScope)}>
								<SelectOptions options={[
									{ label: t('Settings.CartSelect.no'), value: false },
									{ label: t('Settings.CartSelect.yes'), value: true }]}
									labelBy="label"
									valueBy="value" />
							</SelectInputControlled>
						</TableTd>)}
				</TableTr>
			</CustomTable>

			<CustomTable id="printSettingsCashDesk-table"
				headers={tableHeadersStaticPrint}
				onLoad={() => {
				}}
				currentLength={changeSettings?.length}
				totalLength={changeSettings?.length}>
				<TableTr className="bold-border row-tablet-cols-4 row-mob-cols-2">
					<TableTd className="cart-setting col-tablet-1-3">
						<TableMobileTitle className="small">{tableHeadersStaticPrint[0].title}</TableMobileTitle>
						<TableBoldTitle>{t('Settings.TextPrintSettings')}</TableBoldTitle>
					</TableTd>
					{printSettingsSorted?.map((item, i) =>
						<TableTd key={i} className="cart-setting">
							<TableMobileTitle className="small">{tableHeadersStaticPrint[i + 1].title}</TableMobileTitle>
							<SelectInputControlled name={`printSettingsCashDesk-${i}`}
								value={item.disabled}
								onChange={e => changePrintSetting(e, item.id)}>
								<SelectOptions options={[
									{ label: t('Settings.CartSelect.no'), value: false },
									{ label: t('Settings.CartSelect.yes'), value: true }]}
									labelBy="label"
									valueBy="value" />
							</SelectInputControlled>
						</TableTd>)}
				</TableTr>
			</CustomTable>
		</ShortTableWrapper>
	)
})

CartSettingForCashDesk.displayName = 'CartSettingForCashDesk'
export default CartSettingForCashDesk
