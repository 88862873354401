export const SET_REPORTS = 'SET_REPORTS'
export const SET_REPORT_TOTAL_LENGTH = 'SET_REPORT_TOTAL_LENGTH'
export const APPEND_REPORTS = 'APPEND_REPORTS'
export const SET_DATE_TO = 'SET_DATE_TO'
export const SET_DATE_FROM = 'SET_DATE_FROM'
export const SET_MONTH_TO = 'SET_MONTH_TO'
export const SET_MONTH_FROM = 'SET_MONTH_FROM'
export const SET_REPORT_LOADING = 'SET_REPORT_LOADING'
export const SET_STRIPE_REPORT_DATA = 'SET_STRIPE_REPORT_DATA'
export const SET_STRIPE_REPORT_DATA_LOADING = 'SET_STRIPE_REPORT_DATA_LOADING'
export const SET_STRIPE_REPORT_TO_PRINT = 'SET_STRIPE_REPORT_TO_PRINT'
export const SET_STRIPE_REPORT_PRINT_MESSAGE_DATA = 'SET_STRIPE_REPORT_PRINT_MESSAGE_DATA'
