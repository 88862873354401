import { ADD_NEW_KITCHEN_ORDER, RE_INITIALIZE_KITCHEN_ORDERS, REMOVE_KITCHEN_ORDER, SET_KITCHEN_ORDERS, SET_KITCHEN_ORDERS_TOTAL_LENGTH, SET_KITCHEN_SELECTED_ORDER, UPDATE_KITCHEN_ORDER } from '../../actionTypes/kitchenMonitor'

export const reInitializeKitchenOrders = () => ({ type: RE_INITIALIZE_KITCHEN_ORDERS })

export const setKitchenOrders = value => ({ type: SET_KITCHEN_ORDERS, value })

export const addNewKitchenOrder = value => ({ type: ADD_NEW_KITCHEN_ORDER, value })

export const updateKitchenOrder = value => ({ type: UPDATE_KITCHEN_ORDER, value })

export const removeKitchenOrder = value => ({ type: REMOVE_KITCHEN_ORDER, value })

export const setKitchenOrdersTotalLength = value => ({ type: SET_KITCHEN_ORDERS_TOTAL_LENGTH, value })

export const setKitchenSelectedOrder = value => ({ type: SET_KITCHEN_SELECTED_ORDER, value })
