import { theme } from '../../../style/theme'

const windowBreakPoint = (breakpoint) => {
	const point = parseInt(breakpoint.replace('px', ''))
	const { innerWidth: width } = window
	return (width <= point)
}

export const point1660 = windowBreakPoint(theme.point1660)
export const point1440 = windowBreakPoint(theme.point1440)
export const point1280 = windowBreakPoint(theme.point1280)
export const point1024 = windowBreakPoint(theme.point1024)
export const point820 = windowBreakPoint(theme.point820)
export const point720 = windowBreakPoint(theme.point720)

export const fillMeta = {
	formType: 'CIRCLE',
	chairCount: 4,
	coordX: { value: 45, type: 'EM' },
	coordY: { value: 70, type: 'EM' },
	width: { value: 0, type: 'EM' },
	height: { value: 0, type: 'EM' },
}

export const PATTERN_SIZE_TABLE = [
	{ formType: 'CIRCLE', width: 100, height: 100 },
	{ formType: 'OVAL', width: 150, height: 100 },
	{ formType: 'RECTANGLE', width: 150, height: 100 },
	{ formType: 'BAR_COUNTER', width: 500, height: 90 },
]