import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { convert2CouponItem, getNeededDateFormat } from '../../../../../helper'
import { selectCouponInCart } from '../../../../../redux/selector/cart'
import { getIsValidCouponThunk } from '../../../../../redux/thunk/coupon'
import Button from '../../../buttons/Button'
import TextInputControlled from '../../../inputs/TextInput/controlled'
import CustomModal from '../../../modal/CustomModal'
import { CouponModalWrapper, ErrorText } from './index.styled'
import { setCouponDisabledForKasse, setGiftCoupon, setGiftCouponIsCanceled, setGiftCouponIsInvalid, setGiftCouponIsNotExist, setGiftCouponIsUsed } from '../../../../../redux/action/coupon'
import { updateClientMonitorCartThunk } from '../../../../../redux/thunk/clientMonitorCart'

const EVENT_Z_INDEX = 45

const ActivateCouponModal = React.memo(({ isOpen, close, addCoupon = () => {}, removeCoupon = () => {}, cartType, isRestaurant = false }) => {
	const { t } = useTranslation()
	const hasCouponInDeleviryCart = useSelector(selectCouponInCart('DELIVERY'))
	const hasCouponInCashDesk = useSelector(selectCouponInCart('CASH_DESK'))
	const invalidGiftCouponError = useSelector(state => state.coupon?.giftCouponIsInvalid)
	const usedGiftCouponError = useSelector(state => state.coupon?.giftCouponIsUsed)
	const isNotExistGiftCouponError = useSelector(state => state.coupon?.giftCouponIsNotExist)
	const couponDisabledForKasse = useSelector(state => state.coupon?.couponDisabledForKasse)
	const isCanceledGiftCouponError = useSelector(state => state.coupon?.giftCouponIsCanceled)
	const isCoupon = hasCouponInDeleviryCart || hasCouponInCashDesk
	const dispatch = useDispatch()
	const [couponNumber, setCouponNumber] = useState('')
	const [isEmptyCouponNumber, setIsEmptyCouponNumber] = useState(false)
	const dateInvalidGiftCoupon = getNeededDateFormat(invalidGiftCouponError, 'dd.MM.y H:mm')

	const resetErrors = useCallback(() => {
		setIsEmptyCouponNumber(false)
		dispatch(setGiftCouponIsInvalid(null))
		dispatch(setGiftCouponIsUsed(false))
		dispatch(setGiftCouponIsNotExist(false))
		dispatch(setGiftCouponIsCanceled(false))
		dispatch(setCouponDisabledForKasse(false))
	}, [dispatch])

	const handleCouponNumberChange = useCallback((e) => {
		const value = e.target.value
		setCouponNumber(value)
		resetErrors()
	}, [resetErrors])

	const onClose = useCallback(() => {
		setCouponNumber('')
		resetErrors()
		close()
	}, [close, resetErrors])

	const addCouponToCart = useCallback((couponToActivate) => {
		const couponItem = convert2CouponItem(couponToActivate)
		if (isCoupon) {
			removeCoupon()
		}

		addCoupon(couponItem)
		onClose()
	}, [addCoupon, onClose, removeCoupon, isCoupon])

	const addGiftCouponToCart = useCallback((couponToActivate) => {
		dispatch(updateClientMonitorCartThunk(cartType, 
			() => dispatch(setGiftCoupon(couponToActivate))))
		onClose()
	}, [dispatch, cartType, onClose])

	const onActivate = useCallback(async () => {
		if (couponNumber) {
			dispatch(getIsValidCouponThunk(couponNumber, isRestaurant, addCouponToCart, addGiftCouponToCart))
		} else {
			setIsEmptyCouponNumber(true)
		}
	}, [dispatch, couponNumber, addCouponToCart, addGiftCouponToCart, isRestaurant])

	return (
		<CustomModal isOpen={isOpen}
			close={onClose}
			title={t('Cart.Coupon.modal.title')}
			zIndex={EVENT_Z_INDEX}
			showBackButton={true}
			mobileFooter={true}
			button={<Button onClick={onActivate} text={t('Cart.Coupon.title')} icon="checkmark" color="green" name="activate" keyButton="F2" zIndex={EVENT_Z_INDEX} />}>
			<CouponModalWrapper>
				{invalidGiftCouponError && <ErrorText>{t('Cart.Coupon.modal.not_active_error', { expirationDate: dateInvalidGiftCoupon })}</ErrorText>}
				{usedGiftCouponError && <ErrorText>{t('Cart.Coupon.modal.used_error')}</ErrorText>}
				{isNotExistGiftCouponError && <ErrorText>{t('Cart.Coupon.modal.is_not_exist_error')}</ErrorText>}
				{isCanceledGiftCouponError && <ErrorText>{t('Cart.Coupon.modal.is_canceled_error')}</ErrorText>}
				{couponDisabledForKasse && <ErrorText>{t('Cart.Coupon.modal.disabled_error')}</ErrorText>}
				<TextInputControlled name="coupon-number"
					testId="coupon-number"
					value={couponNumber}
					onChange={handleCouponNumberChange}
					errorMessage={isEmptyCouponNumber && t('app.validation.required')}
					size="x-big"
					color="gray" />
			</CouponModalWrapper>
		</CustomModal>
	)
})

ActivateCouponModal.displayName = 'ActivateCouponModal'
export default ActivateCouponModal
