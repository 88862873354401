import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import DropDownSelect from '../Elements/buttons/DropDownSelect'
import { updateClientMonitorConfigThunk } from '../../redux/thunk/clientMonitorConfig'
import { useDispatch, useSelector } from 'react-redux'

const items = [{ icon: 'de', text: 'DE' }, { icon: 'ru', text: 'RU' }, { icon: 'en', text: 'EN' }, { icon: 'es', text: 'SP' }, { icon: 'cze', text: 'CH' }]

const Language = React.memo(() => {
	const { i18n } = useTranslation()
	const dispatch = useDispatch()
	const configClientMonitor = useSelector(store => store.clientMonitorConfig.config)
	const enabledClientMonitor = useSelector(store => store.clientMonitorConfig.config?.enabled)

	const selectedIndex = items.findIndex(item => item.icon === i18n?.language)

	const handleItemClick = useCallback((item) => {
		i18n.changeLanguage(item.icon)
		if (enabledClientMonitor) {
			dispatch(updateClientMonitorConfigThunk({
				...configClientMonitor, language: item.icon,
			}))
		}
	}, [i18n, dispatch, enabledClientMonitor, configClientMonitor])

	return (
		<DropDownSelect className="language" items={items}
										selectedIndex={selectedIndex} onClickItem={handleItemClick} />
	)
})

Language.displayName = 'Language'
export default Language
