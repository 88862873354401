import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n
	.use(detector)
	.use(backend)
	.use(initReactI18next)
	.init({
		fallbackLng: 'de',
		whitelist: ['de', 'ru', 'en', 'es', 'cze'],
		saveMissing: true,
		debug: false,
		detection: {
			order: ['localStorage', 'customDetector', 'querystring', 'cookie'],
		},
		parseMissingKeyHandler: (key) => {
			return `No translation found for "${key}"`
		},
		interpolation: {
			escapeValue: false,
		},
		backend: {
			loadPath: '/locales/{{lng}}/translation.json?v=' + (new Date()).getTime(),
		},
	})

export default i18n
