export const RE_INITIALIZE_TABLE_CART = 'RE_INITIALIZE_TABLE_CART'
export const SET_TABLE_CART_ORDERS = 'SET_TABLE_CART_ORDERS'
export const ADD_TABLE_CART_ORDER_ITEM = 'ADD_TABLE_CART_ORDER_ITEM'
export const REMOVE_TABLE_CART_ORDER_ITEM = 'REMOVE_TABLE_CART_ORDER_ITEM'
export const REMOVE_TABLE_CART_ORDER_ITEM_SUBITEM = 'REMOVE_TABLE_CART_ORDER_ITEM_SUBITEM'
export const UPDATE_TABLE_CART_ORDER_ITEM = 'UPDATE_TABLE_CART_ORDER_ITEM'
export const SET_TABLE_CART_ITEM_FOCUS_INDEX = 'SET_TABLE_CART_ITEM_FOCUS_INDEX'
export const SET_TABLE_CART_ORDER_FOCUS_INDEX = 'SET_TABLE_CART_ORDER_FOCUS_INDEX'
export const SET_TABLE_CART_SPLITS = 'SET_TABLE_CART_SPLITS'
export const REMOVE_TABLE_CART_ORDER_ITEM_REMARK = 'REMOVE_TABLE_CART_ORDER_ITEM_REMARK'
export const ADD_TABLE_CART_REMARK_ORDER_TO_ITEM ='ADD_TABLE_CART_REMARK_ORDER_TO_ITEM'
export const SET_TABLE_CART_LOADING ='SET_TABLE_CART_LOADING'
export const SET_TABLE_CART_NEW_ORDER ='SET_TABLE_CART_NEW_ORDER'
export const ADD_TABLE_CART_NEW_ORDER_ITEM_SUBITEM ='ADD_TABLE_CART_NEW_ORDER_ITEM_SUBITEM'
export const ADD_TABLE_CART_NEW_ORDER_REMARK_TO_ITEM ='ADD_TABLE_CART_NEW_ORDER_REMARK_TO_ITEM'
export const SET_TABLE_CART_SELECTED_DISH_COURSE_INDEX ='SET_TABLE_CART_SELECTED_DISH_COURSE_INDEX'
export const SET_TABLE_CART_SHOW_DISH_COURSE_MODAL = 'SET_TABLE_CART_SHOW_DISH_COURSE_MODAL'
export const SET_TABLE_CART_ITEM_DISH_COURSE_INDEX = 'SET_TABLE_CART_ITEM_DISH_COURSE_INDEX'
export const SET_TABLE_CART_MODAL_CUSTOM_PRODUCT_IS_OPEN = 'SET_TABLE_CART_MODAL_CUSTOM_PRODUCT_IS_OPEN'
export const SET_TABLE_CART_SHOW_MOBILE_CART = 'SET_TABLE_CART_SHOW_MOBILE_CART'
