import { setAppErrors } from '../../../action/system'
import { retrieveErrorFromApi } from '../../../../helper'
import { setTableCartSplits } from '../../../action/tableCart'
import storeTableSplit from '../../../axios/restaurant/table/split/post'
import fetchTableSplits from '../../../axios/restaurant/table/split'
import closeTableSplit from '../../../axios/restaurant/table/split/closeSplit/post'
import { fetchTableOrdersThunk } from '../order'
import { fetchRestaurantTablesThunk } from '../table'
import updateTableSplit from '../../../axios/restaurant/table/split/put'
import printTableSplit from '../../../axios/restaurant/table/split/printSplit/post'
import deleteTableSplit from '../../../axios/restaurant/table/split/delete'
import { setPrintReceiptOrderId } from '../../../action/printReceipt'
import { setRestaurantShowProcessPaymentErrorModal } from '../../../action/restaurant'

export const fetchTableSplitsThunk = (tableId) => dispatch => {
	return fetchTableSplits(tableId)
		.then(res => {
			if (res.data) {
				return dispatch(setTableCartSplits(res.data))
			}
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const storeTableSplitThunk = (tableId, split, hasModalLogin) => (dispatch, getState) => {
	return storeTableSplit(tableId, split, hasModalLogin)
		.then(res => {
			dispatch(fetchTableOrdersThunk(tableId, true, 'BEING_PREPARED'))
			dispatch(fetchTableSplitsThunk(tableId))
			return dispatch(fetchRestaurantTablesThunk(getState().restaurant.areaId))
		})
		.catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const updateTableSplitThunk = (splitId, tableId, split, hasModalLogin) => (dispatch, getState) => {
	return updateTableSplit(splitId, tableId, split, hasModalLogin)
		.then(res => {
			dispatch(fetchTableOrdersThunk(tableId))
			dispatch(fetchTableSplitsThunk(tableId))
			return dispatch(fetchRestaurantTablesThunk(getState().restaurant.areaId))
		})
		.catch(error => {
			const errorStatusCode = error.response.data?.apierror.errorStatusCode
			if (errorStatusCode === 'split_is_being_paid') {
				return dispatch(setRestaurantShowProcessPaymentErrorModal(true))
			}
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const closeTableSplitThunk = (splitId, tableId, data, hasModalLogin) => (dispatch, getState) => {
	const isEcTerminalPaymentMethod = data.paymentMethod === 'EC_TERMINAL'
	return closeTableSplit(splitId, tableId, data, hasModalLogin)
		.then(res => {
			if (!isEcTerminalPaymentMethod) {
				dispatch(setPrintReceiptOrderId(res.data.orderId))
			}
		}).catch(error => {
			const errorStatusCode = error.response?.data?.apierror?.errorStatusCode
			if (errorStatusCode === 'terminal_payment_session_invalid') {
				const paymentEC = data.payments.find(payment => payment.method === 'EC_TERMINAL')
				/* eslint no-console: off */
				console.error(`Close split: paymentId=${paymentEC?.id} totalPrice=${paymentEC?.total} companyIndex=${getState().login.companyIndex}`)
			} else if (errorStatusCode === 'split_is_being_paid') {
				return dispatch(setRestaurantShowProcessPaymentErrorModal(true))
			}
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
			throw new Error(error.response?.data?.apierror?.message)
		})
}

export const printTableSplitThunk = (splitId, tableId, hasModalLogin) => dispatch => {
	return printTableSplit(splitId, tableId, hasModalLogin)
		.then(res => {
		}).catch(error => {
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}

export const deleteTableSplitThunk = (splitId, tableId, hasModalLogin, data) => dispatch => {
	return deleteTableSplit(splitId, tableId, hasModalLogin, data)
		.then(res => {
			dispatch(fetchTableOrdersThunk(tableId, false, 'BEING_PREPARED'))
			return dispatch(fetchTableSplitsThunk(tableId))
		}).catch(error => {
			const errorStatusCode = error.response.data?.apierror.errorStatusCode
			if (errorStatusCode === 'split_is_being_paid') {
				return dispatch(setRestaurantShowProcessPaymentErrorModal(true))
			}
			dispatch(setAppErrors(retrieveErrorFromApi(error)))
		})
}
