import styled from 'styled-components'
import { theme } from '../../../../style/theme'
import { colorTheme } from '../../../../style/theme/colorTheme'

export const CustomTableWrapper = styled.div`
	padding: 0 1.88em;

	@media screen and (max-width: ${theme.point820}) {
		padding: 0 0.63em;
	}
`

export const TableWrapper = styled.div`
	width: 100%;
	margin: 0 auto;
	border-top-left-radius: 0.6em;
	position: relative;
	${props => props.isScrollBody && `
		max-height: 65vh;
		height: 100%;
		overflow: auto;
  `};

	@media screen and (max-width: ${theme.point820}) {
		box-shadow: none;
		${props => props.isScrollBody && `
			overflow: initial;
  	`};
	}
`

export const Table = styled.table`
	width: 100%;
	border-collapse: separate;
	font-size: 1.25rem;
	font-weight: 400;

	@media screen and (max-width: ${theme.point820}) {
		display: block;
	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1rem;
	}
`

export const TableHead = styled.thead`
	@media screen and (max-width: ${theme.point820}) {
		display: none;
	}
`

export const TableHeadTr = styled.tr`
	box-shadow: 0.06em 0.9em 2.5em rgb(0 0 0 / 15%);
	border-radius: 0.6em;
	height: 3.3em;

	${props => props.isScrollBody && `
		position: sticky;
		top: 0;
		z-index: 3;
  	border-bottom: 2px solid gray;
		border-radius: 0;
  `}
`

export const TableTh = styled.th`
	padding: 0.3em;
	background: ${colorTheme.table.tableTh.background};
	text-align: left;
	color: ${colorTheme.table.tableTh.color};
	font-family: ${theme.fontRobotoRegular};
	font-size: 1em;
	font-weight: normal;

	&:first-child {
		border-bottom-left-radius: 0.6em;
		border-top-left-radius: 0.6em;
		padding-left: 1.75em;
	}

	&:last-child {
		border-bottom-right-radius: 0.6em;
		border-top-right-radius: 0.6em;
		padding-right: 1em;
	}

	& svg {
		width: 1.3em;
		height: 0.8em;
		vertical-align: middle;
	}

	&.sortBy {
		color: ${colorTheme.table.tableTh.sortBy};
		text-decoration: underline;
		cursor: pointer;
	}
`

export const TableThInner = styled.div`
	display: flex;
	gap: 0.25em;

	svg {
		width: 0.85em;
		height: 0.6em;
	}
`

export const TableBody = styled.tbody`
	@media screen and (max-width: ${theme.point820}) {
		display: grid;
		gap: 0.63em;
		filter: drop-shadow(0 5px 25px rgba(0, 0, 0, 0.4));
	}
`

export const TableTrStyled = styled.tr`
	height: 3.95em;
	cursor: ${props => props.draggable && 'move'};
	transform: translate3d(0, 0, 0);

	&.strikethroughFont {
		td {
			text-decoration: line-through;
		}
	}

	&.small {
		height: 3em;
	}

	&:first-child td {
		border-top: 1px solid transparent;
	}

	&:last-child td {
		border-bottom: 1px solid ${colorTheme.table.tableTr.borderBottom};
	}

	&.disabled {
		td:not(.const-color) {
			color: ${colorTheme.table.tableTr.disabled.text};

			svg:not(button > svg) {
				fill: ${colorTheme.table.tableTr.disabled.text};
			}
		}

		span {
			background: ${colorTheme.table.tableTr.disabled.text};
		}
	}

	&.disabledStatus {
		td.const-color {
			color: ${colorTheme.table.tableTr.disabled.text};

			> div {
				color: ${colorTheme.table.tableTr.disabled.text};
			}

			svg {
				fill: ${colorTheme.table.tableTr.disabled.text} !important;
			}
		}
	}

	&.active {
		background: ${colorTheme.table.tableTr.active.backgroundTr};

		p {
			color: ${colorTheme.table.tableTr.active.text};
		}

		td {
			border-top: 1px solid ${colorTheme.table.tableTr.active.border};
			border-bottom: 1px solid ${colorTheme.table.tableTr.active.border};
			background: ${colorTheme.table.tableTr.active.background};
		}

		td:not(.const-color) {
			color: ${colorTheme.table.tableTr.active.text};

			svg, svg:not(button > svg) {
				fill: ${colorTheme.table.tableTr.active.text};
			}
		}

		& + tr td {
			border-top: 1px solid transparent;
		}

		td:first-child {
			border-radius: 0.6em 0 0 0.6em;
			border-left: 1px solid ${colorTheme.table.tableTr.active.border};
		}

		td:last-child {
			border-radius: 0 0.6em 0.6em 0;
			border-right: 1px solid ${colorTheme.table.tableTr.active.border};
		}

		span {
			background: ${colorTheme.table.tableTr.active.border};
		}
	}

	small {
		font-size: 0.6em;
		vertical-align: middle;
	}

	&.td_hide {
		td:not(:last-child) {
			display: none;
		}

		td:last-child {
			width: 100%;
		}

	}

	@media screen and (max-width: ${theme.point820}) {
		&.last-group {
			border-bottom: 3px solid ${colorTheme.table.tableTr.lastGroupBorder} !important;
		}
	}

	@media screen and (max-width: ${theme.point820}) {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
		height: auto;
		gap: 1px;
		border: 1px solid ${colorTheme.table.tableTr.borderBottom};
		border-radius: 0.75em;
		padding: 0;
		position: relative;
		background-color: ${colorTheme.table.tableTr.mobileBackground};
		overflow: hidden;

		&.active {
      		background: transparent;
    	}

		&.header {
			border-bottom: none;

			> td {
				color: ${colorTheme.table.tableTr.mobileTitle} !important;
				padding: 1.12em 0.75em 0 0 !important;
				justify-content: flex-end;
				min-height: auto;
			}

			> td:nth-child(2) {
				padding-left: 0.5em !important;
			}
		}

		&.small {
			height: auto;
		}
	}

`
TableTrStyled.displayName = 'TableTrStyled'

export const TableTd = styled.td`
	padding: 0.3em;
	text-align: left;
	border-top: 1px solid ${colorTheme.table.tableTd.border};
	border-bottom: 1px solid transparent;
	font-family: ${theme.fontRobotoRegular};
	font-size: 1em;

	.street {
		display: flex;
		align-items: end;
		flex-wrap: wrap;
		font-size: 0.8em;
	}

	&:first-child {
		padding-left: 1.75em;
	}

	&:last-child {
		padding-right: 1em;
	}

	&.row-1 {
		grid-row: 1;
	}

	&:not(.const-color) {
		color: ${colorTheme.table.tableTd.color} !important;
	}

	@media screen and (max-width: ${theme.point820}) {
		display: flex;
		grid-column: ${props => props.column || '1 / 7'};
		grid-row: ${props => props.row};
		padding: 0.4em 0.75em !important;
		min-height: 3.78em;
		border: none !important;
		background-color: ${colorTheme.table.tableTd.background};;
		border-radius: 0 !important;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;

		.street {
			color: ${colorTheme.table.tableTd.color} !important;
		}

		&.number {
			grid-column: 3;
			grid-row: 1;
			width: 2.5em;
			height: 2.5em;
			min-height: 2.5em;
			border-radius: 50% !important;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid ${colorTheme.table.tableTd.border} !important;
			padding: 0 !important;
			position: absolute;
			top: 0.7em;
			right: 1.06em;
		}

		&.mobile-hidden {
			display: none;
		}

		&.category {
			font-size: 1.28em;
			grid-column: 1 / 5;
			display: flex;
			flex-direction: column;
			gap: 0.28em;
			padding: 0 0 0.78em !important;
			font-weight: 700;
			border-bottom: 1px dashed ${colorTheme.table.tableTd.categoryBorder} !important;
			margin-top: 0.78em;
		}

		&.tablet-hidden {
			display: none;
		}

	}

	@media screen and (max-width: ${theme.point720}) {
		font-size: 1.13em;

		&.category {
			font-size: 1.13em;
		}
	}
`
TableTd.displayName = 'TableTd'

export const TableTdGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(2, auto);
	justify-content: start;
	align-items: center;
	gap: 0.35em;
	margin-top: 0.2em;

	> svg {
		width: 1.55em;
		height: 1.55em;
		fill: ${colorTheme.table.tableTd.icon};
	}

	@media screen and (max-width: ${theme.point720}) {
		column-gap: 0.5em;
		row-gap: 0;

		> svg {
			width: 1.65em;
			height: 1.65em;
			grid-column: 1;
			grid-row: ${props => props.rows === 1 ? '1' : '1 / 3'};
		}
	}
`
TableTdGrid.displayName = 'TableTdGrid'

export const TableMobileTitle = styled.div`
	font-size: 1em;
	font-family: ${theme.fontRobotoMedium};
	color: ${colorTheme.table.tableTr.mobileTitle};
	display: none;
	grid-column: 2;
	grid-row: 1;

	@media screen and (max-width: ${theme.point820}) {
		display: block;
		margin-bottom: .36em;
	}
	@media screen and (max-width: ${theme.point720}) {
		margin-bottom: .31em;
	}

`
TableMobileTitle.displayName = 'TableMobileTitle'

export const TablePlatformMobileTitle = styled(TableMobileTitle)`
	font-size: 0.84em;
	font-family: ${theme.fontRoboto};
	font-weight: 600;
`
TablePlatformMobileTitle.displayName = 'TablePlatformMobileTitle'

export const TableLoader = styled.div`
	margin-top: 0.75em;
`

export const TableLabels = styled.div`
	font-size: 1rem;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 0.6em;
`

export const TableLabel = styled.span`
	box-sizing: border-box;
	background: ${colorTheme.table.tableTr.tableLabel.background};
	font-size: 0.88em;
	border-radius: 0.57em;
	padding: 0.21em 0.36em;
	text-align: center;
	color: ${colorTheme.table.tableTr.tableLabel.color};
`

export const TablePlatformLabel = styled.span`
	box-sizing: border-box;
	background: ${colorTheme.table.tableTr.tableLabel.background};
	font-size: 0.89em;
	line-height: 1.17;
	border-radius: 8px;
	padding: 0.46em;
	text-align: center;
	color: ${colorTheme.table.tableTr.tableLabel.color};

	@media screen and (max-width: ${theme.point720}) {
		display: block;
		padding: 0.31em;
	}
`
TablePlatformLabel.displayName = 'TablePlatformLabel'

export const TableTdStatus = styled(TableTd)`
	&.BEING_PREPARED {
		color: ${colorTheme.orders.table.status.color.beingPrepared};

		svg {
			fill: ${colorTheme.orders.table.status.color.beingPrepared};
		}
	}

	&.ON_THE_WAY {
		color: ${colorTheme.orders.table.status.color.onTheWay};

		svg {
			fill: ${colorTheme.orders.table.status.color.onTheWay};
		}
	}

	&.COMPLETED {
		color: ${colorTheme.orders.table.status.color.completed};

		svg {
			fill: ${colorTheme.orders.table.status.color.completed};
		}
	}

	&.CANCELED {
		color: ${colorTheme.orders.table.status.color.canceled};

		svg {
			fill: ${colorTheme.orders.table.status.color.canceled};
		}
	}

	&.NOT_ACCEPTED {
		color: ${colorTheme.orders.table.status.color.notAccepted};

		svg {
			fill: ${colorTheme.orders.table.status.color.notAccepted};
		}
	}

	&.NOT_REGISTERED {
		color: ${colorTheme.orders.table.status.color.notRegistered};

		svg {
			fill: ${colorTheme.orders.table.status.color.notRegistered};
		}
	}

	&.DENIED {
		color: ${colorTheme.orders.table.status.color.denied};

		svg {
			fill: ${colorTheme.orders.table.status.color.denied};
		}
	}

	&.READY {
		color: ${colorTheme.orders.table.status.color.ready};

		svg {
			fill: ${colorTheme.orders.table.status.color.ready};
		}
	}
`
TableTdStatus.displayName = 'TableTdStatus'

